import React, { useState } from "react";
import { EditDevicesHeader } from "../EditDevicesHeader";
import { SubmitHandler, useForm } from "react-hook-form";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../utils/ux";
import {
  ResponseModal,
  ResponseProps,
} from "../../../../components/Modals/ResponseModal";
import { useMutation, useQuery } from "@apollo/client";
import { useDeviceConfigurationStore } from "../../../../store/DeviceConfiguration";
import { useShallow } from "zustand/react/shallow";
import { GET_SOFTWARE_PACKAGES } from "../../../../schemas/queries/SoftwarePackages";
import { Select } from "../../../../components/Menus/Select";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { toCapitalized } from "../../../../utils/ux";
import { EDIT_SOFTWARE } from "../../../../schemas/mutators/Devices";
import { Tag } from "../../../../components/Tags";
import { AccessControlledComponent } from "../../../../components/AccessControlledComponent";
import { POLICIES } from "../../../../constants/Policies";

type EditSoftwareForm = {
  version?: string;
};

interface SoftwareResponseProps {
  software?: SoftwarePackageType;
}

type SoftwarePackageType = {
  id: string;
  name: string;
  version: string;
  status: string;
};

const SoftwareResponse: React.FC<SoftwareResponseProps> = ({ software }) => {
  return (
    <div className="flex flex-col gap-y-6">
      {software?.version ? (
        <div className="flex flex-row gap-x-2">
          <p className="text-gray-800 dark:text-gray-300">
            Device Software Version
          </p>
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            <Tag color="gray" tag={software.version} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

interface SoftwarePanelProps {}

export const SoftwarePanel: React.FC<SoftwarePanelProps> = () => {
  const [allSoftware, setAllSoftware] = useState<boolean>(false);
  const { handleSubmit } = useForm<EditSoftwareForm>();
  // @ts-ignore
  const [selectedSoftwarePackage, setSelectedSoftwarePackage] =
    // @ts-ignore
    useState<SoftwarePackageType>({});
  const [softwarePackages, setSoftwarePackages] = useState<
    SoftwarePackageType[]
  >([]);

  const handleReset = () => {
    // @ts-ignore
    setSelectedSoftwarePackage({});
  };

  const { devices } = useDeviceConfigurationStore(
    useShallow((state) => ({
      devices: state.devices,
    }))
  );

  const softwarePackageQuery = useQuery(GET_SOFTWARE_PACKAGES, {
    variables: {
      allSoftware,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const packages = data.softwarePackages.map((pkg) => ({
        id: pkg.id,
        name: pkg.name,
        version: pkg.version,
        status: pkg.status,
      }));
      setSoftwarePackages(packages);
    },
  });

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });
  const [softwareResponse, setSoftwareResponse] =
    useState<SoftwareResponseProps>({});

  const [editSoftware, { loading, error }] = useMutation(EDIT_SOFTWARE, {
    onError: (error) => {
      console.error(error);
    },
  });

  const onSubmit: SubmitHandler<EditSoftwareForm> = async (data) => {
    if (devices.length < 1) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select at least one Device to update.",
      });
      setSoftwareResponse({});
      setOpen(true);
      return;
    }

    const cellIds = devices.map(({ id }) => id);
    if (!selectedSoftwarePackage?.version) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select a Software Version to update Devices to.",
      });
      setSoftwareResponse({});
      setOpen(true);
      return;
    }

    const softwareInput = {
      version: selectedSoftwarePackage.version,
    };
    const mqttMessage = await editSoftware({
      variables: {
        ids: cellIds,
        update: softwareInput,
      },
    });
    setResponse({
      ...response,
      success: true,
      successMsg: "Changing devices to have these settings...",
    });
    setSoftwareResponse({});
    setOpen(true);
  };

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <EditDevicesHeader />
      <div className="px-12 mt-6 mb-4">
        {softwarePackageQuery.loading ? (
          <LoadingSpinner h={12} w={12} className="mx-auto my-auto" />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col [&>*]:py-3"
          >
            <div className="flex flex-col justify-between w-full sm:flex-row gap-y-4">
              <button
                type="button"
                className="px-2 py-1 -mt-3.5 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
                onClick={handleReset}
              >
                Clear
              </button>
              <Switch.Group as="div" className="flex items-center gap-x-2">
                <Switch.Label as="span" className="text-sm">
                  <span className="font-medium text-gray-900 dark:text-gray-200">
                    All Software
                  </span>
                </Switch.Label>
                <Switch
                  checked={allSoftware}
                  onChange={setAllSoftware}
                  className={classNames(
                    allSoftware ? "bg-indigo-600" : "bg-gray-400",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      allSoftware ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="flex flex-col md:flex-row md:justify-between gap-x-4 lg:gap-x-0">
              <label
                className="block w-40 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
                htmlFor="mode"
              >
                Software Version
              </label>
              <div className="w-full">
                {!softwarePackageQuery.loading ? (
                  <>
                    <Select
                      value={
                        selectedSoftwarePackage.version
                          ? selectedSoftwarePackage
                          : ""
                      }
                      onChange={setSelectedSoftwarePackage}
                      itemType="Version"
                      items={softwarePackages}
                      menuHeight={"max-h-48"}
                      displayProp="version"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="flex w-full mt-2">
              <button
                type="submit"
                className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
                onSubmit={(e) => handleSubmit(onSubmit)}
                onClick={(e) => handleSubmit(onSubmit)}
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        )}
      </div>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={
          response.success
            ? "Requested to Change Device Software Version"
            : "Error"
        }
      >
        <SoftwareResponse {...softwareResponse} />
      </ResponseModal>
    </div>
  );
};

export default SoftwarePanel;
