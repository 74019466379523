import { BatchPerson, BatchPreview } from "./BatchPreview";
import { Customer, useCustomerStore } from "../../store/Customer";
import { Feed, Stage } from "../../components/Progress/Feed";
import React, { useEffect, useMemo, useState } from "react";
import {
	ResponseModal,
	ResponseProps,
} from "../../components/Modals/ResponseModal";
import {
	UploadStageDictionary,
	UploadState,
} from "../../constants/Progress/FacialRecognition";

import { ADD_PEOPLE_BATCH_SUBSCRIPTION } from "../../schemas/subscriptions/People";
import { Booleans } from "../../constants/Booleans";
import { Button } from "../../components/Buttons";
import { ExclamationIcon } from "../../components/Icons";
import { FEED_STAGE_COLORS } from "../../constants/Colors";
import { FileUpload } from "../../components/Inputs/FileUpload";
import { Link } from "react-router-dom";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import { Select } from "../../components/Menus/Select";
import { parse } from "papaparse";
import { usePortalUserStore } from "../../store/PortalUser";
import { useRole } from "../../hooks/auth";
import { useShallow } from "zustand/react/shallow";
import { useSubscription } from "@apollo/client";

interface UploadBatchStage extends Stage {
	state: UploadState;
}

const headerFields = [
	"name",
	"category",
	"color",
	"alert",
	"venues",
	"email",
	"phone",
	"about",
	"imageLink",
];

interface UploadBatchProps {
	className?: string;
}

export const UploadBatch: React.FC<UploadBatchProps> = ({
	className = "w-full pl-4",
}) => {
	const [files, setFiles] = useState<File[]>([]);
	const [persons, setPersons] = useState<BatchPerson[]>([]);
	const [batch, setBatch] = useState<BatchPerson[]>([]);
	const [error, setError] = useState("");
	const [stages, setStages] = useState<UploadBatchStage[]>([]);
	const [uploading, setUploading] = useState(false);
	const [open, setOpen] = useState(false);
	const [response, setResponse] = useState<ResponseProps>({
		success: false,
		successMsg: "",
		errorMsg: "",
	});

	const clearFile = () => {
		setFiles([]);
	};

	const { portalUser } = usePortalUserStore(
		useShallow((state) => ({ portalUser: state.portalUser }))
	);
	const { isAdmin } = useRole(portalUser);

	const [customerInput, setCustomerInput] = useState<Customer>({});

	const { customers, customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customers: state.customers,
			customerFilter: state.customerFilter,
		}))
	);

	const selectedCustomer = useMemo(() => {
		if (customerFilter.length > 0) return customerFilter;
		if (customerInput.id) return [customerInput.id];
		if (portalUser.customers && portalUser.customers.length > 0)
			return portalUser.customers;
		return [];
	}, [customerInput, customerFilter]);

	useEffect(() => {
		if (files.length > 0) {
			parse(files[0], {
				complete: (results) => {
					const { data, errors, meta } = results;
					// check column headers
					if (meta && meta.fields) {
						// sort headers
						const requiredHeaders = headerFields.slice().sort();
						const inputHeaders = meta.fields.slice().sort();
						for (let i = 0; i < requiredHeaders.length; i++) {
							if (
								i >= inputHeaders.length ||
								inputHeaders[i] !== requiredHeaders[i]
							) {
								// trigger modal for input csv file error
								setResponse({
									success: false,
									successMsg: "",
									errorMsg: `Invalid CSV file. File should have the following fields: ${requiredHeaders.join(
										", "
									)}`,
								});
								setOpen(true);
								return;
							}
						}
					}

					if (errors && errors.length > 0) {
						console.error(errors);
						// trigger modal for error here
						setResponse({
							success: false,
							successMsg: "",
							errorMsg:
								"Encountered errors parsing CSV file. Please contact support.",
						});
						setOpen(true);
						return;
					}
					const parsedData = data.map((person) => ({
						...person,
						venues: person.venues
							.trim()
							.split(";")
							.map((venue) => venue.trim()),
						alert: Booleans[person.alert.toString()],
					}));
					setPersons(parsedData);
				},
				header: true,
				skipEmptyLines: true,
			});
		}
	}, [files]);

	const batchSubscription = useSubscription(ADD_PEOPLE_BATCH_SUBSCRIPTION, {
		variables: {
			batch,
		},
		shouldResubscribe: true,
	});

	const handleUpload = async () => {
		if (selectedCustomer.length === 0) {
			setResponse({
				success: false,
				successMsg: "",
				errorMsg: "Need to select a customer.",
			});
			setOpen(true);
			return;
		}
		const uploadBatch = persons.map((person) => ({
			...person,
			customer: selectedCustomer[0],
		}));
		setBatch(uploadBatch);
		setPersons([]);
		setFiles([]);
		setUploading(true);
	};

	// handle updates to uploading progress state
	useEffect(() => {
		if (
			!batchSubscription.loading &&
			batchSubscription.data &&
			batchSubscription.data.uploadBatch
		) {
			const { state, errorMsg } = batchSubscription.data.uploadBatch;
			let newStage: UploadBatchStage;
			if (errorMsg || state === "ERROR") {
				newStage = {
					color: FEED_STAGE_COLORS.red,
					icon: ExclamationIcon,
					state,
					show: true,
					message: errorMsg
						? errorMsg
						: "Something went wrong processing upload file. Please contact support.",
				};
			} else if (state === "SUCCESS") {
				setResponse({
					success: true,
					successMsg: "Succesfully processed CSV file!",
					errorMsg: "",
				});
				setOpen(true);
				setBatch([]); // prevents resubscription with another upload
				return;
			} else {
				newStage = UploadStageDictionary[state];
			}
			// append stage
			setStages((prevStages) => [...prevStages, newStage]);
		}
	}, [batchSubscription]);

	return (
		<div className={className}>
			{!uploading ? (
				<>
					{files.length > 0 && persons.length > 0 && !uploading ? (
						<div className="flex flex-col sm:px-4 gap-y-8">
							<div className="flex">
								<button
									type="button"
									className="sm:ml-auto px-2 py-1 -mt-3.5 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
									onClick={clearFile}
								>
									Clear
								</button>
							</div>
							{isAdmin && customerFilter.length === 0 ? (
								<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
									<label
										className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
										htmlFor="customer"
									>
										Customer{" "}
										<span className="text-red-300 dark:text-red-500">
											*
										</span>
									</label>
									<div className="w-full pl-4">
										<Select
											value={customerInput}
											onChange={setCustomerInput}
											itemType={"Customer"}
											items={customers}
										/>
									</div>
								</div>
							) : (
								<></>
							)}
							{persons.length > 0 ? (
								<BatchPreview batch={persons} />
							) : (
								<></>
							)}
							<div className="flex w-full">
								<button
									type="button"
									className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
									onClick={handleUpload}
								>
									Upload
								</button>
							</div>
						</div>
					) : (
						<FileUpload
							files={files}
							setFiles={setFiles}
							accept={".csv"}
							fileType={"CSV File"}
							multiple={false}
						/>
					)}
				</>
			) : (
				<>
					{stages.length === 0 && <ScreenLoader />}
					<Feed stages={stages} />
				</>
			)}
			<ResponseModal
				open={open}
				setOpen={setOpen}
				{...response}
				title={response.success ? "Success!" : "Error"}
			>
				<></>
			</ResponseModal>
		</div>
	);
};

interface AddBatchProps {}

export const AddBatch: React.FC<AddBatchProps> = () => {
	return (
		<main>
			<div className="py-8 space-y-16 xl:space-y-20">
				<div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
					<div className="px-4 mt-16 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
									Upload People
								</h1>
								<p className="mt-2 text-sm text-gray-700 dark:text-gray-300/80">
									Upload file containing a list of people and
									their personal details, category, and images
									to be added to our facial recognition
									database.
								</p>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<Link to="/people">
									<Button
										type="button"
										className="block px-1 text-sm font-semibold text-center "
									>
										Back to People
									</Button>
								</Link>
							</div>
						</div>
					</div>
					<div className="px-4 my-8">
						<UploadBatch />
					</div>
				</div>
			</div>
		</main>
	);
};

export default AddBatch;
