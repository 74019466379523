import { gql } from "@apollo/client";

export const GET_SOFTWARE_PACKAGES = gql`
  query GetSoftwarePackages($allSoftware: Boolean = false) {
    softwarePackages(allSoftware: $allSoftware) {
      id
      name
      version
      status
    }
  }
`;
