import { gql } from "@apollo/client";

export const EDIT_USER_PHONE = gql`
  mutation EditPhone($phone: String!) {
    editPhone(phone: $phone) {
      id
      emailAddress
      phoneNumber
    }
  }
`;

export const EDIT_USER_NOTIFICATIONS = gql`
  mutation EditNotifications(
    $emailOptIn: Boolean!
    $phoneOptIn: Boolean!
    $alertOptIn: Boolean!
  ) {
    editNotifications(
      emailOptIn: $emailOptIn
      phoneOptIn: $phoneOptIn
      alertOptIn: $alertOptIn
    ) {
      id
      notifications {
        email
        sms
        alert
      }
      emailAddress
    }
  }
`;
