export const breadcrumbMap = {
  devices: {
    name: "Devices",
    to: "/devices",
  },
  edit: {
    name: "Edit",
    to: "/devices/edit",
  },
  inference: {
    name: "Inference",
    to: "/devices/edit/inference",
  },
  mode: {
    name: "Mode",
    to: "/devices/edit/mode",
  },
  software: {
    name: "Software",
    to: "/devices/edit/software",
  },
  config: {
    name: "Configuration",
    to: "/devices/edit/config",
  },
  security: {
    name: "Security",
    to: "/devices/edit/security",
  },
};
