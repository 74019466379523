import { PreviewFaces } from "../../pages/People/AddPerson";
import DragAndDrop from "./DragAndDrop";
import React from "react";

interface UploadImagesProps {
	images: File[];
	setImages: React.Dispatch<React.SetStateAction<File[]>>;
	className?: string;
}

export const UploadImages: React.FC<UploadImagesProps> = ({
	images,
	setImages,
	className = "w-full pl-4",
}) => {
	const removeFile = (index: number) => {
		const update = [...images];
		update.splice(index, 1);
		setImages(update);
	};
	return (
		<div className={className}>
			{images.length < 1 ? (
				<DragAndDrop images={images} setImages={setImages} />
			) : (
				<PreviewFaces
					faces={images}
					removeFile={removeFile}
					setFaces={setImages}
				/>
			)}
		</div>
	);
};
