import { gql } from "@apollo/client";

export const GET_PORTAL_USER = gql`
  query GET_PORTAL_USER {
    portalUser {
      id
      name
      emailAddress
      phoneNumber
      role
      # roles
      notifications {
        email
        sms
        alert
      }
      customers
      permissions
    }
  }
`;
