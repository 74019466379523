// @ts-ignore
export const GRAPHQL_URI = import.meta.env?.VITE_APP_GRAPHQL_URI
	? // @ts-ignore
	  import.meta.env?.VITE_APP_GRAPHQL_URI
	: "http://localhost:8000/graphql/api";

export const GRAPHQL_HOST = new URL(GRAPHQL_URI).host;
// @ts-ignore
export const DEV_ENV = import.meta.env?.VITE_APP_GRAPHQL_URI ? false : true;

// @ts-ignore
export const wsPrefix = import.meta.env?.VITE_APP_SECURE ? "wss" : "ws";
