import { gql } from "@apollo/client";

export const ADD_HUBSPOT_TICKETS = gql`
	mutation SubmitHubspotTickets(
		$tickets: [CreateMultipleHubspotTicketsType!]!
	) @isUpload {
		submitHubspotTickets(tickets: $tickets) {
			email
			ticketCount
			submitted
		}
	}
`;
