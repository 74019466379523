import React from "react";
import { usePolicies } from "../hooks/auth";

interface AccessControlledComponentProps {
  children: any;
  policies: string[];
  hasOne?: boolean; // only set to true when you want to show a component that has children with different required policies
}

export const AccessControlledComponent: React.FC<
  AccessControlledComponentProps
> = ({ children, policies, hasOne = false }) => {
  const { hasAccess } = usePolicies(policies, hasOne);
  if (!hasAccess) {
    return <></>;
  }
  return <>{children}</>;
};

export default AccessControlledComponent;
