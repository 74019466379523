import {
  DetailsOverview,
  DetailsOverviewTerm,
  DetailsOverviewTermTagged,
} from "../../components/Overviews/DetailsOverview";
import {
  getPercentageOfArrivals,
  getTicketsSoldPercentage,
} from "../../utils/ux";

import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { POLICIES } from "../../constants/Policies";
import { getHourRange } from "../../utils/timestamps";

export const EventDetailsOverview = ({ eventData }) => {
  if (!eventData) {
    return <></>;
  }
  return (
    <DetailsOverview>
      <DetailsOverviewTerm
        term={"Total Arrivals"}
        description={eventData.includedWalks?.toLocaleString()}
      />
      <DetailsOverviewTermTagged
        term={"Tickets Sold"}
        description={eventData.ticketsSold?.toLocaleString()}
        tags={
          eventData.ticketsSold > 0
            ? [
                {
                  color: "blue",
                  value: getTicketsSoldPercentage(
                    eventData.includedWalks,
                    eventData.ticketsSold
                  ),
                },
              ]
            : []
        }
      />
      <DetailsOverviewTermTagged
        term={"Registered Threats"}
        description={eventData.registeredThreats?.toLocaleString()}
        tags={[
          {
            color: "blue",
            value: getPercentageOfArrivals(
              eventData.includedWalks,
              eventData.registeredThreats
            ),
          },
        ]}
      />
      <DetailsOverviewTermTagged
        term={"Alerts"}
        description={eventData.alerts?.toLocaleString()}
        tags={[
          {
            color: "blue",
            value: getPercentageOfArrivals(
              eventData.includedWalks,
              eventData.alerts
            ),
          },
        ]}
      />
      <DetailsOverviewTermTagged
        term={"Max Hourly Arrivals"}
        description={eventData.maxHourlyArrivals?.toLocaleString()}
        tags={[
          {
            color: "blue",
            value: getHourRange(
              eventData.maxHourlyArrivalsStartTime,
              eventData.maxHourlyArrivalsFinishTime,
              eventData.timeZoneId
            ),
          },
        ]}
      />
      <AccessControlledComponent policies={[POLICIES.READ_PEOPLE]}>
        <DetailsOverviewTermTagged
          term={"People Recognized"}
          description={eventData.recognized?.toLocaleString()}
          tags={[
            {
              color: "green",
              value: eventData.recognizedGreen?.toLocaleString(),
            },
            {
              color: "red",
              value: eventData.recognizedRed?.toLocaleString(),
            },
          ]}
        />
      </AccessControlledComponent>
    </DetailsOverview>
  );
};

export default EventDetailsOverview;
