import { gql } from "@apollo/client";

// fill out properties to pull inside of query
export const GET_VENUE = gql`
	query GetVenue($id: String!) {
		venue(id: $id) {
			address
			name
			description
			shortCode
			phoneNumber
			emailAddress
			capacity
			timeZoneId
			mostRecentEvent
			mostRecentEventName
			mostRecentEventTime
		}
	}
`;

export const GET_VENUES = gql`
	query GetVenues(
		$skip: Int = 0
		$limit: Int = 10
		$customers: [String!] = []
	) {
		venues(skip: $skip, limit: $limit, customers: $customers) {
			count
			items {
				id
				customer
				name
				phoneNumber
				emailAddress
				address
				shortCode
				mostRecentEventTime
			}
		}
	}
`;

export const GET_USER_VENUES = gql`
	query GetUserVenues($customers: [String!] = []) {
		userVenues(customers: $customers) {
			id
			customer
			name
		}
	}
`;
