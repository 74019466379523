import PageLayout from "../../layouts/PageLayout";
import React from "react";
import SupportForm from "./SupportForm";

export default function SupportPage() {
  return (
    <PageLayout
      title="Add Support Ticket"
      description="Describe the issue for us to best help you. If possible, attach images of the problem you are facing to help us resolve it for you."
      buttonText="View Tickets"
      buttonLink="/support/tickets"
    >
      <SupportForm />
    </PageLayout>
  );
}
