import {
  Document,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";

import { GET_ENTITY_PHOTOS } from "../../schemas/queries/EntityPhotos";
import { GET_EVENT_REPORT } from "../../schemas/queries/Events";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Modal } from "../../components/Modals";
import ProfilePicture from "../../assets/images/profile_picture.jpg";
import { createTw } from "react-pdf-tailwind";
import { timestampToDate } from "../../utils/timestamps";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";
import { useTimestampStore } from "../../store/Timestamp";

const tw = createTw({
  theme: {
    extend: {
      colors: {
        transparent: "transparent",
        current: "currentColor",
        blue: {
          xonar: "#08102a",
          "xonar-light": "#1d66c2",
        },
        sky: {
          xonar: "#2d96f3",
        },
        indigo: {
          vite: "#535bf2;",
        },
        pink: {
          xonar: "#f476de",
        },
      },
    },
  },
});

export const EventDetailsReport = ({ chartURI, eventId }) => {
  const [dateString, setDateString] = useState("");
  const [displayDateString, setDisplayDateString] = useState("");
  const [venueId, setVenueId] = useState("");
  const { updateTimestamp } = useTimestampStore(
    useShallow((state) => ({
      updateTimestamp: state.updateTimestamp,
    }))
  );
  const [open, setOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [photo, setPhoto] = useState("");
  const openReport = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const eventQuery = useQuery(GET_EVENT_REPORT, {
    variables: {
      id: eventId,
      venueId,
      hasVenueId: venueId !== "",
    },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // if the venue changes, the graphql cache won't update on mutate
      setVenueId(data.event.venue);
      if (data?.entityPhotos) {
        setPhoto(data.entityPhotos[0].url);
      }
      const [date, display] = timestampToDate(data.event.eventTime);
      // isToday(data.event.eventTime);
      setDateString(date);
      setDisplayDateString(display);
      updateTimestamp();
      setInitialLoading(!venueId);
    },
  });

  return (
    <>
      <a
        className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-500 hover:cursor-pointer"
        onClick={openReport}
      >
        View Report
      </a>
      <Modal
        open={open}
        setOpen={setOpen}
        title=""
        panelClassName="rounded-md mt-16 w-[80vw] h-[80vh] transform overflow-hidden bg-white dark:bg-blue-xonar text-center align-middle shadow-xl transition-all"
      >
        {initialLoading ? (
          <div className="flex w-full h-full mx-auto">
            <LoadingSpinner
              className="w-20 h-20 mx-auto my-auto"
              w={20}
              h={20}
            />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%">
            <Document
              // title={eventQuery.data.event.name}
              author={"Xonar Technologies Inc."}
            >
              <Page size="A4" style={tw("p-4 flex flex-row flex-wrap gap-4")}>
                <View style={tw("mx-auto flex items-center justify-between")}>
                  <View style={tw("flex items-center gap-x-6")}>
                    {/* {photo ? <Image src={photo} /> : <></>} */}
                    <View style={tw("text-left")}>
                      <Text
                        style={tw(
                          "text-base font-semibold leading-6 text-gray-900"
                        )}
                      >
                        {eventQuery.data.event.name}
                      </Text>
                      <Text style={tw("text-sm leading-6 text-gray-800")}>
                        {eventQuery.data.event.venueName}
                      </Text>
                      <Text style={tw("text-xs leading-6 text-gray-500")}>
                        {displayDateString}
                      </Text>
                    </View>
                  </View>
                </View>
                {[...Array(1)].map((_, i) => (
                  <View
                    key={i}
                    style={tw("flex-1 min-w-[200pt] p-4 flex-col bg-blue-100")}
                    wrap={false}
                  >
                    <Text style={tw("text-2xl font-bold")}>
                      Section {i + 1}
                    </Text>
                    <Text style={tw("text-sm")}>
                      This is a work in progress. Xonar developers are working
                      very hard to get this done soon.
                    </Text>
                  </View>
                ))}
              </Page>
            </Document>
          </PDFViewer>
        )}
      </Modal>
    </>
  );
};

export default EventDetailsReport;
