import React from "react";
import { classNames } from "../../utils/ux";

interface TagProps {
  color: string;
  tag: string;
  className?: string;
}

export const Tag: React.FC<TagProps> = ({ color, tag, className = "" }) => {
  return (
    <span
      className={classNames(
        `px-2 py-1 text-xs font-medium text-${color}-500 bg-${color}-100 rounded-md shadow-sm dark:bg-${color}-500/50 dark:text-${color}-300`,
        className
      )}
    >
      {tag}
    </span>
  );
};

export default Tag;
