import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";

import { EDIT_USER_NOTIFICATIONS } from "../../schemas/mutators/Profile";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Tag } from "../../components/Tags";
import { usePortalUserStore } from "../../store/PortalUser";
import { useShallow } from "zustand/react/shallow";

type Props = {};

type NotificationSettingsForm = {
  smsOptIn: boolean;
  emailOptIn: boolean;
  alertOptIn: boolean;
};

export const NotificationSettings = (props: Props) => {
  const client = useApolloClient();
  const [editing, setEditing] = useState(false);
  const [editNotifications, { loading }] = useMutation(EDIT_USER_NOTIFICATIONS);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setFocus,
    setError,
    register,
    watch,
  } = useForm<NotificationSettingsForm>();
  const { portalUser, refetchPortalUser } = usePortalUserStore(
    useShallow((state) => ({
      portalUser: state.portalUser,
      refetchPortalUser: state.refetchPortalUser,
    }))
  );
  useEffect(() => {
    setValue("smsOptIn", !!portalUser?.notifications?.sms);
    setValue("emailOptIn", !!portalUser?.notifications?.email);
    setValue("alertOptIn", !!portalUser?.notifications?.alert);
  }, [portalUser, setValue]);

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name === "smsOptIn" && type === "change") {
        setValue("smsOptIn", !!data.smsOptIn);
      }
    });
    return () => subscription.unsubscribe();
  }, [portalUser, setValue]);

  const onSubmit: SubmitHandler<NotificationSettingsForm> = async (data) => {
    const updateNotifications = await editNotifications({
      variables: {
        phoneOptIn: data.smsOptIn,
        emailOptIn: data.emailOptIn,
        alertOptIn: data.alertOptIn,
      },
    });
    refetchPortalUser(client);
    setEditing(false);
  };

  return (
    <div className="mt-6 mb-2">
      <div className="flex flex-col">
        <h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
          Notifications
        </h2>
        <div className="flex flex-row justify-between w-full mt-2 text-sm">
          <p className="text-gray-700 dark:text-gray-300/80">
            Opt in or out of notifications via text messages (SMS), emails, or
            Portal alerts. You may opt in or out at any time.
          </p>
          {!editing && (
            <button
              type="button"
              className="mx-4 font-medium text-indigo-600 hover:text-indigo-900"
              onClick={() => setEditing(true)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
      <form
        className="my-2 border-b border-gray-200 dark:border-gray-200/20"
        onSubmit={handleSubmit(onSubmit)}
      >
        <dl className="divide-y divide-gray-100 dark:divide-gray-200/20">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
              SMS
            </dt>
            <dd className="flex mt-1 text-sm leading-6 text-gray-800 capitalize dark:text-gray-200 sm:col-span-2 sm:mt-0">
              {!editing ? (
                <Tag
                  color={portalUser?.notifications?.sms ? "green" : "red"}
                  tag={portalUser?.notifications?.sms ? "Enabled" : "Disabled"}
                />
              ) : (
                <>
                  <input
                    type="checkbox"
                    className="w-4 h-4 my-auto border-gray-300 rounded text-blue-xonar-light dark:text-blue-xonar-light/70 focus:ring-blue-xonar-light/50"
                    aria-describedby={"Toggle Notifications via SMS"}
                    checked={watch("smsOptIn")}
                    {...register("smsOptIn")}
                  />
                </>
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
              Emails
            </dt>
            <dd className="flex mt-1 text-sm leading-6 text-gray-800 dark:text-gray-200 sm:col-span-2 sm:mt-0">
              {!editing ? (
                <Tag
                  color={portalUser?.notifications?.email ? "green" : "red"}
                  tag={
                    portalUser?.notifications?.email ? "Enabled" : "Disabled"
                  }
                />
              ) : (
                <>
                  <input
                    type="checkbox"
                    className="w-4 h-4 my-auto border-gray-300 rounded text-blue-xonar-light dark:text-blue-xonar-light/70 focus:ring-blue-xonar-light/50"
                    aria-describedby={"Toggle Notifications via Email"}
                    checked={watch("emailOptIn")}
                    {...register("emailOptIn")}
                  />
                </>
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
              Portal Alerts
            </dt>
            <dd className="flex mt-1 text-sm leading-6 text-gray-800 dark:text-gray-200 sm:col-span-2 sm:mt-0">
              {!editing ? (
                <Tag
                  color={portalUser?.notifications?.alert ? "green" : "red"}
                  tag={
                    portalUser?.notifications?.alert ? "Enabled" : "Disabled"
                  }
                />
              ) : (
                <>
                  <input
                    type="checkbox"
                    className="w-4 h-4 my-auto border-gray-300 rounded text-blue-xonar-light dark:text-blue-xonar-light/70 focus:ring-blue-xonar-light/50"
                    aria-describedby={"Toggle Notifications via Portal Alerts"}
                    checked={watch("alertOptIn")}
                    {...register("alertOptIn")}
                  />
                </>
              )}
            </dd>
          </div>
        </dl>
        {editing && (
          <div className="flex flex-row justify-between w-full pb-6 mt-2 text-sm">
            <button
              type="button"
              className="font-medium text-indigo-600 hover:text-indigo-900"
              onClick={() => {
                setValue(
                  "smsOptIn",
                  portalUser?.notifications?.sms ? true : false
                );
                setValue(
                  "emailOptIn",
                  portalUser?.notifications?.email ? true : false
                );
                setValue(
                  "alertOptIn",
                  portalUser?.notifications?.alert ? true : false
                );
                setEditing(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="mx-2 font-medium text-indigo-600 hover:text-indigo-900"
            >
              {loading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default NotificationSettings;
