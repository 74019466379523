import { createJSONStorage, persist } from "zustand/middleware";

import { GET_CUSTOMERS } from "../schemas/queries/Customers";
import { create } from "zustand";

export interface Device {
  id: string;
  name: string;
  sharedIdentifier: string;
}

interface DeviceConfigurationState {
  devices: Device[];
  // TODO: write type for gql device model
  setDevices: (e: any | Object[]) => void;
}

export const useDeviceConfigurationStore = create<DeviceConfigurationState>(
  (set, get) => ({
    devices: [],
    // TODO: write type for gql device model
    setDevices: (selectedDevices: any | Object[]) => {
      set((state) => ({ devices: selectedDevices }));
    },
  })
);
