import { Listbox, Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useShallow } from "zustand/react/shallow";
import { useCustomerStore } from "../../store/Customer";
import { classNames } from "../../utils/ux";

export const AdminMenu = () => {
	const {
		customer,
		setCustomer,
		clearCustomer,
		customers,
		customersFetched,
	} = useCustomerStore(
		useShallow((state) => ({
			customer: state.customer,
			setCustomer: state.setCustomer,
			clearCustomer: state.clearCustomer,
			customers: state.customers,
			customersFetched: state.customersFetched,
		}))
	);

	if (!customersFetched || customers.length === 1) {
		return <></>;
	}

	return (
		<>
			<div className="py-2 border-t border-gray-200 dark:border-gray-200/20">
				<Listbox
					as="div"
					className="relative"
					value={customer}
					onChange={setCustomer}
				>
					{({ open }) => (
						<>
							<Listbox.Label className={"sr-only"}>
								Select Customer
							</Listbox.Label>
							<div className="relative">
								<Listbox.Button className="relative w-full py-2 pl-4 pr-10 text-left transition duration-150 ease-in-out bg-white cursor-default dark:bg-blue-xonar dark:text-gray-200 focus:outline-none sm:text-sm sm:leading-5 rounded-none hover:cursor-pointer hover:bg-gray-100 dark:hover:bg-indigo-vite">
									<span className="block truncate">
										{customer?.customerName
											? customer?.customerName
											: "Select Customer"}
									</span>
									<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
										<svg
											className="w-5 h-5 text-gray-400"
											viewBox="0 0 20 20"
											fill="none"
											stroke="currentColor"
										>
											<path
												d="M7 7l3-3 3 3m0 6l-3 3-3-3"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								</Listbox.Button>
								<Transition
									show={open}
									as={Fragment}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Listbox.Options className="absolute z-10 w-full py-2 mt-1 overflow-auto text-base bg-white shadow-lg dark:bg-blue-xonar ring-1 ring-black ring-opacity-5 dark:ring-white/10 focus:outline-none sm:text-sm max-h-60">
										{customers.map((customer) => (
											<Listbox.Option
												key={customer.id}
												value={customer}
												className={({ active }) =>
													classNames(
														active
															? "text-white bg-indigo-600 dark:bg-indigo-vite"
															: "text-gray-900 dark:text-gray-200",
														"cursor-default select-none relative py-2 pl-8 pr-4"
													)
												}
											>
												{({ selected, active }) => (
													<>
														<span
															className={classNames(
																selected
																	? "font-semibold"
																	: "font-normal",
																"block truncate"
															)}
														>
															{
																customer.customerName
															}
														</span>
														{selected && (
															<span
																className={classNames(
																	active
																		? "text-white"
																		: "text-indigo-600 dark:text-indigo-400",
																	"absolute inset-y-0 left-0 flex items-center pl-1.5"
																)}
															>
																<svg
																	className="w-5 h-5"
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 20 20"
																	fill="currentColor"
																>
																	<path
																		fillRule="evenodd"
																		d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																		clipRule="evenodd"
																	/>
																</svg>
															</span>
														)}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</>
					)}
				</Listbox>
			</div>
			{customer?.id && (
				<Menu.Item>
					{({ active }) => (
						<div
							className={classNames(
								active ? "bg-gray-100 dark:bg-indigo-vite" : "",
								"block px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-200 dark:hover:text-gray-300 hover:cursor-pointer"
							)}
							onClick={clearCustomer}
						>
							View All
						</div>
					)}
				</Menu.Item>
			)}
		</>
	);
};
