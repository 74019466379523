import { ApolloProvider } from "./ApolloProvider.jsx";
import { AuthProvider } from "./AuthProvider.jsx";
import ThemeProvider from "./ThemeProvider.jsx";

const PROVIDERS = [AuthProvider, ApolloProvider, ThemeProvider];

export default function ContextProvider({ children, providers = PROVIDERS }) {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
}
