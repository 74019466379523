import { gql } from "@apollo/client";

export const READ_USER_ALERT = gql`
  mutation ReadUserAlert($notification: String!) {
    readAlert(notification: $notification) {
      id
      method
      notifier
      message
      viewed
      viewedTime
      cleared
      clearedTime
    }
  }
`;

export const READ_USER_ALERTS = gql`
  mutation ReadUserAlert($notifications: [String!]!) {
    readAlerts(notifications: $notifications) {
      id
      method
      notifier
      message
      viewed
      viewedTime
      cleared
      clearedTime
    }
  }
`;

export const CLEAR_USER_ALERT = gql`
  mutation ClearUserAlert($notification: String!) {
    clearAlert(notification: $notification) {
      id
      method
      notifier
      message
      viewed
      viewedTime
      cleared
      clearedTime
    }
  }
`;

export const CLEAR_USER_ALERTS = gql`
  mutation ClearUserAlert($notifications: [String!]!) {
    clearAlerts(notifications: $notifications) {
      id
      method
      notifier
      message
      viewed
      viewedTime
      cleared
      clearedTime
    }
  }
`;
