import React from "react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "../../utils/ux";

export type BreadcrumbPage = {
  name: string;
  to: string;
  current: boolean;
};

interface BreadcrumbsProps {
  pages: BreadcrumbPage[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pages }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {pages.map(({ name, to, current }, i) => {
          return (
            <li key={name}>
              <div className="flex flex-row items-center">
                {i == 0 ? (
                  <></>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="flex-shrink-0 w-5 h-5 text-gray-400 dark:text-gray-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                )}
                <Link
                  to={to}
                  className={classNames(
                    current
                      ? "text-indigo-600 dark:text-indigo-400"
                      : "text-gray-500 dark:text-gray-400",
                    "ml-4 text-sm font-medium  hover:text-indigo-600 dark:hover:text-indigo-400"
                  )}
                >
                  {name}
                </Link>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
