import React, { useState } from "react";
import {
  ResponseModal,
  ResponseProps,
} from "../../../components/Modals/ResponseModal";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { GET_VENUES } from "../../../schemas/queries/Venues";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { MultiSelect } from "../../../components/Menus/MultiSelect";
import { UPDATE_PERSON } from "../../../schemas/mutators/People";
import { VenueType } from ".";
import isAlpha from "validator/es/lib/isAlpha";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import { useParams } from "react-router-dom";

interface EditDetailsProps {
  name?: string;
  email?: string;
  phone?: string;
  venue?: VenueType[];
  alert?: boolean;
  description?: string;
  customer?: string;
  refetch?: any;
}

type EditDetailsForm = {
  name?: string;
  email?: string;
  phone?: string;
  venue?: VenueType[] | string[];
  alert?: boolean;
  description?: string;
};

export const EditDetails: React.FC<EditDetailsProps> = ({
  name,
  email,
  phone,
  venue,
  alert,
  description,
  customer,
  refetch,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<EditDetailsForm>();
  const { personId } = useParams();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });
  const [selectedVenue, setSelectedVenue] = useState<VenueType[]>([]);

  const venueQuery = useQuery(GET_VENUES, {
    variables: {
      skip: 0,
      limit: 10000, // hard code limit :)
      customers: [customer],
    },
    onCompleted: (data) => {
      const existing = data.venues.items.filter((venueData) =>
        // @ts-ignore
        venue.some((v) => v.id === venueData.id)
      );
      setSelectedVenue(existing);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [EditPerson, { loading, error }] = useMutation(UPDATE_PERSON);

  const onSubmit: SubmitHandler<EditDetailsForm> = async (data) => {
    data.venue = selectedVenue;
    data.name = data.name?.trim();
    const words = data.name?.split(/\s+/).filter((word) => !isAlpha(word));
    if (data.name && words && words.length > 0) {
      setError("name", {
        type: "validate",
        message: "Please enter a valid alphabetical name!",
      });
      setFocus("name");
      return;
    }
    data.email = data.email?.trim();
    if (data.email && !isEmail(data.email)) {
      setError("email", {
        type: "validate",
        message: "Please enter a valid email!",
      });
      setFocus("email");
      return;
    }
    data.phone = data.phone?.trim();
    if (data.phone && !isMobilePhone(data.phone)) {
      setError("phone", {
        type: "validate",
        message: "Please enter a valid phone number!",
      });
      setFocus("phone");
      return;
    }

    // if its all the venues, then venue should be empty list to cover all of them
    if (data.venue.length == venueQuery.data.venues.items.length) {
      data.venue = [];
    } else {
      data.venue = data.venue.map((venueData) => venueData.id);
    }

    const updatedPerson = await EditPerson({
      variables: {
        id: personId,
        person: data,
      },
    });
    if (updatedPerson.errors && updatedPerson.errors.length > 0) {
      for (const error of updatedPerson.errors) {
        console.error(error);
      }
      setResponse({
        ...response,
        success: false,
        errorMsg: `Failed to update details. Please contact support or try again.`,
      });
      setOpen(true);
      return;
    }
    setResponse({
      ...response,
      success: true,
      successMsg: `Updated details for ${updatedPerson.data.updatePerson.name}!`,
    });
    setOpen(true);
    await refetch();
  };

  return (
    <section>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col divide-y divide-gray-100 dark:divide-gray-200/20 [&>*]:py-8"
      >
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="name"
          >
            Full Name
          </label>
          <div className="w-full pl-4">
            <input
              id="name"
              aria-describedby={"Person's Full Name"}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
              placeholder={name}
              {...register("name")}
            />
            {errors.name?.message && (
              <span role="alert" className="text-red-300 dark:text-red-500">
                {errors.name?.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="venue"
          >
            Venue
          </label>

          <div className="flex flex-col w-full pl-4 gap-y-4">
            {!venueQuery.loading ? (
              <>
                <MultiSelect
                  itemType="venue"
                  items={venueQuery.data.venues.items}
                  {...(register("venue"),
                  {
                    value: selectedVenue,
                    onChange: (e: any) => {
                      setSelectedVenue(e);
                    },
                  })}
                />
                <span className="text-xs text-gray-400 dark:text-gray-500">
                  If no Venue is selected, this person will be added to all of
                  your Venues.
                </span>
              </>
            ) : (
              <p className="text-sm font-medium leading-8 text-gray-900 dark:text-gray-200">
                Loading Venues...
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="alert"
          >
            Arrival Alerts
          </label>
          <div className="flex flex-row w-full pl-4 gap-x-3 lg:gap-x-6">
            <input
              id="alert"
              type="checkbox"
              className="w-4 h-4 my-auto border-gray-300 rounded text-blue-xonar-light dark:text-blue-xonar-light/70 focus:ring-blue-xonar-light/50"
              aria-describedby={"Alert upon person's arrival"}
              defaultChecked={alert}
              {...register("alert")}
            />
            <span className="text-sm font-medium leading-8 text-gray-900 dark:text-gray-200">
              {`You will ${
                watch("alert") ? "" : "not"
              } receive email and text alerts when this person arrives if you have enabled them.`}
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="email"
          >
            Email Address
          </label>
          <div className="w-full pl-4">
            <input
              aria-describedby={"Person's Email"}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
              placeholder={email ? email : "N/A"}
              type="email"
              id="email"
              {...register("email")}
            />

            {errors.email?.message && (
              <span role="alert" className="text-red-300 dark:text-red-500">
                {errors.email?.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="phone"
          >
            Phone Number
          </label>
          <div className="w-full pl-4">
            <input
              aria-describedby={"Person's Phone Number"}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
              placeholder={phone ? phone : "N/A"}
              type="tel"
              id="phone"
              {...register("phone")}
            />
            {errors.phone?.message && (
              <span role="alert" className="text-red-300 dark:text-red-500">
                {errors.phone?.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="description"
          >
            About
          </label>
          <div className="w-full pl-4">
            <textarea
              rows={5}
              className="resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
              aria-describedby={"About Person"}
              defaultValue={description}
              id="description"
              {...register("description")}
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex w-full">
            <button
              type="submit"
              className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
            >
              {loading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={response.success ? "Success!" : "Failed to Edit Person"}
      />
    </section>
  );
};

export default EditDetails;
