import { usePageStyles, usePageWindow } from "./PaginationHooks";

import { classNames } from "../../utils/ux";

export const Pagination = ({
  skip,
  onSkip,
  count,
  limit,
  currentAmount,
  itemType = "results",
  className = "",
  background = "",
}) => {
  const { pages, handlePageClick, firstWindow, lastWindow } = usePageWindow({
    onSkip,
    count,
    limit,
  });
  const { pageClass, pageClassActive } = usePageStyles();

  return (
    <div className={className}>
      <div
        className={classNames(
          "flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 dark:bg-gray-900 dark:border-gray-200/20 sm:px-6",
          background
        )}
      >
        <div className="flex justify-between flex-1 sm:hidden">
          {skip > 0 && (
            <>
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md dark:border-gray-200/40 dark:bg-gray-900 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-blue-xonar first:mr-auto"
                onClick={(e) => handlePageClick(e, skip > 1 ? skip : 1)}
              >
                Previous
              </a>
            </>
          )}

          {skip < Math.ceil(count / limit - 1) && (
            <>
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md dark:border-gray-200/40 dark:bg-gray-900 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-blue-xonar last:ml-auto"
                onClick={(e) => handlePageClick(e, skip + 2)}
              >
                Next
              </a>
            </>
          )}
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700 dark:text-gray-400">
              Viewing{" "}
              <span className="font-medium">
                {limit * skip + currentAmount}
              </span>{" "}
              of <span className="font-medium">{count}</span> {itemType}
            </p>
          </div>
          <div className="sm:mt-1">
            <nav
              className="inline-flex -space-x-px rounded-md shadow-sm isolate"
              aria-label="Pagination"
            >
              {skip > 0 && (
                <a
                  href=""
                  className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-md dark:hover:bg-gray-700 dark:hover:text-gray-300 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={(e) => handlePageClick(e, skip > 1 ? skip : 1)}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                  </svg>
                </a>
              )}
              {pages.length < 6 ? (
                <>
                  {pages.map((page, i) => {
                    return (
                      <a
                        href=""
                        key={`${page}-page-${i}`}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                          skip === page - 1 ? pageClassActive : pageClass
                        }`}
                        onClick={(e) => handlePageClick(e, page)}
                      >
                        {page}
                      </a>
                    );
                  })}
                </>
              ) : (
                <>
                  {firstWindow.map((page, i) => {
                    return (
                      <a
                        href=""
                        key={`${page}-page-${i}`}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold  ${
                          skip === page - 1 ? pageClassActive : pageClass
                        }`}
                        onClick={(e) => handlePageClick(e, page)}
                      >
                        {page}
                      </a>
                    );
                  })}
                  {skip < pages.length - 5 && (
                    <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                      ...
                    </span>
                  )}
                  {lastWindow.map((page, i) => {
                    return (
                      <a
                        href=""
                        key={`${page}-page-${i}`}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold  ${
                          skip === page - 1 ? pageClassActive : pageClass
                        }`}
                        onClick={(e) => handlePageClick(e, page)}
                      >
                        {page}
                      </a>
                    );
                  })}
                </>
              )}
              {skip < Math.ceil(count / limit - 1) && (
                <a
                  href=""
                  className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md dark:hover:bg-gray-700 dark:hover:text-gray-300 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={(e) => handlePageClick(e, skip + 2)}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </a>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
