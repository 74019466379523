import { useCallback, useMemo, useState } from "react";

export const usePaginationState = (
	initialSkip = 0,
	initialLimit = 6,
	totalItems = 0
) => {
	const [skip, setSkip] = useState(initialSkip);
	const [limit, setLimit] = useState(initialLimit);

	const reset = useCallback(() => {
		setSkip(0);
	}, []);

	const currentPage = useMemo(
		() => Math.floor(skip / limit) + 1,
		[skip, limit]
	);
	const totalPages = useMemo(
		() => Math.ceil(totalItems / limit),
		[totalItems, limit]
	);

	return {
		skip,
		limit,
		setSkip,
		setLimit,
		reset,
		currentPage,
		totalPages,
	};
};
