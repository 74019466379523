import React, { useState } from "react";
import { EditDevicesHeader } from "../EditDevicesHeader";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  ResponseModal,
  ResponseProps,
} from "../../../../components/Modals/ResponseModal";
import { useMutation, useQuery } from "@apollo/client";
import { useDeviceConfigurationStore } from "../../../../store/DeviceConfiguration";
import { useShallow } from "zustand/react/shallow";
import { GET_CELL_MODES } from "../../../../schemas/queries/CellModes";
import { Select } from "../../../../components/Menus/Select";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { toCapitalized } from "../../../../utils/ux";
import { EDIT_CELL_MODE } from "../../../../schemas/mutators/Devices";
import { Tag } from "../../../../components/Tags";

type EditModeForm = {
  mode?: ModeType;
};

interface ModeResponseProps {
  mode?: ModeType;
}

type ModeType = {
  id: string;
  name: string;
};

const ModeResponse: React.FC<ModeResponseProps> = ({ mode }) => {
  return (
    <div className="flex flex-col gap-y-6">
      {mode ? (
        <div className="flex flex-row gap-x-2">
          <p className="text-gray-800 dark:text-gray-300">Device Mode</p>
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            <Tag color="gray" tag={mode.name} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

interface ModePanelProps {}

export const ModePanel: React.FC<ModePanelProps> = () => {
  const { handleSubmit } = useForm<EditModeForm>();
  // @ts-ignore
  const [selectedMode, setSelectedMode] = useState<ModeType>({});
  const [modes, setModes] = useState<ModeType[]>([]);

  const handleReset = () => {
    // @ts-ignore
    setSelectedMode({});
  };

  const { devices } = useDeviceConfigurationStore(
    useShallow((state) => ({
      devices: state.devices,
    }))
  );

  const modeQuery = useQuery(GET_CELL_MODES, {
    onCompleted: (data) => {
      const cellModes = data.modes.cellModeOptions.map((mode, i) => ({
        id: `device-mode-${i}`,
        name: mode,
      }));

      setModes(cellModes);
    },
  });

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });
  const [modeResponse, setModeResponse] = useState<ModeResponseProps>({});

  const [editCellMode, { loading, error }] = useMutation(EDIT_CELL_MODE, {
    onError: (error) => {
      console.error(error);
    },
  });

  const onSubmit: SubmitHandler<EditModeForm> = async (data) => {
    if (devices.length < 1) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select one Device to update.",
      });
      setModeResponse({});
      setOpen(true);
      return;
    }

    const cellIds = devices.map(({ id }) => id);
    const mode = selectedMode?.name?.trim();
    if (!mode) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select a Mode to change Devices to.",
      });
      setModeResponse({});
      setOpen(true);
      return;
    }

    const modeInput = {
      mode: mode,
    };
    const mqttMessage = await editCellMode({
      variables: {
        ids: cellIds,
        update: modeInput,
      },
    });
    setResponse({
      ...response,
      success: true,
      successMsg: "Changing devices to have these settings...",
    });
    setModeResponse({
      mode: selectedMode,
    });
    setOpen(true);
  };

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <EditDevicesHeader />
      <div className="px-12 mt-6 mb-4">
        {modeQuery.loading ? (
          <LoadingSpinner h={12} w={12} className="mx-auto my-auto" />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col [&>*]:py-3"
          >
            <div className="flex w-full">
              <button
                type="button"
                className="px-2 py-1 -mt-3.5 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
                onClick={handleReset}
              >
                Clear
              </button>
            </div>

            <div className="flex flex-col md:flex-row md:justify-between gap-x-4 lg:gap-x-0">
              <label
                className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
                htmlFor="mode"
              >
                Device Mode
              </label>
              <div className="w-full">
                {!modeQuery.loading ? (
                  <>
                    <Select
                      value={selectedMode.name ? selectedMode : ""}
                      onChange={setSelectedMode}
                      itemType="Mode"
                      items={modes}
                      menuHeight={"max-h-48"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="flex w-full mt-2">
              <button
                type="submit"
                className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
                onSubmit={(e) => handleSubmit(onSubmit)}
                onClick={(e) => handleSubmit(onSubmit)}
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        )}
      </div>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={response.success ? "Requested to Change Device Mode" : "Error"}
      >
        <ModeResponse {...modeResponse} />
      </ResponseModal>
    </div>
  );
};

export default ModePanel;
