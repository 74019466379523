import { Listbox, Transition } from "@headlessui/react";

import React from "react";
import { toCapitalized } from "../../utils/ux";
import { classNames } from "../../utils/ux";

interface Props {
	value: any;
	onChange: React.Dispatch<React.SetStateAction<any>> | ((e: any) => void);
	itemType: string;
	items: any[];
	menuHeight?: string;
}

export const MultiSelect: React.FC<Props> = ({
	value,
	onChange,
	itemType,
	items,
	menuHeight = "",
}) => {
	const label = toCapitalized(itemType);
	return (
		<div className="flex items-center">
			<div className="w-full">
				<Listbox
					as="div"
					className="relative space-y-1"
					value={value}
					onChange={(e) => {
						onChange(e);
					}}
					multiple
				>
					{({ open }) => (
						<>
							<Listbox.Label className={"sr-only"}>
								{label}s
							</Listbox.Label>
							<div className="relative">
								<span className="inline-block w-full rounded-md shadow-sm">
									<Listbox.Button className="relative w-full h-8 pl-3 pr-10 text-left text-gray-900 transition duration-150 ease-in-out bg-white border-0 border-gray-300 rounded-md cursor-default dark:bg-blue-xonar dark:text-gray-200 focus:outline-none focus:shadow-outline-blue sm:text-sm sm:leading-5 ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light dark:border-gray-200/30">
										<span className="block truncate">
											{value.length < 1
												? `Select ${label}`
												: `Selected (${
														value.length
												  }) ${label}${
														value.length > 1
															? "s"
															: ""
												  }`}
										</span>
										<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
											<svg
												className="w-5 h-5 text-gray-400"
												viewBox="0 0 20 20"
												fill="none"
												stroke="currentColor"
											>
												<path
													d="M7 7l3-3 3 3m0 6l-3 3-3-3"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</span>
									</Listbox.Button>
								</span>
								<Transition
									show={open}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
									className="bg-white rounded-md shadow-lg"
								>
									<Listbox.Options
										className={classNames(
											"absolute z-10 w-full py-2 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-blue-xonar ring-1 ring-black ring-opacity-5 dark:ring-white/10 focus:outline-none sm:text-sm",
											menuHeight ? menuHeight : "max-h-24"
										)}
									>
										{items &&
											items?.length &&
											items.map((item) => {
												return (
													<Listbox.Option
														key={item.id}
														value={item}
													>
														{({
															selected,
															active,
														}) => (
															<div
																className={`${
																	active
																		? "text-white bg-indigo-600 dark:bg-indigo-vite dark:text-gray-300"
																		: "text-gray-900 dark:text-gray-200"
																} cursor-default select-none relative py-2 pl-8 pr-4`}
															>
																<span
																	className={`${
																		selected
																			? "font-semibold"
																			: "font-normal"
																	} block truncate`}
																>
																	{item.name}
																</span>
																{selected && (
																	<span
																		className={`${
																			active
																				? "text-white"
																				: "text-indigo-600 dark:text-indigo-400"
																		} absolute inset-y-0 left-0 flex items-center pl-1.5`}
																	>
																		<svg
																			className="w-5 h-5"
																			xmlns="http://www.w3.org/2000/svg"
																			viewBox="0 0 20 20"
																			fill="currentColor"
																		>
																			<path
																				fillRule="evenodd"
																				d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																				clipRule="evenodd"
																			/>
																		</svg>
																	</span>
																)}
															</div>
														)}
													</Listbox.Option>
												);
											})}
									</Listbox.Options>
								</Transition>
							</div>
						</>
					)}
				</Listbox>
			</div>
		</div>
	);
};

export default MultiSelect;
