import { GET_VENUES } from "../../schemas/queries/Venues";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import VenueCard from "./VenueCard";
import { useCustomerStore } from "../../store/Customer";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";

export const Venues = () => {
  const navigate = useNavigate();
  const [venueLimit, setVenueLimit] = useState(10);
  const [venueSkip, setVenueSkip] = useState(0);
  const [venues, setVenues] = useState([]);
  const { customerFilter } = useCustomerStore(
    useShallow((state) => ({
      customerFilter: state.customerFilter,
    }))
  );

  const { loading } = useQuery(GET_VENUES, {
    variables: {
      skip: venueSkip,
      limit: venueLimit,
      customers: customerFilter,
    },
    // TODO: pagination
    onCompleted: (data) => {
      setVenues(data.venues.items);
    },
    onError: (error) => {
      console.error(error.message);
      navigate("/error", {
        state: {
          status: 404,
          error: "Something went wrong querying for Venues.",
        },
      });
    },
  });
  if (loading) return <ScreenLoader />;

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <ul
        role="list"
        className="divide-y divide-gray-100 dark:divide-gray-200/20"
      >
        {venues.map((venue) => {
          return <VenueCard key={venue.id} venueData={venue} />;
        })}
      </ul>
      {/* TODO: pagination for when venue list grows */}
    </div>
  );
};
