import React, { useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { LoadingSpinner } from "./LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { usePolicies } from "../hooks/auth";

export const ProtectedComponent = ({ component, policiesRequired = [] }) => {
	const navigate = useNavigate();
	const { hasAccess } = usePolicies(policiesRequired);
	const { isAuthenticated, isLoading } = useAuth0();

	// useEffect(() => {
	//   if (!isLoading && !isAuthenticated && !hasAccess) {
	//     navigate("/error", {
	//       state: { status: 401, error: "Unauthorized to access resource." },
	//     });
	//   }
	// }, [navigate, hasAccess, isAuthenticated, isLoading]);

	// TODO: place the loading spinner in the center of the view
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => {
			return <LoadingSpinner />;
		},
	});

	return <Component />;
};
