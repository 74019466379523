import { useMutation, useQuery } from "@apollo/client";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	CLEAR_USER_ALERT,
	CLEAR_USER_ALERTS,
	READ_USER_ALERTS,
} from "../../schemas/mutators/Notifications";
import { GET_USER_ALERTS } from "../../schemas/queries/Notifications";
import { NotificationItem, NotificationType } from "./NotificationItem";

export const NotificationBell: React.FC = (props) => {
	const [notifications, setNotifications] = useState<NotificationType[]>([]);
	const notificationQuery = useQuery(GET_USER_ALERTS, {
		pollInterval: 2500, // poll every 2.5 seconds
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			if (data.notifications) {
				setNotifications(data.notifications);
			}
		},
	});
	const [readNotifications] = useMutation(READ_USER_ALERTS);
	const [clearNotification] = useMutation(CLEAR_USER_ALERT);
	const [clearNotifications] = useMutation(CLEAR_USER_ALERTS);

	const [isOpen, setIsOpen] = useState(false);
	const [isAnimating, setIsAnimating] = useState(false);
	const [isPulsing, setIsPulsing] = useState(false);

	const unreadCount = notifications.filter((n) => !n.viewed).length;
	const [prevUnreadCount, setPrevUnreadCount] = useState(unreadCount);
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
			setPrevUnreadCount(unreadCount);
		} else if (unreadCount > prevUnreadCount) {
			setIsAnimating(true);
			setIsPulsing(true);
			setTimeout(() => {
				setIsAnimating(false);
				setIsPulsing(false);
			}, 500);
		}
		setPrevUnreadCount(unreadCount);
	}, [unreadCount, prevUnreadCount]);

	const handleActionButton = async () => {
		if (unreadCount > 0) {
			await readNotifications({
				variables: {
					notifications: notifications.map((n) => n.id),
				},
			});
		} else {
			await clearNotifications({
				variables: {
					notifications: notifications.map((n) => n.id),
				},
			});
		}
		notificationQuery.refetch();
	};

	return (
		<Popover className="relative">
			{({ open }) => (
				<>
					<Popover.Button
						className="relative flex items-center mr-2 text-gray-400 hover:text-gray-500 focus:outline-none"
						aria-label="Notifications"
						onClick={() => {
							setIsAnimating(true);
							setTimeout(() => setIsAnimating(false), 500); // Duration of the animation
						}}
					>
						<div
							className={`p-1 ${isAnimating ? "bell-shake" : ""}`}
						>
							<svg
								className="w-6 h-6"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
								/>
							</svg>
						</div>
						{unreadCount > 0 && (
							<span
								className={`absolute flex items-center justify-center w-5 h-5 text-xs font-semibold text-center text-white rounded-full -right-2 -top-1 bg-pink-xonar-violet dark:bg-pink-xonar-sky-magenta ring-1 ring-gray-200 dark:ring-gray-700 select-none ${
									isPulsing ? "count-pulse" : ""
								}`}
							>
								{unreadCount}
							</span>
						)}
					</Popover.Button>
					<Transition
						show={open}
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
						afterEnter={() => setIsOpen(true)}
						afterLeave={() => setIsOpen(false)}
					>
						<Popover.Panel className="absolute z-10 mt-2 rounded-lg -right-6 sm:right-0">
							<div
								className={`overflow-hidden overflow-y-auto bg-gray-100 ${
									notifications.length > 0
										? "rounded-t-xl"
										: "rounded-xl"
								} shadow-lg dark:bg-gray-200 max-h-80 w-80 ring-1 ring-black/5`}
							>
								{notifications.length > 0 ? (
									notifications.map((notification) => (
										<NotificationItem
											key={notification.id}
											{...notification}
											isPopoverOpen={isOpen}
											refetch={notificationQuery.refetch}
										/>
									))
								) : (
									<div className="p-2 bg-gray-50">
										<p className="text-center text-blue-900">
											No new notifications
										</p>
									</div>
								)}
							</div>
							{notifications.length > 0 && (
								<div className="p-3 transition-colors duration-300 ease-in-out rounded-b-2xl bg-blue-xonar-light hover:bg-blue-xonar-light/90 ring-1 ring-gray-200 dark:ring-gray-800 select-none">
									<button
										className="w-full text-sm font-semibold text-gray-50 dark:text-gray-200"
										onClick={handleActionButton}
									>
										{unreadCount > 0
											? "Read All Notifications"
											: "Clear All Notifications"}
									</button>
								</div>
							)}
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};
