import React, { useState } from "react";
import { DetailsHeader } from "../components/Headers/DetailsHeader";
import { timestampToDate, getFullDisplayTimestamp } from "../utils/timestamps";
import {
	EyeIcon,
	EyeSlashIcon,
	ClipboardIcon,
} from "@heroicons/react/20/solid";

interface ReportLayoutProps {
	venuePhoto?: string;
	venueName?: string;
	eventName?: string;
	eventTime?: number;
	createdTime?: number;
	timeZoneId?: string;
	passcode: string;
	shortCode?: string;
	children: React.ReactNode;
}

export default function ReportLayout({
	venuePhoto,
	venueName,
	eventName,
	eventTime,
	createdTime,
	timeZoneId,
	passcode,
	shortCode,
	children,
}: ReportLayoutProps) {
	const [passcodeVisible, setPasscodeVisible] = useState(false);

	const [date, display] = timestampToDate(eventTime);
	const reportCreatedTime = getFullDisplayTimestamp(createdTime, timeZoneId);

	return (
		<main className="w-full">
			<DetailsHeader>
				<div className="flex items-center gap-x-6">
					<img
						src={venuePhoto}
						alt={venueName ? venueName : "Event Venue"}
						className="flex-none w-16 h-16 rounded-full ring-1 ring-gray-900/10"
					/>
					<h1>
						<div className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
							{eventName}
						</div>
						<div className="text-sm leading-6 text-gray-800 dark:text-gray-300">
							{venueName}
						</div>
						<div className="text-sm leading-6 text-gray-500 dark:text-gray-300/80">
							<time dateTime={date}>{display}</time>
						</div>
						<div className="flex flex-col my-2 space-y-1">
							<span className="pr-4 ml-auto text-xs leading-4 text-gray-400 dark:text-gray-200/80">
								Generated {reportCreatedTime}
							</span>
						</div>
						<div className="flex flex-col mt-4 gap-y-2">
							<div className="flex flex-row text-gray-400 gap-x-1 sm:hidden dark:text-gray-200/80">
								<span className="flex pr-4 text-xs leading-4 text-gray-400 sm:hidden dark:text-gray-200/80">
									<ClipboardIcon
										className="w-4 h-4 mr-2 hover:cursor-pointer hover:text-indigo-500"
										onClick={async () =>
											await navigator.clipboard.writeText(
												passcode
											)
										}
									/>
									Passcode:{" "}
									{passcodeVisible ? passcode : "••••••••"}
									{passcodeVisible ? (
										<EyeIcon
											className="flex w-4 h-4 mx-3 sm:hidden hover:cursor-pointer hover:text-indigo-500"
											onClick={() =>
												setPasscodeVisible(false)
											}
										/>
									) : (
										<EyeSlashIcon
											className="flex w-4 h-4 mx-3 sm:hidden hover:cursor-pointer hover:text-indigo-500"
											onClick={() =>
												setPasscodeVisible(true)
											}
										/>
									)}
								</span>
							</div>
							<span
								className="flex pr-4 mr-auto text-xs leading-4 text-gray-400 sm:hidden dark:text-gray-200/80 hover:cursor-pointer hover:text-indigo-500"
								onClick={async () =>
									await navigator.clipboard.writeText(
										`https://u.xonar.com/${shortCode}`
									)
								}
							>
								<ClipboardIcon className="w-4 h-4 mr-2 hover:text-indigo-500" />
								<span className="hover:text-indigo-500">
									Share URL
								</span>
							</span>
						</div>
					</h1>
				</div>
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row text-gray-400 gap-x-1 sm:inline-block dark:text-gray-200/80">
						<span className="hidden pr-4 ml-auto text-xs leading-4 text-gray-400 sm:flex dark:text-gray-200/80">
							<ClipboardIcon
								className="w-4 h-4 mx-2 hover:cursor-pointer hover:text-indigo-500"
								onClick={async () =>
									await navigator.clipboard.writeText(
										passcode
									)
								}
							/>
							Passcode: {passcodeVisible ? passcode : "••••••••"}
							{passcodeVisible ? (
								<EyeIcon
									className="hidden w-4 h-4 mx-3 sm:flex hover:cursor-pointer hover:text-indigo-500"
									onClick={() => setPasscodeVisible(false)}
								/>
							) : (
								<EyeSlashIcon
									className="hidden w-4 h-4 mx-3 sm:flex hover:cursor-pointer hover:text-indigo-500"
									onClick={() => setPasscodeVisible(true)}
								/>
							)}
						</span>
					</div>
					<span
						className="hidden pr-4 mr-auto text-xs leading-4 text-gray-400 sm:flex dark:text-gray-200/80 hover:cursor-pointer hover:text-indigo-500"
						onClick={async () =>
							await navigator.clipboard.writeText(
								`https://u.xonar.com/${shortCode}`
							)
						}
					>
						<ClipboardIcon className="w-4 h-4 mx-2 hover:text-indigo-500" />
						<span className="hover:text-indigo-500">Share URL</span>
					</span>
				</div>
			</DetailsHeader>
			<div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
				{children}
			</div>
		</main>
	);
}
