export const Booleans = {
  true: true,
  True: true,
  false: false,
  False: false,
  yes: true,
  Yes: true,
  y: true,
  Y: true,
  no: false,
  No: false,
  n: false,
  N: false,
};
