import { useEffect, useState } from "react";

import { Button } from "../../components/Buttons";
import { EDIT_EVENT } from "../../schemas/mutators/Events";
import { Modal } from "../../components/Modals/";
import { TextInput } from "../../components/Inputs/TextInput";
import toInt from "validator/lib/toInt";
import { useInputWithError } from "../../hooks/inputWithError";
import { useMutation } from "@apollo/client";

export const EventEditModal = ({ eventId, eventData, refetch }) => {
  const [open, setOpen] = useState(false);
  //   TODO: event name input error handling
  //   What are the input requirements here?
  const [eventName, setEventName] = useInputWithError(undefined);
  const [
    eventTicketsSold,
    setEventTicketsSold,
    eventTicketsSoldError,
    setEventTicketsSoldError,
  ] = useInputWithError(undefined);

  const [editError, setEditError] = useState("");

  useEffect(() => {
    // clear modal error after 3 seconds
    setTimeout(() => {
      setEditError("");
    }, 3000);
  }, [editError]);

  const [editEvent, { loading, error }] = useMutation(EDIT_EVENT, {
    variables: {
      id: eventId,
    },
  });

  const clearInputs = () => {
    setEventName("");
    setEventTicketsSold("");
    setEventTicketsSoldError(false);
  };

  const openModal = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleName = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setEventName(value);
  };

  const handleTicketsSold = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const num = toInt(value);
    if (value !== "" && (num < 0 || isNaN(num))) {
      setEventTicketsSoldError(true);
      setEventTicketsSold("");
    } else {
      setEventTicketsSoldError(false);
      setEventTicketsSold(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (eventName === "" && eventTicketsSold === "") return;

    if (eventName && eventName.trim().length === 0) {
      setEditError("Event Name cannot be empty.");
      return;
    }

    if (eventTicketsSold && toInt(eventTicketsSold) < 0) {
      setEditError("Tickets Sold must be a round number.");
      return;
    }

    // construct EventUpdateInputType
    const update = {};

    if (eventName) update.name = eventName.trim();
    if (eventTicketsSold) {
      const sold = toInt(eventTicketsSold);
      if (isNaN(sold) || sold < 0) {
        setEditError("Tickets Sold must be a round number.");
        setEventTicketsSold("");
        return;
      }

      if (sold === ticketsSold) {
        setEditError("Tickets Sold must be different from the previous value.");
        setEventTicketsSold("");
        return;
      }

      if (sold >= 0) update.ticketsSold = Number(eventTicketsSold);
    }

    await editEvent({ variables: { update } });
    // clear state
    clearInputs();
    refetch();
  };

  if (error) {
    console.error(error);
    setEditError(error.message);
  }

  if (!eventData) {
    return <></>;
  }
  const { name, ticketsSold } = eventData;

  return (
    <>
      <a
        href=""
        className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-500"
        onClick={(e) => openModal(e)}
      >
        Edit
      </a>
      <Modal open={open} setOpen={setOpen} title="Edit Event">
        <div className="flex flex-col my-6 space-y-4">
          {editError && (
            <div className="mt-2 text-sm text-center text-red-600">
              <p>{editError}</p>
            </div>
          )}
          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <TextInput
              inputType={"text"}
              inputName={"eventName"}
              inputId={"eventName"}
              placeholder={name}
              label={"Name"}
              value={eventName}
              onChange={handleName}
            />
            <TextInput
              inputType={"number"}
              inputName={"tickets"}
              inputId={"tickets"}
              placeholder={ticketsSold}
              label={"Tickets Sold"}
              value={eventTicketsSold}
              onChange={handleTicketsSold}
              error={eventTicketsSoldError}
              errorMessage="Tickets Sold must be a whole number."
            />
            <div className="flex">
              <Button
                type="submit"
                className="flex flex-row gap-x-2 last:ml-auto"
                onClick={handleSubmit}
                onSubmit={handleSubmit}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default EventEditModal;
