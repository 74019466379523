import "react-international-phone/style.css";

import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";

import { EDIT_USER_PHONE } from "../../schemas/mutators/Profile";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import { usePortalUserStore } from "../../store/PortalUser";
import { useShallow } from "zustand/react/shallow";

type Props = {};
type ProfilePhoneNumberProps = {
  phone?: string;
};
type EditPhoneForm = {
  phone: string;
};
const ProfilePhoneNumber: React.FC<ProfilePhoneNumberProps> = () => {
  const { portalUser, refetchPortalUser } = usePortalUserStore(
    useShallow((state) => ({
      portalUser: state.portalUser,
      refetchPortalUser: state.refetchPortalUser,
    }))
  );
  const client = useApolloClient();
  const [editPhone, { loading }] = useMutation(EDIT_USER_PHONE);
  const [editing, setEditing] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
    setValue,
  } = useForm<EditPhoneForm>();
  const onSubmit: SubmitHandler<EditPhoneForm> = async (data) => {
    if (!!!data.phone) {
      setError("phone", {
        type: "validate",
        message: "Please enter a valid phone number!",
      });
      setFocus("phone");
      return;
    }

    if (!isValidPhoneNumber(data.phone, "US")) {
      setError("phone", {
        type: "validate",
        message: "Please enter a valid phone number!",
      });
      setFocus("phone");
      return;
    }
    const updatePhone = await editPhone({
      variables: {
        phone: data.phone,
      },
    });
    if (updatePhone.errors && updatePhone.errors.length > 0) {
      for (const error of updatePhone.errors) {
        console.error(error);
      }
    }
    // refetch portal user
    refetchPortalUser(client);
    setEditing(false);
  };
  const handleCancel = () => {
    setValue("phone", portalUser?.phoneNumber || "");
    setEditing(false);
  };

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
        Phone Number
      </dt>
      <dd className="flex mt-1 text-sm leading-6 text-gray-800 dark:text-gray-200 sm:col-span-2 sm:mt-0">
        {!editing ? (
          <>
            <span className="flex-grow">
              {portalUser?.phoneNumber || "N/A"}
            </span>
            <span className="flex flex-shrink-0 mx-4 gap-x-8">
              <button
                type="button"
                className="font-medium text-indigo-600 hover:text-indigo-900"
                onClick={() => setEditing(true)}
              >
                Edit
              </button>
            </span>
          </>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-between w-full gap-y-2"
          >
            <PhoneInput
              aria-describedby={"Phone Number"}
              placeholder="Enter Phone Number"
              onChange={(value) => {
                setValue("phone", value);
              }}
            />
            {errors.phone?.message && (
              <span role="alert" className="text-red-300 dark:text-red-500">
                {errors.phone?.message}
              </span>
            )}
            <span className="flex flex-shrink-0 gap-x-8">
              <button
                type="button"
                className="font-medium text-indigo-600 hover:text-indigo-900"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="font-medium text-indigo-600 hover:text-indigo-900"
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
            </span>
          </form>
        )}
      </dd>
    </div>
  );
};

export const ProfileDetails = (props: Props) => {
  const { portalUser } = usePortalUserStore(
    useShallow((state) => ({ portalUser: state.portalUser }))
  );
  return (
    <div className="mb-2 border-b border-gray-200 dark:border-gray-200/20">
      <dl className="divide-y divide-gray-100 dark:divide-gray-200/20">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
            Name
          </dt>
          <dd className="flex mt-1 text-sm leading-6 text-gray-800 capitalize dark:text-gray-200 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{portalUser?.name || "N/A"}</span>
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
            Email Address
          </dt>
          <dd className="flex mt-1 text-sm leading-6 text-gray-800 dark:text-gray-200 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">
              {portalUser?.emailAddress || "N/A"}
            </span>
          </dd>
        </div>
        <ProfilePhoneNumber />
      </dl>
    </div>
  );
};

export default ProfileDetails;
