import {
  AddUserIcon,
  CheckIcon,
  DocumentIcon,
  ImageIcon,
  LinkIcon,
} from "../../components/Icons";

import { FEED_STAGE_COLORS } from "../../constants/Colors";
import { Stage } from "../../components/Progress/Feed";

export type UploadState =
  | "UPLOADING_IMAGES"
  | "UPLOADED_IMAGES"
  | "ADDING_PEOPLE"
  | "ADDED_PEOPLE"
  | "LINKING_FACIAL_RECOGNITION"
  | "LINKED_FACIAL_RECOGNITION"
  | "SAVING_RECORDS"
  | "SAVED_RECORDS"
  | "SUCCESS"
  | "ERROR";

export const UploadStageDictionary = {
  UPLOADING_IMAGES: {
    icon: ImageIcon,
    state: "UPLOADING_IMAGES",
    show: true,
    color: FEED_STAGE_COLORS.blue,
    message: "Uploading images for facial recognition.",
  },
  UPLOADED_IMAGES: {
    icon: CheckIcon,
    state: "UPLOADED_IMAGES",
    show: true,
    color: FEED_STAGE_COLORS.green,
    message: "Successfully uploaded images for facial recognition!",
  },
  ADDING_PEOPLE: {
    icon: AddUserIcon,
    state: "ADDING_PEOPLE",
    show: true,
    color: FEED_STAGE_COLORS.blue,
    message: "Adding people to our database.",
  },
  ADDED_PEOPLE: {
    icon: CheckIcon,
    state: "ADDED_PEOPLE",
    show: true,
    color: FEED_STAGE_COLORS.green,
    message: "Successfully added people to our database!",
  },
  LINKING_FACIAL_RECOGNITION: {
    icon: LinkIcon,
    state: "LINKING_FACIAL_RECOGNITION",
    show: true,
    color: FEED_STAGE_COLORS.blue,
    message: "Linking images to people.",
  },
  LINKED_FACIAL_RECOGNITION: {
    icon: CheckIcon,
    state: "LINKED_FACIAL_RECOGNITION",
    show: true,
    color: FEED_STAGE_COLORS.green,
    message: "Successfully linked images to people!",
  },
  SAVING_RECORDS: {
    icon: DocumentIcon,
    state: "SAVING_RECORDS",
    show: true,
    color: FEED_STAGE_COLORS.blue,
    message: "Updating facial recognition database.",
  },
  SAVED_RECORDS: {
    icon: CheckIcon,
    state: "SAVED_RECORDS",
    show: true,
    color: FEED_STAGE_COLORS.green,
    message: "Successfully updated database!",
  },
};

export const getUploadStageForPerson = (state: UploadState, person: any) => {
  const { name } = person;
  let stage: Stage;
  switch (state) {
    case "UPLOADING_IMAGES":
      stage = {
        ...UploadStageDictionary[state],
        message: `Uploading images of ${name} for facial recognition.`,
      };
      break;
    case "UPLOADED_IMAGES":
      stage = {
        ...UploadStageDictionary[state],
        message: `Successfully uploaded images of ${name} for facial recognition.`,
      };
      break;
    case "ADDING_PEOPLE":
      stage = {
        ...UploadStageDictionary[state],
        message: `Adding ${name} to our database.`,
      };
      break;
    case "ADDED_PEOPLE":
      stage = {
        ...UploadStageDictionary[state],
        message: `Successfully added ${name} to our database!`,
      };
      break;
    case "LINKING_FACIAL_RECOGNITION":
      stage = {
        ...UploadStageDictionary[state],
        message: `Linking images for ${name}.`,
      };
      break;
    case "LINKED_FACIAL_RECOGNITION":
      stage = {
        ...UploadStageDictionary[state],
        message: `Successfully linked images for ${name}!`,
      };
      break;
    case "SAVING_RECORDS":
      stage = {
        ...UploadStageDictionary[state],
      };
      break;
    case "SAVED_RECORDS":
      stage = {
        ...UploadStageDictionary[state],
      };
      break;
  }
  return stage;
};
