import { useEffect, useState } from "react";

import { Switch } from "@headlessui/react";
import { classNames } from "../utils/ux";
import { useDarkMode } from "../hooks/darkMode";

export const DarkModeToggle = () => {
  const { enabled, toggleDarkMode } = useDarkMode();

  return (
    <Switch
      checked={enabled}
      onChange={toggleDarkMode}
      className={classNames(
        enabled ? "bg-indigo-500" : "bg-gray-200",
        "relative ml-auto inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 dark:focus-visible:ring-black/10"
      )}
    >
      <span className="sr-only">Toggle dark mode</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "",
          "absolute pointer-events-none h-6 w-[24px] -translate-y-[2px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
};

export default DarkModeToggle;
