import { Modal, Props } from "../index";
import React, { useState } from "react";
import { Sidebar, SidebarViews } from "./Sidebar";

import { classNames } from "../../../utils/ux";
import { getFullTime } from "../../../utils/timestamps";

interface WalkModalProps extends Props {
	entryImage?: string;
	exitImage?: string;
	walkVideo?: string;
	entryTime?: number;
	cellName?: string;
	timeZoneId?: string;
	customerDeviceName?: string;
}

export const WalkModal: React.FC<WalkModalProps> = ({
	open,
	setOpen,
	children,
	entryImage,
	exitImage,
	walkVideo,
	entryTime,
	cellName,
	customerDeviceName,
	timeZoneId = "America/New_York",
}) => {
	const [view, setView] = useState<SidebarViews>("images");
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			panelClassName={classNames(
				"w-full max-w-5xl transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all",
				"bg-white dark:bg-blue-xonar"
			)}
		>
			<div className="flex flex-col w-full sm:flex-row">
				<></>
				<div className="flex flex-col sm:flex-row">
					<Sidebar view={view} setView={setView} />
					{view === "images" && (
						<div className="flex flex-row w-full mx-auto sm:ml-4 justify-items-center">
							<img
								src={entryImage}
								className="object-scale-down w-1/2 h-3/4"
								alt="Entry Image"
								loading="lazy"
							/>
							<img
								src={exitImage}
								className="object-scale-down w-1/2 h-3/4"
								alt="Exit Image"
								loading="lazy"
							/>
						</div>
					)}
					{view === "video" && (
						<div className="relative flex w-full h-full">
							<video
								src={walkVideo}
								controls={true}
								controlsList="nodownload"
								className="object-cover sm:ml-8"
								autoPlay={true}
								loop={true}
								autoFocus={true}
								poster={entryImage}
								playsInline
							/>
						</div>
					)}
				</div>
				<div className="flex flex-col w-full text-right">
					<h1 className="text-lg font-medium text-gray-800 dark:text-gray-200">
						Detected at{" "}
						{customerDeviceName ? customerDeviceName : cellName}
					</h1>
					<span className="text-sm font-normal text-gray-800 dark:text-gray-200">
						{/* @ts-ignore */}
						{getFullTime(entryTime, undefined, timeZoneId)}
					</span>
					{children}
				</div>
			</div>
		</Modal>
	);
};

export default WalkModal;
