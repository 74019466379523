import { AccessControlledComponent } from "../../../components/AccessControlledComponent";
import { DeviceControlPanelItem } from "./DeviceControlPanelItem";
import { POLICIES } from "../../../constants/Policies";
import React from "react";
import { useLocation } from "react-router-dom";

interface DeviceControlPanelProps {}

export const DeviceControlPanel: React.FC<DeviceControlPanelProps> = ({}) => {
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  // TODO: permissions on who has access to these control items
  return (
    <ul className="flex flex-col gap-y-8">
      <AccessControlledComponent
        policies={[
          POLICIES.EDIT_INFERENCE_LEVEL,
          POLICIES.EDIT_INFERENCE_MODELS,
          POLICIES.EDIT_INFERENCE_THRESHOLDS,
        ]}
        hasOne={true}
      >
        <DeviceControlPanelItem
          name="Inference"
          to={`${path}/inference`}
          icon={"inference"}
        />
      </AccessControlledComponent>
      <AccessControlledComponent policies={[POLICIES.EDIT_CELL_MODE]}>
        <DeviceControlPanelItem
          name="Device Mode"
          to={`${path}/mode`}
          icon={"mode"}
        />
      </AccessControlledComponent>
      {/* TODO: */}
      {/* <DeviceControlPanelItem
        name="Location"
        to={`${path}/location`}
        icon={"location"}
      /> */}
      <AccessControlledComponent policies={[POLICIES.EDIT_SOFTWARE_VERSION]}>
        <DeviceControlPanelItem
          name="Software Version"
          to={`${path}/software`}
          icon={"software"}
        />
      </AccessControlledComponent>
      <AccessControlledComponent policies={[POLICIES.EDIT_CELL]}>
        <DeviceControlPanelItem
          name="Security"
          to={`${path}/security`}
          icon={"security"}
        />
      </AccessControlledComponent>
      <AccessControlledComponent
        policies={[POLICIES.EDIT_CELL, POLICIES.EDIT_SOFTWARE_VERSION]}
      >
        <DeviceControlPanelItem
          name="Configuration"
          to={`${path}/config`}
          icon={"config"}
        />
      </AccessControlledComponent>
      {/* TODO */}
      {/* <DeviceControlPanelItem
        name="MAG" // TODO: better name...
        to={`${path}/metal`}
        icon={"metal"}
      /> */}
    </ul>
  );
};

export default DeviceControlPanel;
