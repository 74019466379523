import { AddCategory, Category } from "./AddCategory";
import { Customer, useCustomerStore } from "../../store/Customer";
import React, { useMemo, useState } from "react";
import {
	ResponseModal,
	ResponseProps,
} from "../../components/Modals/ResponseModal";
import { useMutation, useQuery } from "@apollo/client";

import { Button } from "../../components/Buttons";
import { Color } from "../../constants/Colors";
import { DragAndDrop } from "../../components/Inputs/DragAndDrop";
import { GET_VENUES } from "../../schemas/queries/Venues";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { MultiSelect } from "../../components/Menus/MultiSelect";
import { Select } from "../../components/Menus/Select";
import {
	UPLOAD_FACES,
	ADD_PERSON_IN_BACKGROUND,
} from "../../schemas/mutators/People";
import isAlpha from "validator/es/lib/isAlpha";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import { usePortalUserStore } from "../../store/PortalUser";
import { useRole } from "../../hooks/auth";
import { useShallow } from "zustand/react/shallow";

interface PreviewFaceProps {
	face: File;
	removeFile: (index: number) => void;
	index: number;
}
const PreviewFace: React.FC<PreviewFaceProps> = ({
	face,
	removeFile,
	index,
}) => {
	const fr = new FileReader();
	const [imgSrc, setImgSrc] = useState<any>("");

	fr.onload = (e: any) => {
		const dataUrl = e.target.result;
		setImgSrc(dataUrl);
	};

	fr.readAsDataURL(face);

	return (
		<li className="flex flex-row justify-between py-4">
			<div className="flex flex-col items-center gap-y-3">
				<img
					src={imgSrc}
					alt={face.name}
					className="w-32 h-32 rounded-full"
				/>
				<button
					type="button"
					className="px-4 font-medium text-red-700 rounded-md shadow-sm bg-red-50 ring-red-600/10 dark:text-red-300 dark:bg-red-500/50 ring-1 ring-inset dark:ring-red-500 focus:ring-red-300 ring-offset-0 focus:ring-offset-red-300 dark:focus-visible:ring-offset-red-500 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-red-300 dark:focus-visible:outline-red-500"
					onClick={() => removeFile(index)}
				>
					Remove
				</button>
			</div>
		</li>
	);
};

interface PreviewFacesProps {
	faces: File[];
	setFaces: React.Dispatch<React.SetStateAction<File[]>>;
	removeFile: (index: number) => void;
}

export const PreviewFaces: React.FC<PreviewFacesProps> = ({
	faces,
	removeFile,
	setFaces,
}) => {
	return (
		<div className="flex flex-col gap-y-2">
			<ul className="flex flex-row flex-wrap w-full py-5 mx-auto gap-x-4">
				{faces.map((file, index) => {
					return (
						<PreviewFace
							face={file}
							key={`file-${index}`}
							removeFile={removeFile}
							index={index}
						/>
					);
				})}
			</ul>
		</div>
	);
};

interface UploadFacesProps {
	faces: File[];
	setFaces: React.Dispatch<React.SetStateAction<File[]>>;
	className?: string;
}

export const UploadFaces: React.FC<UploadFacesProps> = ({
	faces,
	setFaces,
	className = "w-full pl-4",
}) => {
	const removeFile = (index: number) => {
		const update = [...faces];
		update.splice(index, 1);
		setFaces(update);
	};
	return (
		<div className={className}>
			{faces.length < 1 ? (
				<DragAndDrop images={faces} setImages={setFaces} />
			) : (
				<PreviewFaces
					faces={faces}
					removeFile={removeFile}
					setFaces={setFaces}
				/>
			)}
		</div>
	);
};

interface AddPersonInput {
	name: string;
	email?: string;
	phone?: string;
	customer: string | undefined;
	venue?: string[];
	alert: boolean;
	category: Category;
	faces: File[];
	description?: string;
}

export const AddPerson: React.FC = () => {
	const [open, setOpen] = useState(false);
	const [response, setResponse] = useState<ResponseProps>({
		success: false,
		successMsg: "",
		errorMsg: "",
	});
	const { portalUser } = usePortalUserStore(
		useShallow((state) => ({ portalUser: state.portalUser }))
	);
	const [customerInput, setCustomerInput] = useState<Customer>({});
	const { isAdmin } = useRole(portalUser);
	const { customers, customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customers: state.customers,
			customerFilter: state.customerFilter,
		}))
	);

	const selectedCustomer = useMemo(() => {
		if (customerFilter.length > 0) return customerFilter;
		if (customerInput.id) return [customerInput.id];
		if (portalUser.customers && portalUser.customers.length > 0)
			return portalUser.customers;
		return [];
	}, [customerInput, customerFilter]);

	const venueQuery = useQuery(GET_VENUES, {
		variables: {
			skip: 0,
			limit: 10000, // hard code limit :)
			customers: selectedCustomer,
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const [uploadFaces, { loading, error }] = useMutation(UPLOAD_FACES, {
		onError: (error) => {
			console.error(error);
		},
	});

	const [addPersonInBackground] = useMutation(ADD_PERSON_IN_BACKGROUND, {
		onError: (error) => {
			console.error(error);
		},
	});

	const [personInput, setPersonInput] = useState<AddPersonInput | undefined>(
		undefined
	);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const [venue, setVenues] = useState<any[]>([]);
	const [alert, setAlert] = useState(false);
	// TODO: make this a list of categories if they want that
	const [category, setCategory] = useState<Category>({
		label: "",
		color: Color.GREEN,
	});
	const [faces, setFaces] = useState<File[]>([]);
	const [description, setDescription] = useState("");

	const handleName = (e: any) => {
		e.preventDefault();
		const value = e.target.value;
		setName(value);
	};

	const handleAlert = () => {
		setAlert(!alert);
	};

	const handleEmail = (e: any) => {
		e.preventDefault();
		const value = e.target.value;
		setEmail(value);
	};

	const handlePhone = (e: any) => {
		e.preventDefault();
		const value = e.target.value;
		setPhone(value);
	};

	const handleDescription = (e: any) => {
		e.preventDefault();
		const value = e.target.value;
		setDescription(value);
	};

	const handleResponse = (response: ResponseProps) => {
		if (!response.success) {
			console.error(response.errorMsg);
		}
		setResponse(response);
		setOpen(true);
	};

	const clearInputs = () => {
		setName("");
		setCategory({
			label: "",
			color: Color.GREEN,
		});
		setCustomerInput({});
		setVenues([]);
		setEmail("");
		setPhone("");
		setDescription("");
		setFaces([]);
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		const words = name
			.trim()
			.split(/\s+/)
			.filter((word) => !isAlpha(word));

		if (words.length > 0) {
			const error = "Person Name must be only letters.";
			handleResponse({ ...response, success: false, errorMsg: error });
			return;
		}
		if (selectedCustomer.length === 0) {
			const error = "Must assign Person to a Customer.";
			handleResponse({ ...response, success: false, errorMsg: error });
			return;
		}
		const venueIds = venue.map((venue) => venue.id);
		if (email && !isEmail(email.trim())) {
			const error = "Email must be valid.";
			handleResponse({ ...response, success: false, errorMsg: error });
			return;
		}
		if (phone && !isMobilePhone(phone.trim())) {
			const error = "Phone number must be valid.";
			handleResponse({ ...response, success: false, errorMsg: error });
			return;
		}
		if (faces.length === 0) {
			const error = "Must add at least one image for Facial Recognition.";
			handleResponse({ ...response, success: false, errorMsg: error });
			return;
		}

		const addPersonInput: AddPersonInput = {
			name,
			email,
			phone,
			customer: selectedCustomer[0],
			venue: venueIds,
			alert,
			category,
			faces,
			description: description.trim(),
		};

		const faceUploadsId = await uploadFaces({
			variables: {
				faces: addPersonInput.faces,
				name: addPersonInput.name,
			},
		});

		const newUploadId = faceUploadsId.data.uploadFaces;

		const addPersonEvent = await addPersonInBackground({
			variables: {
				person: addPersonInput,
				uploadId: newUploadId,
			},
		});
		setResponse({
			success: true,
			successMsg: `${addPersonEvent.data.addPersonInBackground.message}`,
			errorMsg: "",
		});
		setOpen(true);
		setPersonInput(undefined);
		clearInputs();
	};

	return (
		<main>
			<div className="py-8 space-y-16 xl:space-y-20">
				<div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
					<div className="px-4 mt-16 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
									Add Person
								</h1>
								<p className="mt-2 text-sm text-gray-700 dark:text-gray-300/80">
									Add personal details, category, and images
									for facial recognition.
								</p>
							</div>
							<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
								<Link to="/people">
									<Button
										type="button"
										className="block px-1 text-sm font-semibold text-center "
									>
										Back to People
									</Button>
								</Link>
							</div>
						</div>
					</div>
					<div className="px-4 my-8 sm:px-6 lg:px-8">
						<form
							className="flex flex-col divide-y divide-gray-100 dark:divide-gray-200/20 [&>*]:py-8"
							onSubmit={handleSubmit}
							encType="multipart/form-data"
						>
							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="name"
								>
									Full Name{" "}
									<span className="text-red-300 dark:text-red-500">
										*
									</span>
								</label>
								<div className="w-full pl-4">
									<input
										type="text"
										name="name"
										id="name"
										data-testid="personNameInput"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
										placeholder="John Smith"
										aria-describedby={"Person's Full Name"}
										value={name}
										required
										onChange={handleName}
									/>
								</div>
							</div>
							<AddCategory
								category={category}
								setCategory={setCategory}
							/>
							{isAdmin && customerFilter.length === 0 ? (
								<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
									<label
										className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
										htmlFor="customer"
									>
										Customer{" "}
										<span className="text-red-300 dark:text-red-500">
											*
										</span>
									</label>
									<div className="w-full pl-4">
										<Select
											value={customerInput}
											onChange={setCustomerInput}
											itemType={"Customer"}
											items={customers}
										/>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="venue"
								>
									Venue
								</label>

								<div className="flex flex-col w-full pl-4 gap-y-4">
									{!venueQuery.loading ? (
										<>
											<MultiSelect
												value={venue}
												onChange={setVenues}
												itemType="venue"
												items={
													venueQuery.data.venues.items
												}
											/>
											<span className="text-xs text-gray-400 dark:text-gray-500">
												If no Venue is selected, this
												person will be added to all of
												your Venues.
											</span>
										</>
									) : (
										<p className="text-sm font-medium leading-8 text-gray-900 dark:text-gray-200">
											Loading Venues...
										</p>
									)}
								</div>
							</div>

							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="alerts"
								>
									Arrival Alerts
								</label>
								<div className="flex flex-row w-full pl-4 gap-x-3 lg:gap-x-6">
									<input
										type="checkbox"
										name="alerts"
										id="alerts"
										className="w-4 h-4 my-auto border-gray-300 rounded text-blue-xonar-light dark:text-blue-xonar-light/70 focus:ring-blue-xonar-light/50"
										aria-describedby={
											"Alert upon person's arrival"
										}
										checked={alert}
										onChange={handleAlert}
									/>
									<span className="text-sm font-medium leading-8 text-gray-900 dark:text-gray-200">
										{`You will ${
											alert ? "" : "not"
										} receive email and text alerts when this person arrives if you have enabled them.`}
									</span>
								</div>
							</div>
							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="email"
								>
									Email Address
								</label>
								<div className="w-full pl-4">
									<input
										type="email"
										name="email"
										id="email"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
										placeholder="johnsmith@gmail.com"
										aria-describedby={
											"Person's Email Address"
										}
										value={email}
										onChange={handleEmail}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="phone"
								>
									Phone Number
								</label>
								<div className="w-full pl-4">
									<input
										type="tel"
										name="phone"
										id="phone"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
										aria-describedby={
											"Person's Phone Number"
										}
										value={phone}
										onChange={handlePhone}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="description"
								>
									About
								</label>
								<div className="w-full pl-4">
									<textarea
										rows={5}
										name="description"
										id="description"
										className="resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
										aria-describedby={"About Person"}
										value={description}
										onChange={handleDescription}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
								<label
									className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
									htmlFor="faceImages"
								>
									Face Images{" "}
									<span className="text-red-300 dark:text-red-500">
										*
									</span>
								</label>
								<UploadFaces
									faces={faces}
									setFaces={setFaces}
								/>
							</div>
							<div className="flex w-full">
								<button
									type="submit"
									className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
								>
									{loading ? <LoadingSpinner /> : "Submit"}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ResponseModal
				open={open}
				setOpen={setOpen}
				{...response}
				title={
					response.success ? "Added Person" : "Failed to Add Person"
				}
			/>
		</main>
	);
};

export default AddPerson;
