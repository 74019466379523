import { getMetricColor, getMetricPercentage } from "../../utils/ux";
import { previousTimeframes, timeframes } from "../../utils/timestamps";
import { useEffect, useMemo, useState } from "react";

import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { GET_EVENTS_METRICS } from "../../schemas/queries/Events";
import LoadingSpinner from "../../components/LoadingSpinner";
import { POLICIES } from "../../constants/Policies";
import { useCustomerStore } from "../../store/Customer";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useTimeframe } from "../../hooks/timeframe";
import { useTimestampStore } from "../../store/Timestamp";

export default function Stats() {
  const delay = 10000; // 10 second polling delay
  const [present, setPresent] = useState({ start: 0, end: 0 });
  const [previous, setPrevious] = useState({ start: 0, end: 0 });
  const [refresh, setRefresh] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const { timeframe } = useTimeframe();
  const { updateTimestamp } = useTimestampStore(
    useShallow((state) => ({
      updateTimestamp: state.updateTimestamp,
    }))
  );
  const { customerFilter } = useCustomerStore(
    useShallow((state) => ({
      customerFilter: state.customerFilter,
    }))
  );

  const presentTime = useMemo(() => {
    return timeframes[timeframe]();
  }, [timeframe]);

  const previousTime = useMemo(() => {
    return previousTimeframes[timeframe]();
  }, [timeframe]);

  useEffect(() => {
    // on render or timeFrame update or refresh request from graphql
    // update present and previous timestamp ranges
    // const presentTime = timeframes[timeframe]();
    // const previousTime = previousTimeframes[timeframe]();

    setPresent({
      start: presentTime[0],
      end: presentTime[1],
    });
    setPrevious({
      start: previousTime[0],
      end: previousTime[1],
    });
    // after delay ms, graphql will make a query and will need updated timestamp ranges
    setRefresh(false);
  }, [presentTime, previousTime, refresh]);

  const { loading, error, data } = useQuery(GET_EVENTS_METRICS, {
    variables: {
      present,
      previous,
      customers: customerFilter,
    },
    onCompleted: (data) => {
      // always sets initial loading to be true after the first load for UX
      setInitialLoading(false);
      setMetrics(data.metrics);
      // after completed queries
      // graphql need updated timestamp ranges to query the api for live metrics
      setTimeout(() => {
        setRefresh(true);
      }, delay);
      updateTimestamp();
    },
    onError: (error) => {
      console.error(error.message);
    },
    pollInterval: 10000,
    // polling and triggering onCompleted requires below boolean
    notifyOnNetworkStatusChange: true,
  });
  return (
    <>
      <div className="hidden border-b md:block border-b-gray-900/10 dark:border-b-gray-100/20 lg:border-t lg:border-t-gray-900/5 dark:lg:border-t-gray-100/20">
        <dl className="grid grid-cols-1 mx-auto max-w-7xl sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
          <div className="flex flex-wrap items-baseline justify-between px-4 py-10 border-t gap-y-2 gap-x-4 border-gray-900/5 dark:border-gray-100/20 sm:px-6 lg:border-t-0 xl:px-8">
            <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-300">
              Total Arrivals
            </dt>
            <dd
              className={`hidden text-xs font-medium ${
                initialLoading
                  ? "text-gray-700 dark:text-gray-200"
                  : getMetricColor(metrics.arrivals.difference)
              }`}
            >
              {initialLoading ? (
                <LoadingSpinner w={3} h={3} borderSize={2} />
              ) : (
                getMetricPercentage(metrics.arrivals.difference)
              )}
            </dd>
            <dd className="flex-none w-full text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-gray-200">
              {initialLoading ? (
                <LoadingSpinner />
              ) : (
                metrics.arrivals.value?.toLocaleString()
              )}
            </dd>
          </div>
          <div className="flex flex-wrap items-baseline justify-between px-4 py-10 border-t gap-y-2 gap-x-4 border-gray-900/5 dark:border-gray-100/20 sm:px-6 lg:border-t-0 xl:px-8 sm:border-l">
            <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-300">
              Max Hourly Arrivals
            </dt>
            <dd
              className={`hidden text-xs font-medium ${
                initialLoading
                  ? "text-gray-700 dark:text-gray-200"
                  : getMetricColor(metrics.maxHourlyArrivals.difference)
              }`}
            >
              {initialLoading ? (
                <LoadingSpinner w={3} h={3} borderSize={2} />
              ) : (
                getMetricPercentage(metrics.maxHourlyArrivals.difference)
              )}
            </dd>
            <dd className="flex-none w-full text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-gray-200">
              {initialLoading ? (
                <LoadingSpinner />
              ) : (
                metrics.maxHourlyArrivals.value?.toLocaleString()
              )}
            </dd>
          </div>
          <div className="flex flex-wrap items-baseline justify-between px-4 py-10 border-t gap-y-2 gap-x-4 border-gray-900/5 dark:border-gray-100/20 sm:px-6 lg:border-t-0 xl:px-8 sm:border-l">
            <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-300">
              Threats Detected
            </dt>
            <dd
              className={`hidden text-xs font-medium ${
                initialLoading
                  ? "text-gray-700 dark:text-gray-200"
                  : getMetricColor(metrics.threatsDetected.difference)
              }`}
            >
              {initialLoading ? (
                <LoadingSpinner w={3} h={3} borderSize={2} />
              ) : (
                getMetricPercentage(metrics.threatsDetected.difference)
              )}
            </dd>
            <dd className="flex-none w-full text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-gray-200">
              {initialLoading ? (
                <LoadingSpinner />
              ) : (
                metrics.threatsDetected.value?.toLocaleString()
              )}
            </dd>
          </div>
          <AccessControlledComponent policies={[POLICIES.READ_PEOPLE]}>
            <div className="flex flex-wrap items-baseline justify-between px-4 py-10 border-t gap-y-2 gap-x-4 border-gray-900/5 dark:border-gray-100/20 sm:px-6 lg:border-t-0 xl:px-8 sm:border-l">
              <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-300">
                People Recognized
              </dt>
              <dd
                className={`hidden text-xs font-medium ${
                  initialLoading
                    ? "text-gray-700 dark:text-gray-200"
                    : getMetricColor(metrics.peopleRecognized.difference)
                }`}
              >
                {initialLoading ? (
                  <LoadingSpinner w={3} h={3} borderSize={2} />
                ) : (
                  getMetricPercentage(metrics.peopleRecognized.difference)
                )}
              </dd>
              <dd className="flex-none w-full text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-gray-200">
                {initialLoading ? (
                  <LoadingSpinner />
                ) : (
                  metrics.peopleRecognized.value?.toLocaleString()
                )}
              </dd>
            </div>
          </AccessControlledComponent>
        </dl>
      </div>

      <div
        className="absolute left-0 origin-top-left -rotate-90 translate-y-40 top-full -z-10 mt-96 transform-gpu opacity-20 blur-3xl sm:block sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
        aria-hidden="true"
      >
        <div
          className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC] dark:from-blue-xonar-light dark:to-sky-xonar"
          style={{
            clipPath:
              "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
          }}
        ></div>
      </div>
    </>
  );
}
