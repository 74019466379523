import { Disclosure } from "@headlessui/react";
import React, { useEffect } from "react";
import { useNavigation } from "../../hooks/useNavigation";
import { toggleBodyScroll } from "../../utils/ux";
import { NotificationBell } from "../Notifications/NotificationBell";
import {
	Logo,
	MobileMenu,
	MobileMenuButton,
	NavLinks,
} from "./NavbarComponents";
import { ProfileMenu } from "./ProfileMenu";
import { AccessControlledComponent } from "../AccessControlledComponent";
import { POLICIES } from "../../constants/Policies";

export const Navbar = () => {
	const { filteredNavigation, isActive } = useNavigation();

	return (
		<header>
			<Disclosure
				as="nav"
				className="fixed inset-x-0 z-50 flex h-16 bg-white border-b border-gray-900/10 dark:bg-blue-xonar"
				role="navigation"
				aria-modal="true"
			>
				{({ open, close }) => {
					useEffect(() => {
						toggleBodyScroll(open);
						return () => toggleBodyScroll(false);
					}, [open]);

					return (
						<>
							<div className="w-full px-2 lg:mx-auto max-w-7xl sm:px-6 lg:px-8">
								<div className="relative flex items-center justify-between h-16">
									<div className="absolute flex items-center left-2 md:hidden">
										<MobileMenuButton open={open} />
									</div>

									<div className="flex items-center justify-center w-full md:w-auto md:justify-start">
										<Logo />
									</div>

									<div className="hidden md:flex md:items-center md:ml-6 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
										<NavLinks
											filteredNavigation={
												filteredNavigation
											}
											isActive={isActive}
										/>
									</div>

									<div className="absolute flex items-center right-2 md:static md:inset-auto md:ml-6 md:pr-0">
										<NotificationBell />
										<ProfileMenu />
									</div>
								</div>
							</div>

							<MobileMenu
								filteredNavigation={filteredNavigation}
								isActive={isActive}
								onClose={close}
							/>
						</>
					);
				}}
			</Disclosure>
		</header>
	);
};

export default Navbar;
