import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { usePortalUserStore } from "../../../store/PortalUser";
import { useReportStore } from "../../../store/Report";
import { useShallow } from "zustand/react/shallow";
import { Modal } from "../../../components/Modals";
import { useParams } from "react-router-dom";
import { DynamicLoadingSpinner } from "../../../components/LoadingSpinner";
import { Button } from "../../../components/Buttons";
import { DetailsHeader } from "../../../components/Headers/DetailsHeader";
import { ReportDetailsOverview } from "./ReportDetailsOverview";
import { ReportLocation } from "./ReportLocation";
import { ReportArrivals } from "./ReportArrivals";
import { ReportRecognizedPeople } from "./ReportRecognizedPeople";
import { ReportRegisteredThreats } from "./ReportRegisteredThreats";
import PageLayout from "../../../layouts/PageLayout";
import ReportLayout from "../../../layouts/ReportLayout";
import { TextInput } from "../../../components/Inputs/TextInput";

export const Report = () => {
	const { reportId } = useParams();
	// const [passcode, setPasscode] = useState("35928186");
	const [passcode, setPasscode] = useState("");
	const [open, setOpen] = useState(false);
	const [passcodeSubmitted, setPasscodeSubmitted] = useState(false);

	const {
		report,
		getReport,
		authorized,
		firstLoad,
		viewReport,
		validPasscode,
	} = useReportStore(useShallow((state) => state));

	const portalUser = usePortalUserStore(
		useShallow((state) => state.portalUser)
	);

	const handleOpenModal = () => {
		if (!authorized) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	};

	const handleSubmit = () => {
		setTimeout(() => {
			setPasscodeSubmitted(true);
		}, 500);
		if (reportId) {
			getReport({
				id: reportId,
				portalUser: portalUser?.id,
				passcode: passcode,
			});
		}
	};

	useEffect(() => {
		if (!firstLoad && !authorized) {
			setOpen(true);
		}
		if (authorized || viewReport) {
			setOpen(false);
		}
	}, [firstLoad, authorized, viewReport]);

	useEffect(() => {
		if (reportId) {
			getReport({
				id: reportId,
				portalUser: portalUser?.id,
				passcode: passcode,
			});
		}
	}, [reportId, getReport, portalUser]);

	if (firstLoad) {
		return (
			<PageLayout>
				<DynamicLoadingSpinner className="w-12 h-12" />
			</PageLayout>
		);
	}
	if (!firstLoad && !authorized) {
		return (
			<PageLayout>
				<Modal
					open={open}
					setOpen={handleOpenModal}
					title="Enter Passcode to View Report"
				>
					<div className="flex flex-col my-4 gap-y-2">
						<TextInput
							label="Passcode"
							value={passcode}
							onChange={(e) => setPasscode(e.target.value)}
							inputType="password"
							inputName="passcode"
							inputId="passcode"
							placeholder="Passcode"
							error={
								(validPasscode && passcodeSubmitted) ||
								(!validPasscode && !passcodeSubmitted)
									? false
									: true
							}
							errorMessage="Invalid Passcode."
						/>

						<button
							className="px-2 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-xonar-light dark:text-gray-300 dark:hover:text-gray-200 hover:bg-sky-xonar dark:bg-blue-xonar-light/50 dark:hover:bg-blue-xonar-light/40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-xonar-secondary"
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</Modal>
			</PageLayout>
		);
	}

	if (authorized && viewReport) {
		return (
			<ReportLayout
				venuePhoto={report?.metadata?.entitiesInfo?.[0]?.url}
				venueName={report?.metadata?.venueName}
				eventName={report?.metadata?.eventName}
				eventTime={report?.metadata?.eventTime}
				createdTime={report?.createdTime}
				timeZoneId={report?.metadata?.timeZoneId}
				// @ts-ignore
				passcode={report?.passcode}
				shortCode={report?.shortCode}
			>
				<div className="grid items-start max-w-2xl grid-cols-1 grid-rows-1 mx-auto gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					<div className="lg:col-start-1 lg:row-end-1">
						<ReportDetailsOverview />
						<ReportLocation />
					</div>
					<div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
						<ReportArrivals />
						<ReportRecognizedPeople />
						<ReportRegisteredThreats />
					</div>
				</div>
			</ReportLayout>
		);
	}
};

export default Report;
