import { NetworkStatus, useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";

import { GET_RECOGNIZED_WALKS } from "../../schemas/queries/Walks";
import { Link } from "react-router-dom";
import { Pagination } from "../../components/Pagination/Pagination";
import { TAG_COLORS } from "../../constants/Colors";
import { Tag } from "../../components/Tags";
import { WalkModal } from "../../components/Modals/WalkModal";
import { getTime } from "../../utils/timestamps";
import { useParams } from "react-router-dom";

interface WalkData {
	entryImage: string;
	exitImage: string;
	walkVideo: string;
	cellName: string;
	entryTime: number;
	categoryName: string;
	categoryColor: string;
	personOfInterest: string;
	personName: string;
	customerDeviceName?: string;
}

interface RecognizedWalkType extends WalkData {
	cell: string;
	id: string;
}

interface RecognizedPeopleTableRowProps extends RecognizedWalkType {
	timeZoneId: string;
	onClick: () => void;
}

const RecognizedPeopleTableRow: React.FC<RecognizedPeopleTableRowProps> = ({
	categoryName,
	categoryColor,
	personOfInterest,
	entryTime,
	personName,
	timeZoneId,
	cell,
	cellName,
	customerDeviceName,
	onClick,
}) => {
	const time = useMemo(() => {
		return {
			datetime: new Date(entryTime).toISOString(),
			displayed: getTime(entryTime, timeZoneId),
		};
	}, [entryTime, timeZoneId]);

	return (
		<>
			<tr className="border-b border-gray-100 dark:border-gray-100/20">
				<td className="w-full px-0 py-5 align-top">
					<div className="pl-6 font-medium text-gray-900 truncate dark:text-gray-200">
						<Link to={`/people/${personOfInterest}`}>
							{personName}
						</Link>
					</div>
				</td>
				<td className="hidden py-5 pl-8 pr-0 text-right text-gray-700 align-top tabular-nums dark:text-gray-200 sm:table-cell">
					<time dateTime={time.datetime}>{time.displayed}</time>
				</td>
				<td className="hidden py-5 pl-8 pr-0 text-right text-gray-700 align-top tabular-nums dark:text-gray-200 sm:table-cell">
					<Link to={`/devices/${cell}`}>
						{customerDeviceName ? customerDeviceName : cellName}
					</Link>
				</td>
				<td className="py-5 pl-8 pr-0 text-right align-top tabular-nums">
					<Tag
						tag={categoryName}
						color={categoryColor}
						className={TAG_COLORS[categoryColor]}
					/>
				</td>
				<td className="py-5 pl-8 pr-6 text-right text-gray-700 align-top dark:text-gray-200/50 tabular-nums">
					<svg
						className="w-5 h-5 hover:cursor-pointer"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
						onClick={onClick}
					>
						<path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
					</svg>
				</td>
			</tr>
		</>
	);
};

interface RecognizedPeopleTableProps {
	order: number;
	timeZoneId: string;
	searchTerm?: string;
}
const RecognizedPeopleTable: React.FC<RecognizedPeopleTableProps> = ({
	order,
	timeZoneId,
	searchTerm,
}) => {
	const { eventId } = useParams();
	const [people, setPeople] = useState<RecognizedWalkType[]>([]);
	const [count, setCount] = useState(0);
	// Pagination
	const [skip, setSkip] = useState(0);
	const [limit, setLimit] = useState(5);
	// Walk Modal
	const [open, setOpen] = useState(false);

	const [walkData, setWalkData] = useState<WalkData>();

	const recognizedWalkQuery = useQuery(GET_RECOGNIZED_WALKS, {
		variables: {
			eventId,
			skip: skip * limit,
			limit,
			order,
			searchTerm: searchTerm?.trim(),
		},
		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const recognizedWalks: RecognizedWalkType[] =
				data.eventRecognizedWalks.items.map((walkData) => {
					const recognizedWalk: RecognizedWalkType = {
						categoryColor: walkData.categoryColor,
						categoryName: walkData.categoryName,
						personOfInterest: walkData.personOfInterest,
						entryTime: walkData.entryTime,
						personName: walkData.personName,
						cell: walkData.cell,
						cellName: walkData.cellName,
						id: walkData.id,
						entryImage: walkData.entryImage,
						exitImage: walkData.exitImage,
						walkVideo: walkData.walkVideo,
						customerDeviceName: walkData.customerDeviceName,
					};
					return recognizedWalk;
				});
			setPeople(recognizedWalks);
			setCount(data.eventRecognizedWalks.count);
		},
		onError: (error) => {
			// DEV ONLY CONSOLE ERROR
			console.error(error.message);
		},
	});

	if (count < 1) {
		return (
			<>
				<div className="w-full py-8 mt-0 leading-6 text-center bg-white text-md whitespace-nowrap dark:bg-gray-900 dark:text-gray-300">
					No people recognized.
				</div>
			</>
		);
	}

	return (
		<>
			<table className="w-full text-sm leading-6 text-left whitespace-nowrap dark:bg-gray-900">
				<thead className="text-gray-900 border-b border-gray-200 dark:text-gray-200 dark:border-gray-200/20">
					<tr>
						<th
							scope="col"
							className="px-0 py-3 pl-6 font-semibold"
						>
							Person
						</th>
						<th
							scope="col"
							className="hidden py-3 pl-8 pr-0 font-semibold text-right sm:table-cell"
						>
							Arrival Time
						</th>
						<th
							scope="col"
							className="hidden py-3 pl-8 pr-0 font-semibold text-right sm:table-cell"
						>
							Location
						</th>
						<th
							scope="col"
							className="py-3 pl-8 pr-0 font-semibold text-right"
						>
							Category
						</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					{people.map((person, i) => {
						return (
							<RecognizedPeopleTableRow
								key={`recognized-person-${i}`}
								timeZoneId={timeZoneId}
								{...person}
								onClick={() => {
									setWalkData({
										entryImage: person.entryImage,
										exitImage: person.exitImage,
										walkVideo: person.walkVideo,
										cellName: person.cellName,
										entryTime: person.entryTime,
										categoryName: person.categoryName,
										categoryColor: person.categoryColor,
										personOfInterest:
											person.personOfInterest,
										personName: person.personName,
										customerDeviceName:
											person.customerDeviceName,
									});
									setOpen(true);
								}}
							/>
						);
					})}
				</tbody>
			</table>
			<Pagination
				skip={skip}
				onSkip={setSkip}
				count={count}
				limit={limit}
				currentAmount={people.length}
			/>
			<WalkModal
				open={open}
				setOpen={setOpen}
				timeZoneId={timeZoneId}
				{...walkData}
			>
				{walkData !== undefined && (
					<div className="flex flex-col w-full my-4 gap-y-2">
						<div className="flex flex-col gap-y-1">
							<span>
								<Link
									className="text-base font-medium text-gray-800 dark:text-gray-200"
									to={`/people/${walkData.personOfInterest}`}
								>
									{walkData.personName}
								</Link>
							</span>
						</div>
						<div className="flex flex-col gap-y-1">
							<span>
								<Tag
									tag={walkData.categoryName}
									color={walkData.categoryColor}
									className={
										TAG_COLORS[walkData.categoryColor]
									}
								/>
							</span>
						</div>
					</div>
				)}
			</WalkModal>
		</>
	);
};

// TODO: really small skinny phones can't really show the table
export const RecognizedPeople = ({ timeZoneId }) => {
	// ASCENDING === 1
	// DESCENDING === -1
	const [order, setOrder] = useState(1);
	// search for person
	const [personQuery, setPersonQuery] = useState("");

	const handleSort = (e) => {
		e.preventDefault();
		setOrder(order * -1);
	};

	if (!timeZoneId) {
		return <></>;
	}

	return (
		<div className="mt-8 border border-gray-200 rounded-xl dark:border-gray-200/20">
			<div className="flex flex-row justify-between p-8 pt-6">
				<h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
					Recognized People
				</h2>
				<div className="flex mt-2 rounded-md shadow-sm max-h-10">
					<div className="relative flex focus-within:z-10">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<svg
								className="w-5 h-5 text-gray-400"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
							</svg>
						</div>
						{/* TODO: person filter handler */}
						<input
							type="text"
							name="person"
							id="person"
							className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 bg-white text-gray-900 dark:bg-blue-xonar dark:text-gray-200 dark:border-white/10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							placeholder="Person"
							value={personQuery}
							onChange={(e) => setPersonQuery(e.target.value)}
						/>
						<button
							type="button"
							onClick={handleSort}
							className="relative h-full rounded-l-none border-0 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-5 text-sm font-semibold text-gray-900 dark:text-gray-200/50 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-blue-xonar/90"
						>
							{/* ASCENDING */}
							{order === 1 && (
								<svg
									className="-ml-0.5 h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
										clipRule="evenodd"
									/>
								</svg>
							)}
							{/* DESCENDING */}
							{order === -1 && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-5 h-5"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
									/>
								</svg>
							)}
							Sort
						</button>
					</div>
				</div>
			</div>
			<RecognizedPeopleTable
				order={order}
				timeZoneId={timeZoneId}
				searchTerm={personQuery}
			/>
		</div>
	);
};

export default RecognizedPeople;
