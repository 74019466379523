import { ApolloClient, useApolloClient } from "@apollo/client";
import { createJSONStorage, persist } from "zustand/middleware";
import { PortalRole } from "../hooks/auth";

import { GET_PORTAL_USER } from "../schemas/queries/Auth";
import { create } from "zustand";

type PortalUserNotifications = {
	email?: boolean;
	sms?: boolean;
	alert?: boolean;
};

export interface PortalUser {
	id?: string;
	name?: string;
	emailAddress?: string;
	role?: PortalRole;
	roles?: PortalRole[];
	phoneNumber?: string;
	notifications?: PortalUserNotifications;
	customers?: string[];
	permissions?: string[];
}

interface PortalUserState {
	portalUser?: PortalUser;
	fetchPortalUser: () => void;
	refetchPortalUser: (client: ApolloClient<any>) => void;
}

export const usePortalUserStore = create<PortalUserState>()(
	persist(
		(set, get) => ({
			portalUser: {},
			fetchPortalUser: async () => {
				const client = useApolloClient();
				try {
					const { data, error } = await client.query({
						query: GET_PORTAL_USER,
					});
					if (error) {
						console.error(error);
					}
					set((state) => ({ portalUser: data.portalUser }));
				} catch (error) {
					console.log(
						`${error.message} ... Fetching auth token from browser...`
					);
				}
			},
			refetchPortalUser: async (client: ApolloClient<any>) => {
				try {
					const { data, error } = await client.query({
						query: GET_PORTAL_USER,
					});
					if (error) {
						console.error(error);
					}
					set((state) => ({ portalUser: data.portalUser }));
				} catch (error) {
					console.log(
						`${error.message} ... Fetching auth token from browser...`
					);
				}
			},
		}),
		{
			name: "portalUserStore",
			storage: createJSONStorage(() => sessionStorage),
			// exclude list of permissions from being in sessionStorage
			// partialize: (state) => {
			//   const portalUser = {
			//     ...state.portalUser,
			//   };
			//   delete portalUser.permissions;
			//   return {
			//     portalUser,
			//   };
			// },
		}
	)
);
