import { gql } from "@apollo/client";

export const GET_DEVICE = gql`
	query GetDevice($id: String!) {
		device(id: $id) {
			id
			name
			customer
			customerName
			venue
			venueName
			location
			status
			mostRecentEvent
			mostRecentEventName
			mostRecentEventTime
			sharedIdentifier
			detailsPageShortUrl
			customerDeviceName
		}
		deviceConfig(id: $id) {
			inferenceLevel
			facialRecognitionEnabled
			hardwareVersion
		}
	}
`;

export const GET_DEVICES = gql`
	query GetDevices(
		$statuses: [DeviceStatus!] = []
		$customers: [String!] = []
		$venues: [String!] = []
		$locations: [String!] = []
		$includeDisassembled: Boolean = false
	) {
		devices(
			statuses: $statuses
			customers: $customers
			venues: $venues
			locations: $locations
			includeDisassembled: $includeDisassembled
		) {
			count
			items {
				id
				name
				customer
				customerName
				venue
				venueName
				location
				status
				mostRecentEventTime
				sharedIdentifier
				customerDeviceName
			}
		}
	}
`;

export const GET_DEVICE_METRIC = gql`
	query GetDeviceMetric($id: String!) {
		deviceMetric(cellId: $id) {
			cell
			cellName
			arrivals
			alerts
			threats
			recognized
		}
	}
`;

export const GET_DEVICE_EVENT_METRIC = gql`
	query GetDeviceEventMetric($cellId: String!, $eventId: String!) {
		deviceEventMetric(cellId: $cellId, eventId: $eventId) {
			cell
			cellName
			location
			customerEvent
			customerEventName
			arrivals
			alerts
			threats
			recognized
		}
	}
`;

export const GET_DEVICE_EVENT_METRICS = gql`
	query GetDeviceEventMetric($cellId: String!, $customers: [String!] = []) {
		deviceEvents(id: $cellId, customers: $customers) {
			customerEvent
			customerEventName
			eventTime
		}
	}
`;
