import { gql } from "@apollo/client";

export const GENERATE_REPORT_FROM_EVENT = gql`
	mutation GenerateReportFromEvent($event: String!) {
		generateReport(id: $event) {
			id
			shortCode
			passcode
		}
	}
`;
