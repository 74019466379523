import { gql } from "@apollo/client";

export type WalkFile = {
	fileName: string;
	key: string;
	bucket: string;
	location: string;
	fileType?: string;
};

export type ThreatObject = {
	id: string;
	name: string;
	category: string;
};

export type ThreatAnnotation = {
	subjectArea: string;
	object: ThreatObject;
	createdTime?: number;
};

export type ThreatWalk = {
	id: string;
	entryTime: number;
	exitTime: number;
	venue?: string;
	customer?: string;
	cell?: string;
	cellName?: string;
	customerEvent?: string;
	walkFiles?: WalkFile[];
	asWalkedObjectDetected?: boolean;
	asWalkedRadarObjectDetected?: boolean;
	asWalkedMagnetometerObjectDetected?: boolean;
	validationStatus?: string;
	walkCount?: number;
	objectOfInterestPresent?: boolean;
	annotatedSubjectAreaContents?: ThreatAnnotation[];
	customerDeviceName?: string;
};

export const GET_WALK_INTERVALS = gql`
	query GetWalkIntervals($eventId: String!, $interval: Int = 60) {
		eventWalkIntervals(eventId: $eventId, interval: $interval) {
			xAxis
			yAxis {
				name
				data {
					x
					y
				}
			}
		}
	}
`;

export const GET_THREAT_WALKS = gql`
	query GetThreatWalks(
		$eventId: String!
		$skip: Int = 0
		$limit: Int = 0
		$order: Int = 1
		$searchTerm: String = ""
	) {
		eventThreatWalks(
			eventId: $eventId
			skip: $skip
			limit: $limit
			order: $order
			searchTerm: $searchTerm
		) {
			count
			items {
				id
				entryTime
				exitTime
				venue
				customer
				cell
				cellName
				customerDeviceName
				entryImage
				exitImage
				walkVideo
				validationStatus
				objectOfInterestPresent
				annotatedSubjectAreaContents {
					subjectArea
					object {
						id
						name
						category
					}
				}
			}
		}
	}
`;

export const GET_RECOGNIZED_WALKS = gql`
	query GetRecognizedWalks(
		$eventId: String!
		$skip: Int = 0
		$limit: Int = 0
		$order: Int = 1
		$searchTerm: String = ""
	) {
		eventRecognizedWalks(
			eventId: $eventId
			skip: $skip
			limit: $limit
			order: $order
			searchTerm: $searchTerm
		) {
			count
			items {
				id
				entryTime
				exitTime
				venue
				customer
				cell
				cellName
				validationStatus
				categoryName
				categoryColor
				userId
				personOfInterest
				personName
				entryImage
				exitImage
				walkVideo
				customerDeviceName
			}
		}
	}
`;
