import { gql } from "@apollo/client";

export const EDIT_EVENT = gql`
  mutation EditEvent($id: String!, $update: EventUpdateInputType!) {
    editEvent(id: $id, update: $update) {
      name
      customer
      cells
      venue
      venueName
      eventTime
      timeZoneId
      totalWalkCount
      registeredThreats
      alerts
      maxHourlyArrivals
      maxHourlyArrivalsStartTime
      maxHourlyArrivalsFinishTime
      ticketsSold
    }
  }
`;
