import {
  DetailsOverview,
  DetailsOverviewTerm,
} from "../../components/Overviews/DetailsOverview";
import { useNavigate, useParams } from "react-router-dom";

import { DetailsHeader } from "../../components/Headers/DetailsHeader";
import { GET_ENTITY_PHOTOS } from "../../schemas/queries/EntityPhotos";
import { GET_VENUE } from "../../schemas/queries/Venues";
import { Link } from "react-router-dom";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import { useQuery } from "@apollo/client";
import { useState } from "react";

export const VenueDetails = () => {
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const { venueId } = useParams();
  const [venueData, setVenueData] = useState({});
  const [photo, setPhoto] = useState("");
  const { loading } = useQuery(GET_VENUE, {
    variables: {
      id: venueId,
    },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setVenueData(data.venue);
      setInitialLoading(false);
    },
    onError: (error) => {
      console.error(error.message);
      navigate("/error", {
        state: { status: 404, error: "Venue could not be found." },
      });
    },
  });
  const entityQuery = useQuery(GET_ENTITY_PHOTOS, {
    variables: {
      entityId: venueId,
      includeThumbnail: false,
    },
    onCompleted: (data) => {
      setPhoto(data.entityPhotos[0].url);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });
  if (initialLoading) {
    return <ScreenLoader />;
  }

  return (
    <main>
      <DetailsHeader>
        <div className="flex items-center gap-x-6">
          <img
            src={photo}
            alt={venueData.name}
            className="flex-none w-16 h-16 rounded-full ring-1 ring-gray-900/10 dark:text-gray-300"
          />
          <h1 className="text-left">
            <div className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
              {venueData.name}
            </div>
            <div className="text-sm leading-6 text-gray-800 dark:text-gray-300">
              {venueData.address}
            </div>
          </h1>
        </div>
        <div className="flex-col items-end justify-between hidden ml-auto text-right sm:flex">
          <div className="text-sm font-semibold leading-6 text-gray-800 dark:text-gray-300">
            Most Recent Event
          </div>
          <Link
            to={`/events/${venueData.mostRecentEvent}`}
            className="text-sm font-normal hover:underline dark:text-gray-300"
          >
            {venueData.mostRecentEventName}
          </Link>
        </div>
      </DetailsHeader>
      <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-start max-w-2xl grid-cols-1 grid-rows-1 mx-auto gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div className="lg:col-start-1 lg:row-end-1">
            <DetailsOverview>
              <DetailsOverviewTerm
                term={"Phone Number"}
                description={venueData.phoneNumber}
              />
              <DetailsOverviewTerm
                term={"Email Address"}
                description={venueData.emailAddress}
              />
              <DetailsOverviewTerm
                term={"Description"}
                description={venueData.description}
              />
              <DetailsOverviewTerm
                term={"Capacity"}
                description={venueData.capacity}
              />
              <DetailsOverviewTerm
                term={"Time Zone"}
                description={venueData.timeZoneId}
              />
              <DetailsOverviewTerm
                term={"Short Code"}
                description={venueData.shortCode}
              />
            </DetailsOverview>
          </div>
          <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
            {/* TODO something important here? */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default VenueDetails;
