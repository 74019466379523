import { useEffect, useMemo, useState } from "react";

import { GET_EVENTS } from "../../schemas/queries/Events";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import RecentEvent from "./RecentEvent";
import { timeframes } from "../../utils/timestamps";
import { useCustomerStore } from "../../store/Customer";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useTimeframe } from "../../hooks/timeframe";

export default function RecentEvents() {
  const navigate = useNavigate();
  const [eventLimit, setEventLimit] = useState(6);
  const [eventSkip, setEventSkip] = useState(0);
  const [timeRange, setTimeRange] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  // for time ranges that have >= 6 pages
  // we have two windows
  // left window is [eventSkip - 2, eventSkip - 1, eventSkip]
  // right window is [num pages - 2, num pages - 1, num pages]
  // windowLeft defines the index for eventSkip - 2
  // windowRight defines the index for eventSkip
  const [windowLeft, setWindowLeft] = useState(0);
  const [windowRight, setWindowRight] = useState(3);
  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(0);
  const { timeframe } = useTimeframe();
  const { customerFilter } = useCustomerStore(
    useShallow((state) => ({
      customerFilter: state.customerFilter,
    }))
  );

  useEffect(() => {
    // on time range update or customer filter change
    setTimeRange(timeframes[timeframe]());
    // also set pagination properties back to defaults
    setEventSkip(0);
    setWindowLeft(0);
    setWindowRight(3);
    setInitialLoading(true);
  }, [timeframe]);

  const { loading } = useQuery(GET_EVENTS, {
    variables: {
      skip: eventSkip * eventLimit, // (page num - 1) * page limit == how many documents to skip
      limit: eventLimit,
      timeStart: timeRange[0],
      timeEnd: timeRange[1],
      customers: customerFilter,
    },
    pollInterval: 10000, // poll every 10 seconds
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setEvents(data.events.items);
      setCount(data.events.count);
      setInitialLoading(false);
    },
    onError: (error) => {
      console.error(error.message);
      navigate("/error", {
        state: {
          status: 404,
          error: "Something went wrong querying for Events.",
        },
      });
    },
  });

  const pages = useMemo(() => {
    return Array.from(
      { length: Math.ceil(count / eventLimit) },
      (x, i) => i + 1
    );
  }, [count, eventLimit]);

  const handlePageClick = (event, page) => {
    event.preventDefault();
    if (pages.length >= 6) {
      if (page >= pages.length - 3) {
        setWindowLeft(pages.length - 6);
        setWindowRight(pages.length - 3);
      } else {
        const windowRightIndex = page === 1 ? 3 : page + 1;
        setWindowLeft(windowRightIndex - 3);
        setWindowRight(windowRightIndex);
      }
    }
    setEventSkip(Math.min(pages.length, page - 1));
  };

  const firstWindow = useMemo(() => {
    return [...pages.slice(windowLeft, windowRight)];
  }, [pages, windowLeft, windowRight]);
  const lastWindow = [...pages.slice(-3)];
  const pageClassActive =
    "border-indigo-500 text-indigo-600 dark:text-indigo-400";
  const pageClass =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-indigo-300/50 dark:hover:text-indigo-300";

  return (
    <div className="py-16 space-y-16 xl:space-y-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
          <div className="flex items-center justify-between">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
              Recent Events
            </h2>
            <div className="flex items-center justify-between">
              {!initialLoading && (
                <>
                  <p className="mx-2 text-sm font-semibold dark:text-gray-200">
                    {eventLimit * eventSkip + events.length} of {count} Events
                  </p>
                  <Link
                    to="/events"
                    className="mr-4 text-sm font-semibold leading-6 text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
                  >
                    View all
                  </Link>
                </>
              )}
            </div>
          </div>
          <ul
            role="list"
            className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
          >
            {initialLoading ? (
              <div className="mx-auto my-auto lg:col-span-3">
                <LoadingSpinner w={24} h={24} />
              </div>
            ) : (
              events.map((event) => {
                return <RecentEvent key={event.id} eventData={event} />;
              })
            )}
            {!initialLoading && events.length === 0 && (
              <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                No events found.
              </h3>
            )}
          </ul>
          {!initialLoading && count > eventLimit && (
            <nav className="flex items-center justify-between px-4 pt-4 sm:px-0">
              <div className="flex flex-1 w-0 -mt-px">
                {eventSkip > 0 && (
                  <>
                    <a
                      href=""
                      className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:border-gray-300 hover:text-gray-700 dark:text-indigo-300/50 dark:hover:text-indigo-300"
                      onClick={(e) => handlePageClick(e, 1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                        />
                      </svg>
                      First
                    </a>

                    <a
                      href=""
                      className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:border-gray-300 hover:text-gray-700 dark:text-indigo-300/50 dark:hover:text-indigo-300"
                      onClick={(e) =>
                        handlePageClick(e, eventSkip > 1 ? eventSkip : 1)
                      }
                    >
                      Previous
                    </a>
                  </>
                )}
              </div>
              <div className="hidden md:-mt-px md:flex">
                {pages.length < 6 ? (
                  <>
                    {pages.map((page, i) => {
                      return (
                        <a
                          href=""
                          key={`${page}-page-${i}`}
                          className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                            eventSkip === page - 1 ? pageClassActive : pageClass
                          }`}
                          onClick={(e) => handlePageClick(e, page)}
                        >
                          {page}
                        </a>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {firstWindow.map((page, i) => {
                      return (
                        <a
                          href=""
                          key={`${page}-page-${i}`}
                          className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                            eventSkip === page - 1 ? pageClassActive : pageClass
                          }`}
                          onClick={(e) => handlePageClick(e, page)}
                        >
                          {page}
                        </a>
                      );
                    })}
                    {eventSkip < pages.length - 5 && (
                      <span className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
                        ...
                      </span>
                    )}
                    {lastWindow.map((page, i) => {
                      return (
                        <a
                          href=""
                          key={`${page}-page-${i}`}
                          className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                            eventSkip === page - 1 ? pageClassActive : pageClass
                          }`}
                          onClick={(e) => handlePageClick(e, page)}
                        >
                          {page}
                        </a>
                      );
                    })}
                  </>
                )}
              </div>
              <div className="flex justify-end flex-1 w-0 -mt-px ">
                {eventSkip < Math.ceil(count / eventLimit - 1) && (
                  <>
                    <a
                      href=""
                      className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:border-gray-300 hover:text-gray-700 dark:text-indigo-300/50 dark:hover:text-indigo-300"
                      onClick={(e) => handlePageClick(e, eventSkip + 2)}
                    >
                      Next
                    </a>
                    <a
                      href=""
                      className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:border-gray-300 hover:text-gray-700 dark:text-indigo-300/50 dark:hover:text-indigo-300"
                      onClick={(e) => handlePageClick(e, pages.length)}
                    >
                      Last
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 mx-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </a>
                  </>
                )}
              </div>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
