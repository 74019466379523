import { GET_PEOPLE } from "../../schemas/queries/People";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Pagination } from "../../components/Pagination/Pagination";
import PeopleTableRow from "./PeopleTableRow";
import { useCustomerStore } from "../../store/Customer";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const PeopleTable = () => {
  const navigate = useNavigate();
  const [people, setPeople] = useState([]);
  const [count, setCount] = useState(0);
  //   Pagination
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);

  const { customerFilter } = useCustomerStore(
    useShallow((state) => ({
      customerFilter: state.customerFilter,
    }))
  );

  const peopleQuery = useQuery(GET_PEOPLE, {
    variables: {
      skip: skip * limit,
      limit,
      customerFilter: customerFilter,
    },

    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.error(error);
      if (error.message === "Permission denied") {
        navigate("/error", {
          state: {
            status: 401,
            error: "Unauthorized to access facial recognition.",
          },
        });
      }
    },
    onCompleted: (data) => {
      setPeople(data.people.items);
      setCount(data.people.count);
      setInitialLoading(false);
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className="flow-root mt-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <div>
              {initialLoading ? (
                <div className="min-w-full min-h-[20vw] flex m-auto items-center justify-center">
                  <LoadingSpinner w={24} h={24} />
                </div>
              ) : (
                <>
                  {/* Table of People */}
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-300/20">
                    <thead className="bg-gray-50 dark:bg-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 sm:pl-6"
                        >
                          Full Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Category
                        </th>
                        <th
                          scope="col"
                          className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Arrival Alerts
                        </th>
                        <th
                          scope="col"
                          className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">People Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-200/20 dark:bg-gray-900/80">
                      {peopleQuery?.data?.people?.items.map((person) => {
                        return (
                          <PeopleTableRow key={person.id} personData={person} />
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    skip={skip}
                    onSkip={setSkip}
                    count={count}
                    limit={limit}
                    currentAmount={people.length}
                    className="min-w-full mt-1 divide-y divide-gray-300"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleTable;
