import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { DetailsHeader } from "../../components/Headers/DetailsHeader";
import { EventArrivals } from "./EventArrivals";
import { EventDetailsOverview } from "./EventDetailsOverview";
import { EventDetailsReport } from "./EventDetailsReport";
import { EventEditModal } from "./EventEditModal";
import { EventLocation } from "./EventLocation";
import { GET_EVENT_WITH_VENUE_PHOTO } from "../../schemas/queries/Events";
import LoadingSpinner from "../../components/LoadingSpinner";
import { NetworkStatus } from "@apollo/client";
import { POLICIES } from "../../constants/Policies";
import { RecognizedPeople } from "./RecognizedPeople";
import { RegisteredThreats } from "./RegisteredThreats";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import { Timestamp } from "../../components/Timestamp";
import { timestampToDate } from "../../utils/timestamps";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";
import { useTimestampStore } from "../../store/Timestamp";
import { GenerateReport } from "./GenerateReport";

export const EventDetails = () => {
	const { eventId } = useParams();
	const [venueId, setVenueId] = useState("");
	const [photo, setPhoto] = useState("");
	const [dateString, setDateString] = useState("");
	const [displayDateString, setDisplayDateString] = useState("");
	const navigate = useNavigate();
	const { updateTimestamp } = useTimestampStore(
		useShallow((state) => ({
			updateTimestamp: state.updateTimestamp,
		}))
	);
	const [chartURI, setChartURI] = useState("");

	const eventQuery = useQuery(GET_EVENT_WITH_VENUE_PHOTO, {
		variables: {
			id: eventId,
			venueId,
			hasVenueId: venueId !== "",
		},
		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			// if the venue changes, the graphql cache won't update on mutate
			setVenueId(data.event.venue);
			const [date, display] = timestampToDate(data.event.eventTime);
			// isToday(data.event.eventTime);
			setDateString(date);
			setDisplayDateString(display);
			if (data?.entityPhotos?.[0]?.url) {
				setPhoto(data.entityPhotos[0].url); // fix for when the venue does not have a photo attached and uploaded for it
			}
			updateTimestamp();
		},
		onError: (error) => {
			// DEV ONLY CONSOLE ERROR
			console.error(error.message);
			navigate("/error", {
				state: { status: 404, error: "Event could not be found." },
			});
		},
	});

	if (
		(eventQuery.loading &&
			eventQuery.networkStatus === NetworkStatus.loading) ||
		!eventQuery.data?.event
	) {
		return <ScreenLoader />;
	}

	return (
		<main>
			<DetailsHeader>
				<div className="flex items-center gap-x-6">
					<img
						src={photo}
						alt={
							eventQuery?.data?.event?.venueName
								? eventQuery?.data?.event?.venueName
								: "Event Venue"
						}
						className="flex-none w-16 h-16 rounded-full ring-1 ring-gray-900/10"
					/>
					<h1>
						<div className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
							{eventQuery.data.event.name}
						</div>
						<div className="text-sm leading-6 text-gray-800 dark:text-gray-300">
							{eventQuery.data.event.venueName}
						</div>
						<div className="text-sm leading-6 text-gray-500 dark:text-gray-300/80">
							<time dateTime={dateString}>
								{displayDateString}
							</time>
						</div>
					</h1>
				</div>
				<div className="flex flex-col space-y-1">
					<div className="flex items-center pr-4 ml-auto gap-x-4">
						<AccessControlledComponent
							policies={[POLICIES.EDIT_CUSTOMER_EVENTS]}
						>
							<EventEditModal
								eventId={eventId}
								eventData={eventQuery.data.event}
								refetch={eventQuery.refetch}
							/>
						</AccessControlledComponent>
						<GenerateReport event={eventId} />
						{/* TODO: event details report? */}
						{/* {!chartURI ? (
              <>
                <>
                  <LoadingSpinner className="w-4 h-4" w={4} h={4} />
                </>
              </>
            ) : (
              <EventDetailsReport chartURI={chartURI} eventId={eventId} />
            )} */}
					</div>
					<Timestamp />
				</div>
			</DetailsHeader>
			{/* start of main div for details */}
			<div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="grid items-start max-w-2xl grid-cols-1 grid-rows-1 mx-auto gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					<div className="lg:col-start-1 lg:row-end-1">
						<EventDetailsOverview
							eventData={eventQuery.data.event}
						/>
						<EventLocation
							eventData={eventQuery.data.event}
							venuePhotos={eventQuery.data?.entityPhotos}
						/>
					</div>
					<div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
						{/* hourly arrival chart */}
						<EventArrivals
							eventId={eventId}
							handleURI={setChartURI}
						/>

						{/* Recognized People Table */}
						{/* start of registered threats table */}
						<AccessControlledComponent
							policies={[POLICIES.READ_PEOPLE]}
						>
							<RecognizedPeople
								timeZoneId={eventQuery.data.event.timeZoneId}
							/>
						</AccessControlledComponent>
						<RegisteredThreats
							eventId={eventId}
							timeZoneId={eventQuery.data.event.timeZoneId}
						/>
					</div>
				</div>
			</div>
		</main>
	);
};

export default EventDetails;
