import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

import { Props as ModalProps } from "./index";

export interface ResponseProps {
  success: boolean;
  successMsg: string;
  errorMsg: string;
}

interface ResponseModalProps extends ModalProps, ResponseProps {}

export const ResponseModal: React.FC<ResponseModalProps> = ({
  open,
  setOpen,
  children,
  title,
  className = "",
  panelClassName = "",
  titleClassName = "",
  success,
  successMsg,
  errorMsg,
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className={className} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 dark:bg-white/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  panelClassName
                    ? panelClassName
                    : "w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-blue-xonar p-6 text-left align-middle shadow-xl transition-all"
                }
              >
                <Dialog.Title
                  as="h3"
                  className={
                    titleClassName
                      ? titleClassName
                      : "text-lg font-medium leading-6 text-gray-900 dark:text-gray-200"
                  }
                >
                  {title}
                </Dialog.Title>
                <div className="mx-auto my-4">
                  {success ? (
                    <p className="text-green-400 dark:text-green-700">
                      {successMsg}
                    </p>
                  ) : (
                    <p className="text-red-400 dark:text-red-700">{errorMsg}</p>
                  )}
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResponseModal;
