import { classNames } from "../../utils/ux";

export const DetailsHeader = ({ className = "", children }) => {
	return (
		<header className={classNames("relative pt-16 isolate", className)}>
			<div
				className="absolute inset-0 overflow-hidden -z-10"
				aria-hidden="true"
			>
				<div className="absolute -mt-16 opacity-50 left-16 top-full transform-gpu blur-3xl xl:left-1/2 xl:-ml-80">
					<div
						className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC] dark:from-blue-xonar-light dark:to-sky-xonar"
						style={{
							clipPath:
								"polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
						}}
					></div>
				</div>
				<div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5"></div>
			</div>
			<div className="px-4 py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="flex items-center justify-between max-w-2xl mx-auto gap-x-8 lg:mx-0 lg:max-w-none">
					{children}
				</div>
			</div>
		</header>
	);
};

export default DetailsHeader;
