import React, { useState } from "react";
import {
  ResponseModal,
  ResponseProps,
} from "../../../../components/Modals/ResponseModal";

import { EditDevicesHeader } from "../EditDevicesHeader";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Modal } from "../../../../components/Modals";
import { PULL_CONFIG } from "../../../../schemas/mutators/Devices";
import { Tag } from "../../../../components/Tags";
import { toCapitalized } from "../../../../utils/ux";
import { useDeviceConfigurationStore } from "../../../../store/DeviceConfiguration";
import { useMutation } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";

interface ConfigPanelProps {}

export const ConfigPanel: React.FC<ConfigPanelProps> = () => {
  const { devices } = useDeviceConfigurationStore(
    useShallow((state) => ({
      devices: state.devices,
    }))
  );

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });

  const [pullConfig, { loading, error }] = useMutation(PULL_CONFIG, {
    onError: (error) => {
      console.error(error);
    },
  });

  const [openConfirm, setOpenConfirm] = useState(false);

  const displayConfirmation = (e: any) => {
    // set error response if no devices are selected
    if (devices.length < 1) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select at least one device.",
      });
      setOpen(true);
      return;
    }
    setOpenConfirm(true);
  };

  const handlePullConfig = async (e: any) => {
    const cellIds = devices.map(({ id }) => id);
    const mqttMessage = await pullConfig({
      variables: {
        ids: cellIds,
      },
    });
    setResponse({
      ...response,
      success: true,
      successMsg: "Triggered devices to pull config files from Insight.",
    });
    setOpenConfirm(false);
    setOpen(true);
  };

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <EditDevicesHeader />
      <div className="px-12 mt-6 mb-4">
        <div className="flex w-full mt-2">
          <button
            type="button"
            className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
            onClick={displayConfirmation}
          >
            Pull Configuration from Insight
          </button>
        </div>
      </div>
      <Modal
        open={openConfirm}
        setOpen={setOpenConfirm}
        title="Are you sure you want to pull down the configuration file from Insight to these cells?"
      >
        <ul className="flex flex-col py-4 gap-y-2">
          {devices.map((device, i) => {
            return (
              <li key={`device-${i}`}>
                <Tag color={"gray"} tag={device.name} />
              </li>
            );
          })}
        </ul>
        <div className="flex justify-between w-full mt-2">
          <button
            type="button"
            className="px-2 py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
            onClick={() => setOpenConfirm(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-2 py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
            onClick={handlePullConfig}
          >
            {loading ? <LoadingSpinner /> : "Confirm"}
          </button>
        </div>
      </Modal>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={
          response.success ? "Requested to Pull Config from Insight" : "Error"
        }
      >
        <></>
      </ResponseModal>
    </div>
  );
};

export default ConfigPanel;
