import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider as Provider,
	createHttpLink,
	split,
} from "@apollo/client";
import { getMainDefinition, hasDirectives } from "@apollo/client/utilities";
import { useEffect } from "react";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { GRAPHQL_HOST, GRAPHQL_URI, wsPrefix } from "../constants";
import { useTokenStore } from "../store/Token";
import { useShallow } from "zustand/react/shallow";

export const ApolloProvider = ({ children }) => {
	// custom Apollo Client that connects with auth0 to fetch JWT token
	// so our GraphQL API can verify the request and return a response
	const { getAccessTokenSilently, isAuthenticated, error, logout } =
		useAuth0();

	const { token, setToken } = useTokenStore(
		useShallow((state) => ({
			token: state.token,
			setToken: state.setToken,
		}))
	);

	useEffect(() => {
		const getToken = async () => {
			try {
				if (error) {
					console.error(error);
					await logout({
						logoutParams: { returnTo: window.location.origin },
					});
				}
				if (isAuthenticated) {
					const authToken = await getAccessTokenSilently();
					setToken(authToken);
				}
			} catch (e) {
				console.error(`${e}; cannot make requests to GraphQL API.`);
			}
		};
		getToken();
	}, [
		token,
		getAccessTokenSilently,
		isAuthenticated,
		error,
		logout,
		setToken,
	]);

	const buildAuthLink = (apolloLink) => {
		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					authorization: token ? `Bearer ${token}` : "",
				},
			};
		});
		return authLink.concat(apolloLink);
	};

	const httpLink = createHttpLink({
		uri: GRAPHQL_URI,
		credentials: "include",
	});

	const uploadLink = createUploadLink({
		uri: GRAPHQL_URI,
		credentials: "include",
	});

	const wsLink = new GraphQLWsLink(
		createClient({
			url: `${wsPrefix}://${GRAPHQL_HOST}/graphql/subscriptions`,
			connectionParams: {
				authorization: token ? `Bearer ${token}` : "",
			},
			// lazy: true,
		})
	);

	const authHTTPLink = buildAuthLink(httpLink);
	const authUploadLink = buildAuthLink(uploadLink);

	const splitLink = split(
		({ query }) => {
			// const definition = getMainDefinition(query);
			const isUpload = hasDirectives(["isUpload"], query);
			return isUpload;
		},
		authUploadLink,
		authHTTPLink
	);

	const socketSplitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === "OperationDefinition" &&
				definition.operation !== "subscription"
			);
		},
		splitLink,
		wsLink
	);

	const client = new ApolloClient({
		link: socketSplitLink,
		cache: new InMemoryCache(),
		credentials: "include",
	});

	return <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
