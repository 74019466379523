import {
	DetailsOverview,
	DetailsOverviewTerm,
	DetailsOverviewTermTagged,
} from "../../../components/Overviews/DetailsOverview";
import {
	getPercentageOfArrivals,
	getTicketsSoldPercentage,
} from "../../../utils/ux";
import { getHourRange } from "../../../utils/timestamps";
import { useReportStore } from "../../../store/Report";
import { useShallow } from "zustand/react/shallow";
import React from "react";

export const ReportDetailsOverview: React.FC = () => {
	const { report, authorized, viewReport } = useReportStore(
		useShallow((state) => state)
	);

	if (!viewReport || !authorized) {
		return <></>;
	}

	return (
		<DetailsOverview>
			<DetailsOverviewTerm
				term={"Total Arrivals"}
				description={report?.overview?.includedWalks?.toLocaleString()}
			/>
			<DetailsOverviewTermTagged
				term={"Tickets Sold"}
				description={report?.overview?.ticketsSold?.toLocaleString()}
				tags={
					report?.overview?.ticketsSold &&
					report?.overview?.ticketsSold > 0
						? [
								{
									color: "blue",
									value: getTicketsSoldPercentage(
										report?.overview?.includedWalks,
										report?.overview?.ticketsSold
									),
								},
						  ]
						: []
				}
			/>
			<DetailsOverviewTermTagged
				term={"Registered Threats"}
				description={report?.overview?.registeredThreats?.toLocaleString()}
				tags={[
					{
						color: "blue",
						value: getPercentageOfArrivals(
							report?.overview?.includedWalks,
							report?.overview?.registeredThreats
						),
					},
				]}
			/>
			<DetailsOverviewTermTagged
				term={"Alerts"}
				description={report?.overview?.alerts?.toLocaleString()}
				tags={[
					{
						color: "blue",
						value: getPercentageOfArrivals(
							report?.overview?.includedWalks,
							report?.overview?.alerts
						),
					},
				]}
			/>
			<DetailsOverviewTermTagged
				term={"Max Hourly Arrivals"}
				description={report?.overview?.maxHourlyArrivals?.toLocaleString()}
				tags={[
					{
						color: "blue",
						value: getHourRange(
							report?.overview?.maxHourlyArrivalsStartTime,
							report?.overview?.maxHourlyArrivalsFinishTime,
							report?.metadata?.timeZoneId
						),
					},
				]}
			/>
			{/* TODO: need to check if report was generated by someone with face rec permissions */}
			<DetailsOverviewTermTagged
				term={"People Recognized"}
				description={report?.overview?.recognized?.toLocaleString()}
				tags={[
					{
						color: "green",
						value: report?.overview?.recognizedGreen?.toLocaleString(),
					},
					{
						color: "red",
						value: report?.overview?.recognizedRed?.toLocaleString(),
					},
				]}
			/>
		</DetailsOverview>
	);
};

export default ReportDetailsOverview;
