import { Fragment, useMemo, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { getHourRange, timestampToDate } from "../../utils/timestamps";
import {
  getPercentageOfArrivals,
  getTicketsSoldPercentage,
} from "../../utils/ux";

import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { GET_ENTITY_PHOTOS } from "../../schemas/queries/EntityPhotos";
import { Link } from "react-router-dom";
import { LiveIndicator } from "../../components/LiveIndicator";
import LoadingSpinner from "../../components/LoadingSpinner";
import { POLICIES } from "../../constants/Policies";
import { isToday } from "../../utils/timestamps";
import { useQuery } from "@apollo/client";

export default function RecentEvent({ eventData }) {
  const [thumbnail, setThumbnail] = useState("");
  // TODO: people recognized and categories
  const { loading } = useQuery(GET_ENTITY_PHOTOS, {
    variables: {
      entityId: eventData.venue,
      includeThumbnail: true,
    },
    onCompleted: (data) => {
      setThumbnail(data.entityPhotos[0].thumbnail);
    },
    onError: (error) => {
      console.error(error.message);
      console.error("Event venue thumbnail could not be found.");
    },
  });

  const [dateString, displayDateString] = timestampToDate(eventData.eventTime);

  const activeEvent = useMemo(() => {
    return isToday(eventData.eventTime);
  }, [eventData.eventTime]);

  return (
    <li className="overflow-hidden border border-gray-200 rounded-xl dark:border-gray-200/20">
      <div className="flex items-center p-6 border-b gap-x-4 border-gray-900/5 bg-gray-50 dark:bg-blue-xonar-light/5">
        {loading ? (
          <LoadingSpinner className="w-12 h-10" w={12} h={10} />
        ) : (
          <img
            src={thumbnail}
            alt="Venue"
            className="flex-none object-cover w-12 h-12 bg-transparent rounded-lg ring-1 ring-gray-900/10"
          />
        )}
        <div className="flex justify-between w-full">
          <div>
            <div className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
              <Link to={`/events/${eventData.id}`}>{eventData.name}</Link>
            </div>
            <div className="text-xs font-medium text-gray-700 dark:text-gray-300">
              {eventData.venueName}
            </div>
            <div className="text-xs font-light leading-6 text-gray-700 dark:text-gray-300">
              <time dateTime={dateString}>{displayDateString}</time>
            </div>
          </div>

          <div className="flex flex-col justify-between ml-auto">
            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <Menu.Button
                    type="button"
                    className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
                    id="options-menu-0-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open options</span>
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                    </svg>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    show={open}
                  >
                    <Menu.Items
                      className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white dark:bg-blue-xonar py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu-0-button"
                      tabIndex="-1"
                    >
                      <Menu.Item>
                        <Link
                          to={`/events/${eventData.id}`}
                          className="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-200"
                          role="menuitem"
                          tabIndex="-1"
                          id="options-menu-0-item-0"
                        >
                          View<span className="sr-only"> Event</span>
                        </Link>
                      </Menu.Item>
                      {/* TODO: Edit button */}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            {activeEvent ? (
              <div className="mx-auto mb-1 last:mt-auto">
                <LiveIndicator />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <dl className="px-6 py-4 -my-3 text-sm leading-6 divide-y divide-gray-100 dark:divide-gray-200/20">
        <div className="flex justify-between py-3 gap-x-4">
          <dt className="text-gray-500 dark:text-gray-200">Total Arrivals</dt>
          <dd className="font-medium text-gray-900 dark:text-gray-300">
            {eventData.includedWalks?.toLocaleString()}
          </dd>
        </div>
        <div className="flex justify-between py-3 gap-x-4">
          <dt className="text-gray-500 dark:text-gray-200">Tickets Sold</dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900 dark:text-gray-300">
              {eventData.ticketsSold?.toLocaleString()}
            </div>
            {eventData.ticketsSold > 0 && (
              <div className="px-2 py-1 text-xs font-medium text-blue-700 rounded-md ring-1 ring-inset bg-blue-50 ring-blue-600/10 dark:text-blue-300 dark:bg-blue-500/50">
                {getTicketsSoldPercentage(
                  eventData.includedWalks,
                  eventData.ticketsSold
                )}
              </div>
            )}
          </dd>
        </div>
        <div className="flex justify-between py-3 gap-x-4">
          <dt className="text-gray-500 dark:text-gray-200">
            Registered Threats
          </dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900 dark:text-gray-300">
              {eventData.registeredThreats?.toLocaleString()}
            </div>
            <div className="px-2 py-1 text-xs font-medium text-blue-700 rounded-md ring-1 ring-inset bg-blue-50 ring-blue-600/10 dark:text-blue-300 dark:bg-blue-500/50">
              {getPercentageOfArrivals(
                eventData.includedWalks,
                eventData.registeredThreats
              )}
            </div>
          </dd>
        </div>
        <div className="flex justify-between py-3 gap-x-4">
          <dt className="text-gray-500 dark:text-gray-200">Alerts</dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900 dark:text-gray-300">
              {eventData.alerts?.toLocaleString()}
            </div>
            <div className="px-2 py-1 text-xs font-medium text-blue-700 rounded-md ring-1 ring-inset bg-blue-50 ring-blue-600/10 dark:text-blue-300 dark:bg-blue-500/50">
              {getPercentageOfArrivals(
                eventData.includedWalks,
                eventData.alerts
              )}
            </div>
          </dd>
        </div>
        <div className="flex justify-between py-3 gap-x-4">
          <dt className="text-gray-500 dark:text-gray-200">
            Max Hourly Arrivals
          </dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900 dark:text-gray-300">
              {eventData.maxHourlyArrivals?.toLocaleString()}
            </div>
            <div className="px-2 py-1 text-xs font-medium text-blue-700 rounded-md ring-1 ring-inset bg-blue-50 ring-blue-600/10 dark:text-blue-300 dark:bg-blue-500/50">
              {getHourRange(
                eventData.maxHourlyArrivalsStartTime,
                eventData.maxHourlyArrivalsFinishTime,
                eventData.timeZoneId
              )}
            </div>
          </dd>
        </div>
        <AccessControlledComponent policies={[POLICIES.READ_PEOPLE]}>
          <div className="flex justify-between py-3 gap-x-4">
            <dt className="text-gray-500 dark:text-gray-200">
              People Recognized
            </dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900 dark:text-gray-300">
                {eventData.recognized?.toLocaleString()}
              </div>
              <div className="px-2 py-1 text-xs font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/10 dark:text-green-300 dark:bg-green-500/50">
                {eventData.recognizedGreen?.toLocaleString()}
              </div>
              <div className="px-2 py-1 text-xs font-medium text-red-700 rounded-md ring-1 ring-inset bg-red-50 ring-red-600/10 dark:text-red-300 dark:bg-red-500/50">
                {eventData.recognizedRed?.toLocaleString()}
              </div>
            </dd>
          </div>
        </AccessControlledComponent>
      </dl>
    </li>
  );
}
