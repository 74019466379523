import { Color, TAG_COLORS } from "../../constants/Colors";
import React, { useState } from "react";
import { classNames, toCapitalized } from "../../utils/ux";

import { Modal } from "../../components/Modals";
import { Tab } from "@headlessui/react";

export interface Category {
  label: string;
  color: Color | string;
}

interface AddCategoryProps {
  category: Category;
  setCategory: React.Dispatch<React.SetStateAction<Category>>;
}

export const CategoryPreview: React.FC<Category> = ({ label, color }) => {
  return (
    <span
      className={classNames(
        "px-2 py-1 my-auto text-md font-bold text-center rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 capitalize",
        color ? TAG_COLORS[color.toLowerCase()] : ""
      )}
    >
      {label}
    </span>
  );
};

interface RemoveableCategory extends Category {
  removeCategory: () => void;
}

const RemoveableCategoryPreview: React.FC<RemoveableCategory> = ({
  label,
  color,
  removeCategory,
}) => {
  return (
    <span
      className={classNames(
        "inline-flex items-center gap-x-0.5 px-2 py-1 my-auto text-xs font-medium text-center rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 capitalize",
        color ? TAG_COLORS[color.toLowerCase()] : ""
      )}
    >
      {label}
      <button
        type="button"
        className="group relative h-3.5 w-3.5 rounded-sm hover:bg-red-600/20 dark:hover:bg-red-500/40 ml-1"
        onClick={() => removeCategory()}
      >
        <span className="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          className="h-3.5 w-3.5 stroke-red-600/50 dark:stroke-red-300 group-hover:stroke-red-600/75 m-auto"
        >
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
      </button>
    </span>
  );
};

interface AddNewCategoryProps extends AddCategoryProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewCategory: React.FC<AddNewCategoryProps> = ({
  category,
  setCategory,
  setOpen,
}) => {
  const categoryColors = [
    { id: "green", color: toCapitalized(Color.GREEN.toLowerCase()) },
    { id: "red", color: toCapitalized(Color.RED.toLowerCase()) },
  ];
  const [newCategory, setNewCategory] = useState<Category>({
    label: "",
    color: Color.GREEN.toLowerCase(),
  });

  const handleAdd = () => {
    setCategory({
      label: toCapitalized(newCategory.label),
      color: newCategory.color.toUpperCase(),
    });
    setNewCategory({ label: "", color: Color.GREEN.toLowerCase() });
    setOpen(false);
  };

  return (
    <Tab.Panel>
      <div className="flex flex-col px-4 gap-y-2">
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="categoryLabel"
          >
            Category <span className="text-red-300 dark:text-red-500">*</span>
          </label>
          <div className="w-full pl-4">
            <input
              type="text"
              name="categoryLabel"
              id="categoryLabel"
              data-testid="categoryInput"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
              aria-describedby={"Person's Category Type"}
              value={newCategory.label}
              required
              onChange={(e: any) =>
                setNewCategory({ ...newCategory, label: e.target.value })
              }
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="categoryColor"
          >
            Color <span className="text-red-300 dark:text-red-500">*</span>
          </label>
          <div className="flex flex-row w-full pl-4 gap-x-4">
            {categoryColors.map(({ id, color }) => {
              return (
                <div key={id} className="flex items-center justify-between">
                  <input
                    id={id}
                    name="notification-method"
                    type="radio"
                    defaultChecked={id === "green"}
                    data-testid={`categoryColor-${color}`}
                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600 dark:text-blue-xonar-light dark:focus:ring-blue-xonar-light"
                    onChange={() => setNewCategory({ ...newCategory, color })}
                  />
                  <label
                    htmlFor={id}
                    className="block mx-2 text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                  >
                    {color}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        {!newCategory.label.trim() ? (
          <></>
        ) : (
          <div className="flex flex-col">
            <div className="pt-8 m-auto">
              <CategoryPreview
                color={newCategory.color}
                label={newCategory.label}
              />
            </div>
            <div className="mt-4 ml-auto mr-4">
              <button
                className="px-3 py-[2px] text-gray-100 font-semibold bg-indigo-500 rounded-md shadow-sm text-md dark:bg-sky-xonar/20 dark:text-gray-300 ring-white/60 ring-offset-2 ring-offset-indigo-500 dark:ring-offset-sky-xonar/15 dark:ring-sky-xonar/15 focus:outline-none focus:ring-2"
                onClick={handleAdd}
                data-testid="addCategoryBtn"
              >
                Add
              </button>
            </div>
          </div>
        )}
      </div>
    </Tab.Panel>
  );
};

const SelectCategory: React.FC<AddNewCategoryProps> = ({
  category,
  setCategory,
  setOpen,
}) => {
  return (
    <Tab.Panel>
      <div className="flex flex-col px-4 gap-y-2">
        <></>
      </div>
    </Tab.Panel>
  );
};

interface ModalTabProps {
  name: string;
}
const ModalTab: React.FC<ModalTabProps> = ({ name }) => {
  return (
    <Tab
      className={({ selected }) =>
        classNames(
          "w-full rounded-lg py-2.5 text-md font-medium leading-5",
          "ring-white/60 ring-offset-2 ring-offset-indigo-500 dark:ring-offset-sky-xonar/15 dark:ring-sky-xonar/15 focus:outline-none cursor-default",
          selected
            ? // TODO: add back later when you finish existing categories tab panel
              // ? "bg-white text-indigo-600 shadow-sm dark:bg-sky-xonar/10 dark:text-gray-300"
              " text-indigo-600 dark:text-gray-300"
            : "text-gray-600 hover:bg-white/[0.12] hover:text-indigo-500 font-semibold dark:hover:bg-sky-xonar/5 dark:text-gray-500 dark:hover:text-gray-400"
          // "focus:ring-2"
        )
      }
    >
      {name}
    </Tab>
  );
};

export const AddCategory: React.FC<AddCategoryProps> = ({
  category,
  setCategory,
}) => {
  const [open, setOpen] = useState(false);
  const removeCategory = () => {
    setCategory({ label: "", color: Color.GREEN });
  };

  // pull down customers' categories

  return (
    <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
      <label
        className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
        htmlFor="category"
      >
        Category <span className="text-red-300 dark:text-red-500">*</span>
      </label>
      <div className="flex flex-row w-full pl-4 gap-x-3 lg:gap-x-6">
        {!category.label.trim() ? (
          <button
            type="button"
            className="px-2 py-1 my-auto text-xs font-bold text-center text-white bg-indigo-600 rounded-md shadow-sm cursor-pointer dark:text-gray-300 dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:focus-visible:outline-blue-xonar-light/50"
            onClick={(e) => setOpen(true)}
          >
            Add Category
          </button>
        ) : (
          <RemoveableCategoryPreview
            {...category}
            removeCategory={removeCategory}
          />
        )}
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        title=""
        panelClassName="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-blue-xonar px-2 pb-6 pt-4 text-left align-middle shadow-xl transition-all"
      >
        <div className="flex flex-col pb-6 space-y-4">
          <Tab.Group>
            <Tab.List
              className={classNames(
                "flex p-1 space-x-1 rounded-lg "
                // "bg-gray-300/20 dark:bg-blue-xonar-secondary/50"
              )}
            >
              <ModalTab name="New" />
              {/* <ModalTab name="Existing" /> */}
            </Tab.List>
            <Tab.Panels>
              <AddNewCategory
                setCategory={setCategory}
                category={category}
                setOpen={setOpen}
              />
              {/* TODO: */}
              {/* <SelectCategory
                setCategory={setCategory}
                category={category}
                setOpen={setOpen}
              /> */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal>
    </div>
  );
};

export default AddCategory;
