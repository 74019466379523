import { create } from "zustand";
import { getFullTime } from "../utils/timestamps";
import { useMemo } from "react";

interface TimestampState {
  timestamp: number;
  displayTimestamp: string;
  updateTimestamp: () => void;
}

export const useTimestampStore = create<TimestampState>()((set, get) => ({
  timestamp: new Date().getTime(),
  displayTimestamp: "",
  updateTimestamp: () => {
    const time = new Date().getTime();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    set((state) => ({
      timestamp: time,
      displayTimestamp: getFullTime(time, undefined, tz),
    }));
  },
}));
