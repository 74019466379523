import { useTimeframe } from "../../hooks/timeframe";

export const Arrivals = () => {
  const activeTimeClassName = "text-indigo-600 dark:text-indigo-400";
  const timeClassName = "text-gray-700 dark:text-gray-100";
  const { timeframe, setTimeframe } = useTimeframe();

  const handleTimeFrame = (e, time) => {
    e.preventDefault();
    setTimeframe(time);
  };
  return (
		<>
			<h1 className="pb-2 text-base font-semibold leading-7 text-gray-900 border-b border-gray-200 dark:text-gray-200 sm:border-0 sm:p-0">
				Arrivals
			</h1>
			<div className="flex flex-row order-last w-full text-sm font-semibold leading-6 gap-x-6 sm:gap-x-8 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
				<a
					href=""
					className={
						timeframe === 0 ? activeTimeClassName : timeClassName
					}
					onClick={(e) => handleTimeFrame(e, 0)}
				>
					Today
				</a>
				<a
					href=""
					className={
						timeframe === 7 ? activeTimeClassName : timeClassName
					}
					onClick={(e) => handleTimeFrame(e, 7)}
				>
					Last Week
				</a>
				<a
					href=""
					className={
						timeframe === 30 ? activeTimeClassName : timeClassName
					}
					onClick={(e) => handleTimeFrame(e, 30)}
				>
					Last Month
				</a>
				<a
					href=""
					className={
						timeframe === -1 ? activeTimeClassName : timeClassName
					}
					onClick={(e) => handleTimeFrame(e, -1)}
				>
					Full History
				</a>
			</div>
		</>
  );
};

export default Arrivals;
