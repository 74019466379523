import { classNames } from "../utils/ux";
import { useShallow } from "zustand/react/shallow";
import { useTimestampStore } from "../store/Timestamp";

export const Timestamp = ({ className = "" }) => {
  const { displayTimestamp } = useTimestampStore(
    useShallow((state) => ({
      displayTimestamp: state.displayTimestamp,
    }))
  );
  return (
    <span
      className={classNames(
        "hidden pr-4 my-auto ml-auto text-xs leading-6 text-gray-400 md:inline-block dark:text-gray-200/80",
        className
      )}
    >
      {displayTimestamp ? `Last Updated: ${displayTimestamp}` : ""}
    </span>
  );
};

export default Timestamp;
