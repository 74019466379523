import { Policies } from "@apollo/client/cache";
import { POLICIES } from "../../constants/Policies";

export type NavigationLink = {
	name: string;
	to?: string;
	policies?: string[];
	children?: NavigationLink[];
};

export const navigation: NavigationLink[] = [
	{ name: "Home", to: "/home", policies: [POLICIES.READ_CUSTOMER_EVENTS] },
	{
		name: "Events",
		to: "/events",
		policies: [POLICIES.READ_CUSTOMER_EVENTS],
	},
	{ name: "Venues", to: "/venues", policies: [POLICIES.READ_VENUES] },
	{ name: "Devices", to: "/devices", policies: [POLICIES.READ_CELL] },
	{ name: "People", to: "/people", policies: [POLICIES.READ_PEOPLE] },
	{
		name: "Support",
		children: [
			{
				name: "Add Ticket",
				to: "/support/tickets/add",
				policies: [POLICIES.READ_CELL],
			},
			{
				name: "View Tickets",
				to: "/support/tickets",
				policies: [POLICIES.READ_CELL],
			},
			// {
			// 	name: "User Manuals",
			// 	to: "/support/manuals",
			// },
		],
	},
	// TODO: add routes below when ready for prod
	// {
	// 	name: "Walks",
	// 	to: "/walks",
	// },
	// {
	//   name: "Learning Center",
	//   to: "https://knowledge.xonar.com/",
	//   target: "_blank",
	// },
];
