import colors from "tailwindcss/colors";

const SERIES_COLORS = [
	colors.blue,
	colors.pink,
	colors.orange,
	colors.rose,
	colors.purple,
];

// event metric aggregations
export const getMetricColor = (difference) => {
	let color;
	if (difference < 0) {
		color = "text-rose-600 dark:text-rose-400";
	}
	if (difference === 0) {
		color = "text-gray-700 dark:text-gray-200";
	}
	if (difference > 0) {
		color = "text-green-700 dark:text-green-400";
	}
	return color;
};

export const getMetricPercentage = (difference) => {
	let percentage;
	if (difference < 0) {
		percentage = `${(Math.round(difference * 10000) / 100)
			.toFixed(2)
			.toLocaleString()}%`;
	}
	if (difference === 0) {
		percentage = "";
	}
	if (difference > 0) {
		percentage = `+${(Math.round(difference * 10000) / 100)
			.toFixed(2)
			.toLocaleString()}%`;
	}
	return percentage;
};

export const roundTwoDecimalPlaces = (number) => {
	const rounded = +(Math.round(number + "e+2") + "e-2");
	return rounded;
};

// event detail metric percentages
export const getTicketsSoldPercentage = (totalArrivals, ticketsSold) => {
	const percent = roundTwoDecimalPlaces(totalArrivals / ticketsSold) * 100;
	const percentage = `${percent}%`;
	return percentage;
};

export const getPercentageOfArrivals = (totalArrivals, metric) => {
	const percent = roundTwoDecimalPlaces(metric / totalArrivals) * 100;
	let percentage;
	if (percent === 100) {
		return "100%";
	}

	percentage = percent < 1 ? percent.toPrecision(1) : percent.toPrecision(2);
	percentage = isNaN(percentage) ? 0 : percentage;
	percentage = `${percentage}%`;
	return percentage;
};

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const getSeriesColor = (i, darkMode = false) => {
	const index = i % SERIES_COLORS.length;
	const shade = darkMode ? 300 : 100;
	const color = SERIES_COLORS[index][shade];
	return color;
};

export const toCapitalized = (word) => {
	const capitalized = `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
	return capitalized;
};

export const hasWhiteSpace = (str) => {
	return /\s/.test(str);
};

export const toggleBodyScroll = (disable) => {
	if (disable) {
		document.body.classList.add("overflow-hidden");
	} else {
		document.body.classList.remove("overflow-hidden");
	}
};
