import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { DebouncedFunc } from "lodash";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";

const statuses = [
	{ id: 1, name: "All" },
	{ id: 2, name: "New" },
	{ id: 3, name: "In Progress" },
	{ id: 4, name: "Resolved" },
];

interface TicketFiltersProps {
	statusFilter: string;
	setStatusFilter: (status: string) => void;
	order: number;
	handleSort: () => void;
	subjectQuery: string;
	setSubjectQuery: (query: string) => void;
	cellNameQuery: string;
	setCellNameQuery: (query: string) => void;
}

export default function TicketFilters({
	statusFilter,
	setStatusFilter,
	order,
	handleSort,
	subjectQuery,
	setSubjectQuery,
	cellNameQuery,
	setCellNameQuery,
}: TicketFiltersProps) {
	const [localSubjectQuery, setLocalSubjectQuery] = useState(subjectQuery);
	const [localCellNameQuery, setLocalCellNameQuery] = useState(cellNameQuery);

	const debouncedSetSubjectQuery: DebouncedFunc<(value: string) => void> =
		useCallback(
			debounce((value: string) => {
				setSubjectQuery(value);
			}, 300),
			[setSubjectQuery]
		);

	useEffect(() => {
		debouncedSetSubjectQuery(localSubjectQuery);
		return () => {
			debouncedSetSubjectQuery.cancel();
		};
	}, [localSubjectQuery, debouncedSetSubjectQuery]);

	const debouncedSetCellNameQuery: DebouncedFunc<(value: string) => void> =
		useCallback(
			debounce((value: string) => {
				setCellNameQuery(value);
			}, 300),
			[setCellNameQuery]
		);

	useEffect(() => {
		debouncedSetCellNameQuery(localCellNameQuery);
		return () => {
			debouncedSetCellNameQuery.cancel();
		};
	}, [localCellNameQuery, debouncedSetCellNameQuery]);

	const handleStatusChange = (status: { id: number; name: string }) => {
		setStatusFilter(status.name);
	};

	return (
		<div className="flex flex-col sm:flex-row items-start sm:items-center mb-10 space-y-2 sm:space-y-0 sm:space-x-4">
			<div className="flex shadow-sm">
				<input
					type="text"
					name="subject"
					id="subject"
					className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 bg-white text-gray-900 dark:bg-blue-xonar dark:text-gray-200 dark:border-white/10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					placeholder="Search by Subject"
					value={localSubjectQuery}
					onChange={(e) => setLocalSubjectQuery(e.target.value)}
				/>
				<button
					type="button"
					onClick={handleSort}
					className="relative rounded-l-none border-0 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-1.5 text-sm font-semibold text-gray-900 dark:text-gray-200/50 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-blue-xonar/90 w-24"
				>
					<span className="w-5 h-5 flex items-center justify-center">
						{order === 1 ? (
							<svg
								className="h-5 w-5"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fillRule="evenodd"
									d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
									clipRule="evenodd"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								className="w-5 h-5"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
								/>
							</svg>
						)}
					</span>
					Sort
				</button>
			</div>
			<div>
				<input
					type="text"
					name="cellName"
					id="cellName"
					className="block w-full rounded-md border-0 py-1.5 pl-10 bg-white text-gray-900 dark:bg-blue-xonar dark:text-gray-200 dark:border-white/10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					placeholder="Search by Device"
					value={localCellNameQuery}
					onChange={(e) => setLocalCellNameQuery(e.target.value)}
				/>
			</div>
			<div className="min-w-40">
				<Listbox
					value={statuses.find((s) => s.name === statusFilter)}
					onChange={handleStatusChange}
				>
					<div className="relative">
						<Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 dark:bg-blue-xonar dark:text-gray-200 dark:border-white/10 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
							<span className="block truncate">
								{statuses.find((s) => s.name === statusFilter)
									?.name || "All"}
							</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<ChevronUpDownIcon
									aria-hidden="true"
									className="h-5 w-5 text-gray-400"
								/>
							</span>
						</Listbox.Button>
						<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-blue-xonar dark:text-gray-200 py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							{statuses.map((status) => (
								<Listbox.Option
									key={status.id}
									value={status}
									className={({ active }) =>
										`relative cursor-default select-none py-2 pl-3 pr-9 ${
											active
												? "bg-indigo-600 text-white"
												: "text-gray-900 dark:bg-blue-xonar dark:text-gray-200"
										}`
									}
								>
									{({ selected, active }) => (
										<>
											<span
												className={`block truncate ${
													selected
														? "font-semibold"
														: "font-normal"
												}`}
											>
												{status.name}
											</span>
											{selected ? (
												<span
													className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
														active
															? "text-white"
															: "text-indigo-600"
													}`}
												>
													<CheckIcon
														className="h-5 w-5"
														aria-hidden="true"
													/>
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</div>
				</Listbox>
			</div>
		</div>
	);
}
