import { classNames } from "../utils/ux";

export const LoadingSpinner = ({ h = 8, w = 8, className = "" }) => {
	const height = `h-${h}`;
	const width = `w-${w}`;
	return (
		<div
			className={classNames(
				`inline-block ${height} ${width} animate-spin rounded-full border-[2px] border-solid border-current border-r-transparent align-[-0.125em] text-primary dark:text-gray-200 motion-reduce:animate-[spin_1.5s_linear_infinite]`,
				className
			)}
			role="status"
		>
			<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
				Loading...
			</span>
		</div>
	);
};

export const DynamicLoadingSpinner = ({ className = "w-4 h-4" }) => {
	return (
		<div
			className={classNames(
				`inline-block animate-spin rounded-full border-[2px] border-solid border-current border-r-transparent align-[-0.125em] text-primary dark:text-gray-200 motion-reduce:animate-[spin_1.5s_linear_infinite]`,
				className
			)}
			role="status"
		>
			<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
				Loading...
			</span>
		</div>
	);
};

export default LoadingSpinner;
