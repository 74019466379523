import { useMemo, useState } from "react";

export const usePageWindow = ({ onSkip, count, limit }) => {
	const pages = useMemo(() => {
		return Array.from(
			{ length: Math.ceil(count / limit) },
			(x, i) => i + 1
		);
	}, [count, limit]);

	const [windowLeft, setWindowLeft] = useState(0);
	const [windowRight, setWindowRight] = useState(3);

	const handlePageClick = (event, page) => {
		event.preventDefault();
		if (pages.length >= 6) {
			if (page >= pages.length - 3) {
				setWindowLeft(pages.length - 6);
				setWindowRight(pages.length - 3);
			} else {
				const windowRightIndex = page === 1 ? 3 : page + 1;
				setWindowLeft(windowRightIndex - 3);
				setWindowRight(windowRightIndex);
			}
		}
		onSkip(Math.min(pages.length, page - 1));
	};
	const firstWindow = useMemo(() => {
		return [...pages.slice(windowLeft, windowRight)];
	}, [pages, windowLeft, windowRight]);
	const lastWindow = [...pages.slice(-3)];

	return { pages, handlePageClick, firstWindow, lastWindow };
};

export const usePageStyles = () => {
	const pageClassActive =
		"z-10 bg-indigo-600 dark:bg-indigo-600/50 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
	const pageClass =
		"text-gray-900 dark:text-gray-200 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:text-gray-300 focus:outline-offset-0";
	return { pageClass, pageClassActive };
};
