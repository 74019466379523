export const LiveIndicator = () => {
  return (
    <>
      <span className="relative flex w-3 h-3">
        <span className="absolute inline-flex w-full h-full bg-green-500 rounded-full opacity-75 animate-ping dark:bg-green-700"></span>
        <span className="relative inline-flex w-3 h-3 bg-green-300 rounded-full dark:bg-green-500/50"></span>
      </span>
    </>
  );
};

export default LiveIndicator;
