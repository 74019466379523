import { useMemo } from "react";
import { usePortalUserStore } from "../store/PortalUser";
import { useShallow } from "zustand/react/shallow";
import { PortalUser } from "../store/PortalUser";

type GeneralRoles =
	| "ADMIN"
	| "SUPERVISOR"
	| "STAFF"
	| "SUPERADMIN"
	| "TECHNICIAN";

type FacialRecognitionRoles = "FACIALRECOGNITION" | "FACIALRECOGNITIONADMIN";

export type PortalRole = GeneralRoles | FacialRecognitionRoles;

export const useRole = (portalUser: PortalUser) => {
	const isAdmin = useMemo(() => {
		return (
			portalUser?.role === "ADMIN" || portalUser?.roles?.includes("ADMIN")
		);
	}, [portalUser]);
	const isSupervisor = useMemo(() => {
		return (
			portalUser?.role === "SUPERVISOR" ||
			portalUser?.roles?.includes("SUPERVISOR")
		);
	}, [portalUser]);
	const isStaff = useMemo(() => {
		return (
			portalUser?.role === "STAFF" || portalUser?.roles?.includes("STAFF")
		);
	}, [portalUser]);

	return { isAdmin, isSupervisor, isStaff };
};

export const usePolicies = (policiesRequired, hasOne = false) => {
	const { userPermissions } = usePortalUserStore(
		useShallow((state) => ({
			userPermissions: state.portalUser.permissions,
		}))
	);

	const hasAccess = useMemo(() => {
		if (hasOne) {
			return policiesRequired.some((policy) =>
				userPermissions?.includes(policy)
			);
		}
		return policiesRequired.every((policy) =>
			userPermissions?.includes(policy)
		);
	}, [policiesRequired, userPermissions, hasOne]);
	return { hasAccess };
};
