import { Breadcrumbs } from "../../../components/Menus/Breadcrumbs";
import { useMemo, useState } from "react";

import { GET_DEVICES } from "../../../schemas/queries/Devices";
import MultiSelect from "../../../components/Menus/MultiSelect";
import React from "react";
import ScreenLoader from "../../../components/Loaders/ScreenLoader";
import { useCustomerStore } from "../../../store/Customer";
import { useDeviceConfigurationStore } from "../../../store/DeviceConfiguration";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { breadcrumbMap } from "./BreadcrumbMap";

interface EditDevicesHeaderProps {
	children?: any;
}

export const EditDevicesHeader: React.FC<EditDevicesHeaderProps> = ({
	children,
}) => {
	const { customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customerFilter: state.customerFilter,
		}))
	);
	const [items, setItems] = useState<any[]>([]);
	const deviceQuery = useQuery(GET_DEVICES, {
		variables: {
			customers: customerFilter,
		},
		onCompleted: (data) => {
			const customerFriendlyDevices = data.devices.items.map((device) => {
				const friendlyName = device.customerDeviceName
					? device.customerDeviceName
					: device.name;
				return {
					...device,
					name: friendlyName,
				};
			});

			setItems(customerFriendlyDevices);
		},
	});
	const location = useLocation();
	const path = location.pathname.toLowerCase();
	const pages = path.split("/").filter((s) => s);
	const breadcrumbPages = useMemo(() => {
		return pages.map((page, i) => {
			return {
				...breadcrumbMap[page],
				current: i === pages.length - 1,
			};
		});
	}, [pages]);
	const { devices, setDevices } = useDeviceConfigurationStore(
		useShallow((state) => ({
			devices: state.devices,
			setDevices: state.setDevices,
		}))
	);
	if (deviceQuery.loading) return <ScreenLoader />;

	return (
		<>
			<div className="px-8 mt-4">
				<Breadcrumbs pages={breadcrumbPages} />
			</div>
			<div className="px-12 my-2">
				<MultiSelect
					value={devices}
					onChange={setDevices}
					itemType="device"
					items={items}
					menuHeight={"max-h-64"}
				/>
			</div>
			{children}
		</>
	);
};

export default EditDevicesHeader;
