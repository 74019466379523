import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { useShallow } from "zustand/react/shallow";
import { UploadImages } from "../../components/Inputs/UploadImages";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import MultiSelect from "../../components/Menus/MultiSelect";
import ResponseModal from "../../components/Modals/ResponseModal";
import { ADD_HUBSPOT_TICKETS } from "../../schemas/mutators/HubSpot";
import { GET_DEVICES } from "../../schemas/queries/Devices";
import { useCustomerStore } from "../../store/Customer";

const formSchema = z.object({
	subject: z
		.string()
		.min(1, "Subject is required")
		.max(100, "Subject must be 100 characters or less"),
	content: z
		.string()
		.min(1, "Description is required")
		.max(500, "Description must be 500 characters or less"),
});

const formSubmissionSchema = formSchema.extend({
	files: z.array(z.instanceof(File)),
	cells: z
		.array(
			z.object({
				id: z.string().optional(),
				cellName: z.string().optional(),
				cellIdentifier: z.string().optional(),
			})
		)
		.optional(),
});

export default function SupportForm() {
	const navigate = useNavigate();
	const [supportImages, setSupportImages] = useState<File[]>([]);
	const [responseModelOpen, setResponseModelOpen] = useState<boolean>(false);
	const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
	const [formSubmissions, setFormSubmissions] = useState<
		z.infer<typeof formSubmissionSchema>[]
	>([]);
	const [validationError, setValidationError] = useState<string | null>(null);
	const [hasCurrentFormContent, setHasCurrentFormContent] = useState(false);

	const [
		submitHubSpotTickets,
		{ loading: hubSpotLoading, error: hubSpotError },
	] = useMutation(ADD_HUBSPOT_TICKETS);

	const { customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customerFilter: state.customerFilter,
		}))
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		getValues,
		control,
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			subject: "",
			content: "",
		},
		mode: "onSubmit",
	});

	const content = watch("content");
	const contentCount = content ? content.length : 0;

	const [deviceItems, setDeviceItems] = useState<any[]>([]);

	const {
		loading: devicesLoading,
		error: devicesError,
		data,
	} = useQuery(GET_DEVICES, {
		variables: {
			customers: customerFilter,
		},
		onError: (error) => {
			console.error("Apollo error:", error);
		},
		onCompleted: (data) => {
			const customerFriendlyDevices = data.devices.items.map((device) => {
				const friendlyName = device.customerDeviceName
					? device.customerDeviceName
					: device.name;
				return {
					...device,
					name: friendlyName,
				};
			});

			setDeviceItems(customerFriendlyDevices);
		},
	});

	// Watch for changes in the form fields
	const watchedFields = useWatch({
		control,
		defaultValue: { subject: "", content: "" },
	});

	// Update hasCurrentFormContent when form fields, images, or devices change
	useEffect(() => {
		setHasCurrentFormContent(
			!!watchedFields.subject ||
				!!watchedFields.content ||
				supportImages.length > 0 ||
				selectedDevices.length > 0
		);
	}, [watchedFields, supportImages, selectedDevices]);

	const totalFormsCount = useMemo(() => {
		return formSubmissions.length + (hasCurrentFormContent ? 1 : 0);
	}, [formSubmissions.length, hasCurrentFormContent]);

	if (devicesLoading) return <ScreenLoader />;
	if (devicesError)
		return (
			<p className="text-red-500">
				Error loading devices: {devicesError.message}
			</p>
		);

	const saveValues = async (values: z.infer<typeof formSchema>) => {
		try {
			const submissionData = {
				subject: values.subject,
				content: values.content,
				cells:
					selectedDevices.length > 0
						? selectedDevices.map((device) => ({
								id: device.id,
								cellName: device.name,
								cellIdentifier: device.sharedIdentifier,
						  }))
						: [],
				files: supportImages,
			};

			const validatedData = formSubmissionSchema.parse(submissionData);

			setFormSubmissions((prevSubmissions) => [
				...prevSubmissions,
				validatedData,
			]);
			resetForm();
			setValidationError(null);
		} catch (error) {
			if (error instanceof z.ZodError) {
				console.error("Validation error:", error.errors);
				setValidationError(
					error.errors.map((e) => e.message).join(", ")
				);
			} else {
				console.error("An unexpected error occurred:", error);
				setValidationError("An unexpected error occurred");
			}
		}
	};

	const resetForm = () => {
		reset();
		setSupportImages([]);
		setSelectedDevices([]);
	};

	const submitAllForms = async () => {
		try {
			const currentFormValues = getValues();
			const currentFormSubmission = {
				subject: currentFormValues.subject,
				content: currentFormValues.content,
				cells:
					selectedDevices.length > 0
						? selectedDevices.map((device) => ({
								id: device.id,
								cellName: device.name,
								cellIdentifier: device.sharedIdentifier,
						  }))
						: [],
				files: supportImages,
			};

			const validatedCurrentSubmission = formSubmissionSchema.parse(
				currentFormSubmission
			);

			const allSubmissions = [
				...formSubmissions,
				validatedCurrentSubmission,
			];

			// Send the allSubmissions array to Johnny's backend using a GraphQL mutation

			await submitHubSpotTickets({
				variables: {
					tickets: allSubmissions,
				},
			});

			setResponseModelOpen(true);
			setFormSubmissions([]);
			resetForm();
			setValidationError(null);
		} catch (error) {
			if (error instanceof z.ZodError) {
				console.error("Validation error:", error.errors);
				setValidationError(
					error.errors.map((e) => e.message).join(", ")
				);
			} else {
				console.error("An unexpected error occurred:", error);
				setValidationError("An unexpected error occurred");
			}
		}
	};

	return (
		<form
			onSubmit={handleSubmit(saveValues)}
			className="[&>*]:py-8 divide-y divide-gray-100 dark:divide-gray-200/20"
		>
			{validationError && (
				<div className="mb-4 text-red-500">{validationError}</div>
			)}
			<div className="flex flex-col justify-between sm:flex-row gap-y-2 sm:gap-x-4 lg:gap-x-0">
				<label
					className="block w-full text-sm font-medium leading-8 text-gray-900 sm:w-32 dark:text-gray-200"
					htmlFor="subject"
				>
					Subject{" "}
					<span className="text-red-300 dark:text-red-500">*</span>
				</label>
				<div className="w-full">
					<input
						{...register("subject")}
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
						aria-description="Subject"
						placeholder="Summary of Issue"
					/>
					{errors.subject && (
						<p className="mt-1 text-sm text-red-600">
							{errors.subject.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex flex-col justify-between sm:flex-row gap-y-2 sm:gap-x-4 lg:gap-x-0">
				<label
					className="block w-full text-sm font-medium leading-8 text-gray-900 sm:w-32 dark:text-gray-200"
					htmlFor="description"
				>
					Description{" "}
					<span className="text-red-300 dark:text-red-500">*</span>
				</label>
				<div className="relative w-full">
					<textarea
						{...register("content")}
						rows={5}
						className="resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6 overflow-hidden"
						aria-describedby="Description"
						placeholder="Please describe the issue you are having."
					/>
					<p
						className={`absolute bottom-0 right-0 text-sm select-none  ${
							contentCount > 500
								? "text-red-500"
								: "text-gray-500 dark:text-gray-400"
						} pr-2 pb-1`}
					>
						{contentCount} / 500
					</p>
					{errors.content && (
						<p className="mt-1 text-sm text-red-600">
							{errors.content.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex flex-col justify-between sm:flex-row gap-y-2 sm:gap-x-4 lg:gap-x-0">
				<label
					className="block w-full text-sm font-medium leading-8 text-gray-900 sm:w-32 dark:text-gray-200"
					htmlFor="devices"
				>
					Devices{" "}
				</label>
				<div className="w-full">
					<MultiSelect
						value={selectedDevices}
						onChange={setSelectedDevices}
						itemType="device"
						items={deviceItems}
						menuHeight={"max-h-64"}
					/>
				</div>
			</div>
			<div className="flex flex-col justify-between sm:flex-row gap-y-2 sm:gap-x-4 lg:gap-x-0">
				<label
					className="block w-full text-sm font-medium leading-8 text-gray-900 sm:w-32 dark:text-gray-200"
					htmlFor="supportImages"
				>
					Attachments
				</label>
				<UploadImages
					images={supportImages}
					setImages={setSupportImages}
					className="w-full"
				/>
			</div>
			<div className="w-full pt-8 space-y-3">
				<button
					type="submit"
					className="w-full py-2 font-medium text-gray-800 transition-all duration-300 bg-white border border-gray-200 rounded-md shadow-sm dark:text-white bg-opacity-20 dark:bg-white dark:bg-opacity-10 backdrop-filter backdrop-blur-lg dark:border-white/15 hover:bg-opacity-30 hover:bg-slate-200 dark:hover:bg-opacity-15 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 dark:focus-visible:outline-white/50"
				>
					Add to Submissions
				</button>
				<button
					type="button"
					className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
					onClick={submitAllForms}
				>
					Submit All{" "}
					{totalFormsCount > 0 ? `(${totalFormsCount})` : ""}
				</button>
			</div>
			<ResponseModal
				open={responseModelOpen}
				setOpen={setResponseModelOpen}
				title="Support Ticket Submitted"
				success={true}
				successMsg={"We will get back to you soon!"}
				errorMsg={""}
			/>
		</form>
	);
}
