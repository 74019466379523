import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
	timestampToDate,
	getFullDisplayTimestamp,
} from "../../../utils/timestamps";

type Metadata = {
	eventName: string;
	eventTime: number;
	customerName: string;
	venueName: string;
	timeZoneId: string;
	entitiesInfo: {
		url: string;
		thumbnail: string;
	}[];
};

type ReportCardProps = {
	id: string;
	shortCode: string;
	createdBy: string;
	metadata: Metadata;
	customer: string;
	venue: string;
	passcode: string;
	createdTime: number;
};

export const ReportCard = (props: ReportCardProps) => {
	const [date, display] = timestampToDate(props.metadata.eventTime);
	const reportCreatedTime = getFullDisplayTimestamp(
		props.createdTime,
		props.metadata.timeZoneId
	);
	return (
		<li className="flex justify-between py-5 gap-x-6">
			<div className="flex min-w-0 gap-x-4">
				<img
					src={props.metadata.entitiesInfo?.[0]?.thumbnail}
					alt="Event Venue"
					className="flex-none w-12 h-12 rounded-full bg-gray-50 dark:text-gray-300"
				/>

				<div className="flex-auto min-w-0">
					<p className="mb-2 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
						<Link
							to={`/shared/reports/${props.id}`}
							className="hover:underline"
						>
							{props.metadata.eventName}
						</Link>
					</p>
					<div className="text-xs leading-5 text-gray-500 dark:text-gray-300/80">
						<time dateTime={date}>{display}</time>
					</div>
					<p className="text-xs leading-5 text-gray-500 truncate dark:text-gray-300/80">
						{props.metadata.venueName}
					</p>
					<p className="text-xs leading-5 text-gray-500 dark:text-gray-300/80">
						Generated {reportCreatedTime}
					</p>
				</div>
			</div>
			<div className="flex items-center shrink-0 gap-x-6">
				<Menu as="div" className="relative flex-none">
					{({ open }) => (
						<>
							<Menu.Button
								type="button"
								className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900 dark:hover:text-gray-400"
								id="options-menu-0-button"
								aria-expanded="false"
								aria-haspopup="true"
							>
								<span className="sr-only">Open options</span>
								<svg
									className="w-5 h-5"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
								</svg>
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
								show={open}
							>
								<Menu.Items
									className="absolute right-0 z-10 py-2 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 dark:bg-blue-xonar ring-1 ring-gray-900/5 focus:outline-none"
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="options-menu-0-button"
									tabIndex="-1"
								>
									<Link
										to={`/shared/reports/${props.id}`}
										className="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-300"
										role="menuitem"
										tabIndex="-1"
										id="options-menu-0-item-0"
									>
										View
										<span className="sr-only"> Report</span>
									</Link>
									<Link
										to="#"
										className="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-300"
										role="menuitem"
										tabIndex="-1"
										id="options-menu-0-item-1"
										onClick={async (e) => {
											e.preventDefault();
											await navigator.clipboard.writeText(
												`https://u.xonar.com/${props.shortCode}`
											);
										}}
									>
										Share Link
										<span className="sr-only">
											{" "}
											Report URL
										</span>
									</Link>
									<Link
										to="#"
										className="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-300"
										role="menuitem"
										tabIndex="-1"
										id="options-menu-0-item-1"
										onClick={async (e) => {
											e.preventDefault();
											await navigator.clipboard.writeText(
												props.passcode
											);
										}}
									>
										Share Passcode
										<span className="sr-only">
											{" "}
											Report Passcode
										</span>
									</Link>
								</Menu.Items>
							</Transition>
						</>
					)}
				</Menu>
			</div>
		</li>
	);
};

export default ReportCard;
