import { Button } from "../../../components/Buttons";
import { DELETE_FACES } from "../../../schemas/mutators/People";
import { Modal } from "../../../components/Modals";
import React from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { POLICIES } from "../../../constants/Policies";
import { AccessControlledComponent } from "../../../components/AccessControlledComponent";

interface DeleteImageProps {
  face_id: string;
  refetch: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteImage: React.FC<DeleteImageProps> = ({
  face_id,
  refetch,
  open,
  setOpen,
}) => {
  const { personId } = useParams();
  const [DeleteFaceImage, { loading }] = useMutation(DELETE_FACES);

  const handleDelete = async () => {
    const deletedFace = await DeleteFaceImage({
      variables: {
        id: personId,
        faces: {
          faces: [face_id],
        },
      },
    });
    refetch();
  };

  return (
    <AccessControlledComponent policies={[POLICIES.DELETE_PEOPLE]}>
      <Modal
        open={open}
        setOpen={setOpen}
        title={
          !loading
            ? "Are you sure you want to delete this image?"
            : "Deleting image..."
        }
      >
        <div className="flex mt-6">
          <Button
            type="button"
            className="flex flex-row gap-x-2 last:ml-auto"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="flex flex-row gap-x-2 last:ml-auto"
            onClick={handleDelete}
            onSubmit={handleDelete}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </AccessControlledComponent>
  );
};

export default DeleteImage;
