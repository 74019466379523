import { useAuth0 } from "@auth0/auth0-react";

export const useLogout = () => {
	const { logout } = useAuth0();
	const handleLogout = async (e) => {
		e.preventDefault();
		// clean up session storage
		sessionStorage.clear();
		return await logout({
			logoutParams: { returnTo: window.location.origin },
		});
	};
	return handleLogout;
};
