import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { navigation, NavigationLink } from "../components/Navbar/Navigation";
import { usePortalUserStore } from "../store/PortalUser";

export const useNavigation = () => {
	const location = useLocation();
	const path = location.pathname.toLowerCase();
	const { userPermissions } = usePortalUserStore(
		useShallow((state) => ({
			userPermissions: state.portalUser.permissions,
		}))
	);

	const filterNavigation = (navItems: NavigationLink[]): NavigationLink[] => {
		return navItems.filter((nav) => {
			if (nav.children) {
				const filteredChildren = filterNavigation(nav.children);
				return filteredChildren.length > 0;
			}
			return (
				nav.policies?.every(
					(policy) => userPermissions?.includes(policy) === true
				) ?? true
			);
		});
	};

	const filteredNavigation = useMemo(() => {
		return filterNavigation(navigation);
	}, [userPermissions]);

	const isActive = (route: NavigationLink): boolean => {
		if (route.children) {
			return route.children.some(isActive);
		}
		return (
			path.includes(route.to || "") ||
			(route.to === "/home" && path === "/")
		);
	};

	return { filteredNavigation, isActive };
};
