import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { GET_EVENT_METRICS } from "../../schemas/queries/Events";
import { Link } from "react-router-dom";
import { POLICIES } from "../../constants/Policies";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const EventLocationRow = ({ cellMetrics }) => {
	return (
		<tr className="border-b border-gray-100 dark:border-gray-100/20">
			<td className="py-2 pl-6 pr-2 align-top">
				<Link to={`/devices/${cellMetrics.cell}`}>
					<div className="max-w-[7rem] font-medium text-gray-900 dark:text-gray-200 hover:underline">
						{cellMetrics?.customerDeviceName
							? cellMetrics.customerDeviceName
							: cellMetrics.cellName}
					</div>
				</Link>
			</td>
			<td className="hidden py-2 pl-2 pr-1 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cellMetrics.arrivals?.toLocaleString()}
			</td>
			<td className="hidden py-2 pl-2 pr-0 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cellMetrics.alerts?.toLocaleString()}
			</td>
			<td className="hidden py-2 pl-2 pr-0 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cellMetrics.threats?.toLocaleString()}
			</td>
			<AccessControlledComponent policies={[POLICIES.READ_PEOPLE]}>
				<td className="hidden py-2 pl-2 pr-2 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
					{cellMetrics.recognized?.toLocaleString()}
				</td>
			</AccessControlledComponent>
		</tr>
	);
};

export const EventLocation = ({ eventData, venuePhotos }) => {
	if (!eventData) {
		return <></>;
	}
	const [initialLoading, setInitialLoading] = useState(true);

	const { data } = useQuery(GET_EVENT_METRICS, {
		variables: {
			id: eventData.id,
		},
		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		onError: (error) => {
			console.error(error);
		},
		onCompleted: (data) => {
			setInitialLoading(false);
		},
	});

	// const devicePlanImageUrl = venuePhotos?.filter((p) => p.deviceLocationPlan)[0]
	//   ?.url;

	return (
		<div className="mt-4 overflow-hidden border border-gray-200 rounded-xl dark:border-gray-200/20">
			{/* <div className="m-8">
        <img
          className="rounded-md dark:text-gray-400"
          src={devicePlanImageUrl}
          alt={eventData?.venueName}
        />
      </div> */}

			{initialLoading ? (
				<div className="flex items-center w-full pt-8 pb-12 mx-auto">
					<div
						className={`mx-auto h-12 w-12 inline-block animate-spin rounded-full border-[2px] border-solid border-current border-r-transparent align-[-0.125em] text-primary dark:text-gray-200 motion-reduce:animate-[spin_1.5s_linear_infinite]`}
						role="status"
					>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			) : (
				<table className="w-full mt-8 text-xs leading-6 text-left whitespace-nowrap">
					<thead className="text-gray-900 border-b border-gray-200 dark:border-gray-200/20 dark:text-gray-200">
						<tr>
							<th scope="col" className="px-6 py-0 font-semibold">
								Location
							</th>
							<th
								scope="col"
								className="hidden py-2 pl-2 pr-1 font-semibold text-left sm:table-cell"
							>
								Arrivals
							</th>
							<th
								scope="col"
								className="hidden py-2 pl-1 pr-0 font-semibold text-left sm:table-cell"
							>
								Alerts
							</th>
							<th
								scope="col"
								className="hidden py-2 pl-1 pr-0 font-semibold text-left sm:table-cell"
							>
								Threats
							</th>
							<AccessControlledComponent
								policies={[POLICIES.READ_PEOPLE]}
							>
								<th
									scope="col"
									className="hidden py-2 pl-2 pr-1.5 font-semibold text-left sm:table-cell"
								>
									Recognized
								</th>
							</AccessControlledComponent>
						</tr>
					</thead>
					<tbody>
						{data?.eventMetrics.length > 0 ? (
							data?.eventMetrics.map((metric) => {
								return (
									<EventLocationRow
										key={`${metric.cell}-metrics`}
										cellMetrics={metric}
									/>
								);
							})
						) : (
							<tr className="border-b border-gray-100 dark:border-gray-100/20">
								<td className="px-6 py-2 align-top max-w-0">
									<div className="font-medium text-gray-900 dark:text-gray-200">
										No Devices Found.
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default EventLocation;
