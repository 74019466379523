const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const timestampToDate = (timestamp) => {
	const dateObj = new Date(timestamp);
	const year = dateObj.getUTCFullYear();
	const monthIndex = dateObj.getUTCMonth();
	const month = months[monthIndex];
	const date = dateObj.getUTCDate();
	const displayDateString = `${month} ${date}, ${year}`;
	const dateString = `${year}-${monthIndex + 1}-${date}`;
	return [dateString, displayDateString];
};

export const timestampToRecentString = (timestamp) => {
	const dateObj = new Date(timestamp);
	const year = dateObj.getUTCFullYear();
	const monthIndex = dateObj.getUTCMonth();
	const date = dateObj.getUTCDate();
	// const displayDateString = `${month} ${date}, ${year}`;
	const dateString = `${year}-${monthIndex + 1}-${date}`;
	// To calculate the time difference of now and timestamp
	const timeDifference = new Date().getTime() - dateObj.getTime();
	// TODO: math out months, years, etc.
	const timeDifferenceInDays = Math.round(
		timeDifference / (1000 * 3600 * 24)
	);
	const timeDifferenceString = `${timeDifferenceInDays}d`;
	return [dateString, timeDifferenceString];
};

export const getTimestampByDays = (days) => {
	const timestamp = new Date(
		new Date().getTime() - days * 24 * 60 * 60 * 1000
	).getTime();
	return timestamp;
};

export const getTimestampNow = () => {
	const timestamp = new Date().getTime();
	return timestamp;
};

export const getTimestampYesterday = () => {
	return getTimestampByDays(1);
};

export const getTimestampLastWeek = () => {
	return getTimestampByDays(7);
};

export const getTimestampLastMonth = () => {
	return getTimestampByDays(30);
};

export const getToday = () => {
	return [getTimestampYesterday(), getTimestampNow()];
};

export const getLastWeek = () => {
	return [getTimestampLastWeek(), getTimestampNow()];
};

export const getLastMonth = () => {
	return [getTimestampLastMonth(), getTimestampNow()];
};

export const getAllTime = () => {
	return [0, getTimestampNow()];
};

export const timeframes = {
	0: getToday, // today's events
	7: getLastWeek, // current week's events
	30: getLastMonth, // current month's events
	"-1": getAllTime, // all time events
};

export const getPreviousTimeframe = (daysToStart, daysToEnd) => {
	return [getTimestampByDays(daysToStart), getTimestampByDays(daysToEnd)];
};

// time frame for yesterday
export const getPreviousDay = () => getPreviousTimeframe(2, 1);
// time frame for last week
export const getPreviousWeek = () => getPreviousTimeframe(14, 7);
// time frame for last month
export const getPreviousMonth = () => getPreviousTimeframe(60, 30);

// TODO maybe need timeframe for a year

export const previousTimeframes = {
	0: getPreviousDay,
	7: getPreviousWeek,
	30: getPreviousMonth,
	"-1": getAllTime,
};

export const getHour = (timestamp, timeZoneId) => {
	const hour = new Date(timestamp)
		.toLocaleString("en-US", {
			timeZone: timeZoneId,
			hour: "numeric",
		})
		.split(/[ ]+/);
	const formattedHour = `${hour[0]}${hour[1].toLowerCase()}`;
	return formattedHour;
};

export const getTime = (timestamp, timeZoneId) => {
	const hour = new Date(timestamp)
		.toLocaleString("en-US", {
			timeZone: timeZoneId,
			hour: "numeric",
			minute: "numeric",
		})
		.split(/[ ]+/);
	const formattedHour = `${hour[0]} ${hour[1].toLowerCase()}`;
	return formattedHour;
};

export const getHourRange = (startTimestamp, endTimestamp, timeZoneId) => {
	const start = getHour(startTimestamp, timeZoneId);
	const end = getHour(endTimestamp, timeZoneId);
	return `${start} - ${end}`;
};

export const getFullTime = (
	timestamp,
	locale = "en-US",
	timeZone = "America/New_York"
) => {
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		weekday: "long",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		timeZone,
	};
	const time = new Date(timestamp).toLocaleString(locale, options);
	return time;
};

export const isToday = (timestamp) => {
	const today = new Date();
	const time = new Date(timestamp);

	const sameDate = today.getUTCDate() === time.getUTCDate();
	const sameMonth = today.getUTCMonth() === time.getUTCMonth();
	const sameYear = today.getUTCFullYear() === time.getUTCFullYear();

	return sameDate && sameMonth && sameYear;
};

export const getDayStartDate = (date) => {
	const dayStartDate = new Date(date);
	dayStartDate.setHours(0, 0, 0, 0);
	return new Date(dayStartDate);
};

export const getFullDisplayTimestamp = (timestamp, timeZoneId) => {
	return getFullTime(timestamp, undefined, timeZoneId);
};
