import { ADD_FACES, UPDATE_MAIN_FACE } from "../../../schemas/mutators/People";
import { Menu, Transition } from "@headlessui/react";
import React, { useState } from "react";

import { DeleteImage } from "./DeleteImage";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { PersonFaceImages } from ".";
import { Tag } from "../../../components/Tags";
import { UploadFaces } from "../AddPerson";
import { classNames } from "../../../utils/ux";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { POLICIES } from "../../../constants/Policies";
import { AccessControlledComponent } from "../../../components/AccessControlledComponent";

interface EditImagesProps {
  images: PersonFaceImages[];
  mainFace: string;
  refetch: () => void;
  loadingExistingFaces: boolean;
}

interface ExistingImageProps extends PersonFaceImages {
  isMainFace: boolean;
  refetch: () => void;
  faceNumber: number;
}

const ExistingImage: React.FC<ExistingImageProps> = ({
  id,
  url,
  isMainFace,
  refetch,
  faceNumber,
}) => {
  const { personId } = useParams();
  const [open, setOpen] = useState(false);

  const [UpdateMainFace, updateMainFaceMutation] =
    useMutation(UPDATE_MAIN_FACE);

  const setPrimary = async () => {
    const updatedPerson = await UpdateMainFace({
      variables: {
        id: personId,
        face: id,
      },
    });
    refetch();
  };

  // TODO: use DeleteFace mutation after implementing Mahavir Delete Face endpoint into portal backend
  const removeImage = async () => {
    refetch();
  };

  return (
    <li
      className="flex flex-col items-center pb-2"
      data-testid={`face-${faceNumber}`}
    >
      <div className="w-full">
        {!isMainFace && (
          <>
            <Menu
              as="div"
              className="relative z-10 text-gray-800 inset-x-[11.3rem] inset-y-1 h-0"
            >
              <Menu.Button>
                {({ open }) =>
                  open ? (
                    // x icon
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-800/10 hover:text-blue-xonar-secondary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  ) : (
                    // cog icon
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-gray-800/10 hover:text-blue-xonar-secondary"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )
                }
              </Menu.Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute w-[5.4rem] flex flex-col origin-top-right bg-white dark:bg-blue-xonar divide-y rounded-md shadow-lg -left-16 divide-blue-xonar-secondary ring-1 ring-black/5 focus:outline-none py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? "bg-gray-100 dark:bg-indigo-vite" : "",
                          "block py-2 text-xs font-medium text-gray-700 dark:text-gray-200 dark:hover:text-gray-300"
                        )}
                        onClick={setPrimary}
                      >
                        Set Primary
                      </button>
                    )}
                  </Menu.Item>
                  <AccessControlledComponent
                    policies={[POLICIES.DELETE_PEOPLE]}
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active ? "bg-gray-100 dark:bg-indigo-vite" : "",
                            "block py-2 text-xs font-medium text-gray-700 dark:text-gray-200 dark:hover:text-gray-300"
                          )}
                          onClick={() => setOpen(true)}
                        >
                          Remove
                        </button>
                      )}
                    </Menu.Item>
                  </AccessControlledComponent>
                </Menu.Items>
              </Transition>
            </Menu>
            <DeleteImage
              face_id={id}
              refetch={refetch}
              open={open}
              setOpen={setOpen}
            />
          </>
        )}

        <img src={url} alt={id} className="mb-4 rounded-md w-52 h-52" />
      </div>
      {isMainFace && <Tag color="gray" tag="Primary" />}
    </li>
  );
};

export const EditImages: React.FC<EditImagesProps> = ({
  images,
  mainFace,
  refetch,
  loadingExistingFaces,
}) => {
  // TODO: Delete existing images -> just pop out of images
  const { personId } = useParams();
  const [newImages, setNewImages] = useState<File[]>([]);

  const [AddFaces, addFacesMutation] = useMutation(ADD_FACES);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    await AddFaces({
      variables: {
        id: personId,
        faces: {
          faces: newImages,
        },
      },
    });
    refetch();
    setNewImages([]);
  };

  return (
    <section className="flex flex-col divide-y divide-gray-100 dark:text-gray-200 dark:divide-gray-200/20">
      <div className="flex flex-col">
        <h2 className="pb-2">Face Images</h2>
        <ul
          className="flex flex-row flex-wrap w-full pt-1 pb-2 mx-auto gap-x-4"
          data-testid="existingImageList"
        >
          {loadingExistingFaces ? (
            <LoadingSpinner h={24} w={24} className="w-24 h-24 mx-auto" />
          ) : (
            images.map((face, index) => {
              return (
                <ExistingImage
                  id={face.id}
                  url={face.url}
                  isMainFace={face.id === mainFace}
                  refetch={refetch}
                  faceNumber={index}
                />
              );
            })
          )}
        </ul>
      </div>

      <form onSubmit={onSubmit} className="py-8">
        <h2 className="pb-4">Add Images</h2>
        <UploadFaces
          faces={newImages}
          setFaces={setNewImages}
          className="w-full"
        />
        {newImages.length > 0 && (
          <div className="flex w-full">
            <div className="flex w-full">
              <button
                type="submit"
                className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
              >
                {addFacesMutation.loading ? (
                  <LoadingSpinner />
                ) : (
                  "Upload Images"
                )}
              </button>
            </div>
          </div>
        )}
      </form>
    </section>
  );
};

export default EditImages;
