import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { Placeholder } from "../../components/Avatars/Placeholder";
import { DarkModeToggle } from "../../components/DarkModeToggle";
import { useRole } from "../../hooks/auth";
import { useLogout } from "../../hooks/logout";
import { usePortalUserStore } from "../../store/PortalUser";
import { classNames } from "../../utils/ux";
import { AdminMenu } from "./AdminMenu";

export const ProfileMenu = () => {
	const logout = useLogout();
	const { portalUser } = usePortalUserStore(useShallow((state) => state));

	// @ts-ignore
	const { isAdmin } = useRole(portalUser);
	return (
		<Menu as="div" className="relative ml-3 select-none">
			<div>
				<Menu.Button className="relative flex text-sm border-0 rounded-full outline-none focus:outline-none focus:border-0 hover:outline-none hover:border-0">
					<span className="sr-only">Open user menu</span>
					{portalUser?.name ? (
						<img
							className="w-8 h-8 rounded-full "
							src={`https://ui-avatars.com/api/?name=${portalUser?.name}`}
							alt=""
						/>
					) : (
						<Placeholder />
					)}
				</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-blue-xonar ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-white/10">
					<Menu.Item>
						{({ active }) => (
							<Link
								to="/profile"
								className={classNames(
									active
										? "bg-gray-100 dark:bg-indigo-vite"
										: "",
									"block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 dark:hover:text-gray-300"
								)}
							>
								Your Profile
							</Link>
						)}
					</Menu.Item>
					<Menu.Item>
						{({ active }) => (
							<Link
								to="/shared/reports"
								className={classNames(
									active
										? "bg-gray-100 dark:bg-indigo-vite"
										: "",
									"block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 dark:hover:text-gray-300"
								)}
							>
								Your Reports
							</Link>
						)}
					</Menu.Item>
					<Menu.Item>
						{({ active }) => (
							<div
								className={classNames(
									active
										? "bg-gray-100 dark:bg-indigo-vite"
										: "",
									"flex px-4 py-2 text-sm text-gray-700 hover:text-indigo-600 dark:text-gray-200 dark:hover:text-gray-300 font-medium"
								)}
							>
								<p className="">Dark Mode</p>
								{/* <div className="last:ml-auto"> */}
								<DarkModeToggle />
								{/* </div> */}
							</div>
						)}
					</Menu.Item>

					<Menu.Item>
						{({ active }) => (
							<Link
								to=""
								className={classNames(
									active
										? "bg-gray-100 dark:bg-indigo-vite"
										: "",
									"block px-4 py-2 text-sm text-gray-700 hover:text-indigo-600 dark:text-gray-200 dark:hover:text-gray-300"
								)}
								onClick={logout}
							>
								{portalUser?.id ? "Sign Out" : "Sign In"}
							</Link>
						)}
					</Menu.Item>
					{isAdmin ? <AdminMenu /> : <></>}
				</Menu.Items>
			</Transition>
		</Menu>
	);
};
