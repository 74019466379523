import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext(false);

const ThemeProvider = ({ children }) => {
  // USE THIS FOR DEFAULT TO SYSTEM PREFERENCE
  // const [enabled, setEnabled] = useState(
  //   localStorage?.theme === "light "
  //   // localStorage?.theme === "dark" ||
  //   //   (!("theme" in localStorage) &&
  //   //     window.matchMedia("(prefers-color-scheme: dark)").matches)
  // );
  // USE THIS FOR DARK MODE DEFAULT
  const [enabled, setEnabled] = useState(
    !("theme" in localStorage) || localStorage?.theme === "dark"
  );
  useEffect(() => {
    const html = document.querySelector("html");
    switch (enabled) {
      case false:
        localStorage.theme = "light";
        document.body.classList.remove("dark");
        document.body.classList.remove("bg-blue-xonar");
        html.classList.remove("dark");
        html.classList.remove("bg-blue-xonar");
        html.classList.add("bg-white");
        break;
      case true:
        localStorage.theme = "dark";
        document.body.classList.add("dark");
        document.body.classList.add("bg-blue-xonar");
        html.classList.remove("bg-white");
        html.classList.add("dark");
        html.classList.add("bg-blue-xonar");
        break;
    }
  }, [enabled]);
  return (
    <ThemeContext.Provider value={{ enabled, update: setEnabled }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
