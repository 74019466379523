import { createContext, useEffect, useState } from "react";

export const TimeContext = createContext(null);

export const TimeProvider = ({ children }) => {
  // TODAY == 0
  // WEEK == 7
  // MONTH == 30
  // ALL TIME == -1
  const [timeframe, setTimeframe] = useState(0);

  useEffect(() => {
    // only check  localStorage on mount
    if ("timeframe" in localStorage) {
      const cached = parseInt(localStorage.timeframe);
      setTimeframe(cached);
    }
  }, []);

  const updateTimeframe = (value) => {
    setTimeframe(value);
    localStorage.timeframe = value;
  };

  return (
    <TimeContext.Provider value={{ timeframe, setTimeframe: updateTimeframe }}>
      {children}
    </TimeContext.Provider>
  );
};

export default TimeProvider;
