export enum Color {
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
  WHITE = "WHITE",
  BLUE = "BLUE",
}

export type ColorType = "GREEN" | "RED";

export const TAG_COLORS = {
  blue: "text-blue-700 bg-blue-50 ring-blue-600/10 dark:text-blue-300 dark:bg-blue-500/50",
  red: "text-red-700 bg-red-50 ring-red-600/10 dark:text-red-300 dark:bg-red-500/50",
  green:
    "text-green-700 bg-green-50 ring-green-600/10 dark:text-green-300 dark:bg-green-500/50",
};

export const FEED_STAGE_COLORS = {
  gray: "bg-gray-200 dark:bg-gray-500 ring-gray-400 dark:ring-gray-800",
  green: "bg-green-200 dark:bg-green-500 ring-green-400 dark:ring-green-700",
  blue: "bg-blue-200 dark:bg-blue-500 ring-blue-400 dark:ring-blue-700",
  red: "bg-red-200 dark:bg-red-500 ring-red-400 dark:ring-red-700",
};
