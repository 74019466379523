import { gql } from "@apollo/client";

export const GET_ENTITY_PHOTOS = gql`
  query GET_ENTITY_PHOTOS($entityId: String!, $includeThumbnail: Boolean!) {
    entityPhotos(entityId: $entityId) {
      url @skip(if: $includeThumbnail)
      thumbnail @include(if: $includeThumbnail)
    }
  }
`;
