import React from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "../../../utils/ux";

interface Props {
  children: any;
}

export const EditPanel: React.FC<Props> = ({ children }) => {
  return (
    <Tab.Panel
      className={classNames(
        "bg-white dark:bg-blue-xonar-secondary/60 py-6 px-4",
        "rounded-b-xl"
      )}
    >
      {children}
    </Tab.Panel>
  );
};

export default EditPanel;
