import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { Button } from "../../components/Buttons";
import { Link } from "react-router-dom";
import { POLICIES } from "../../constants/Policies";
import PeopleTable from "./PeopleTable";

export const People = () => {
  return (
    <main>
      <div className="py-8 space-y-16 xl:space-y-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 mt-16 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
                  People
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-gray-300/80">
                  A list of all the persons of interest including their
                  categories.
                </p>
              </div>
              <AccessControlledComponent policies={[POLICIES.EDIT_PEOPLE]}>
                <div className="flex flex-row mt-4 gap-x-4 sm:ml-16 sm:mt-0">
                  <Link to="/people/add">
                    <Button
                      type="button"
                      className="block px-1 text-sm font-semibold text-center"
                    >
                      Add Person
                    </Button>
                  </Link>
                  <Link to="/people/add/batch">
                    <Button
                      type="button"
                      className="block px-1 text-sm font-semibold text-center"
                    >
                      Upload People
                    </Button>
                  </Link>
                </div>
              </AccessControlledComponent>
            </div>
            <PeopleTable />
          </div>
        </div>
      </div>
    </main>
  );
};

export default People;
