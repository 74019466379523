import React, { useEffect, useState } from "react";

import { Transition } from "@headlessui/react";

interface FadeInProps {
  delay: string; // delay must be in the form 'delay-[TIME_IN_MS]'
  children: any;
  show: boolean;
}

export const FadeIn: React.FC<FadeInProps> = ({ delay, children, show }) => {
  const [showTransition, setShowTransition] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowTransition(show);
      }, 500);
    } else {
      setShowTransition(show);
    }
  }, [show]);
  return (
    <Transition
      enter={`transition-all ease-in-out duration-700 ${delay}`}
      enterFrom="opacity-0 translate-y-6"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={showTransition}
    >
      {children}
    </Transition>
  );
};

export default FadeIn;
