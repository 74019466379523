import { useTokenStore } from "./Token";
import { usePortalUserStore } from "./PortalUser";
import { Walk } from "../schemas/subscriptions/Walks";
import { io, Socket } from "socket.io-client";
import { create } from "zustand";
import { GRAPHQL_HOST, wsPrefix } from "../constants";

interface CellWalksState {
	socket?: Socket;
	walks: Walk[];
	setWalks: (walks: Walk[]) => void;
	updateSocket: (deviceId?: string) => void;
	disconnectSocket: () => void;
	clearWalks: () => void;
}

export const useCellWalksStore = create<CellWalksState>()((set, get) => ({
	walks: [],
	updateSocket: (deviceId?: string) => {
		if (deviceId) {
			const socket = io(`${wsPrefix}://${GRAPHQL_HOST}`, {
				path: "/ws/socket.io/",
				transports: ["websocket", "polling"],
				auth: {
					token: useTokenStore.getState().token,
					name: usePortalUserStore.getState().portalUser.emailAddress,
				},
			});
			socket.on("connect", () => {
				socket.emit("join_walks", deviceId);
			});
			socket.on("walk", (data) => {
				const walk = data.walk;
				// TODO: insert/update walk in correct place
				set((state) => {
					const prevWalks = get().walks;
					const existingWalkIndex = prevWalks.findIndex(
						(w) => w.guid === walk.guid
					);
					if (existingWalkIndex !== -1) {
						// update walk if it got updated
						// @ts-ignore
						const updatedWalks = prevWalks.toSpliced(
							existingWalkIndex,
							1,
							walk
						);
						return { walks: updatedWalks };
					} else {
						// append new walks
						return { walks: [...state.walks, walk] };
					}
				});
			});
			socket.on("disconnect", () => {
				console.log("Socket connection disconnected.");
			});
			set({ socket });
		}
	},
	disconnectSocket: () => {
		get().socket?.close();
	},
	setWalks: (walks: Walk[]) => {
		set({ walks });
	},
	clearWalks: () => {
		set({ walks: [] });
	},
}));
