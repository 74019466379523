import { gql } from "@apollo/client";

export const EDIT_INFERENCE = gql`
	mutation EditInference($ids: [String!]!, $update: InferenceInput!) {
		editInference(ids: $ids, update: $update) {
			status
			eventType
			cells
		}
	}
`;

export const EDIT_CELL_MODE = gql`
	mutation EditCellMode($ids: [String!]!, $update: CellModeInput!) {
		editCellMode(ids: $ids, update: $update) {
			status
			eventType
			cells
		}
	}
`;

export const EDIT_SOFTWARE = gql`
	mutation EditSoftware($ids: [String!]!, $update: SoftwarePackageInput!) {
		editSoftware(ids: $ids, update: $update) {
			status
			eventType
			cells
		}
	}
`;

export const EDIT_PASSCODE = gql`
	mutation EditPasscode($ids: [String!]!, $update: PassCodeInput!) {
		editPasscode(ids: $ids, update: $update) {
			status
			eventType
			cells
		}
	}
`;

export const PULL_CONFIG = gql`
	mutation PullConfig($ids: [String!]!) {
		pullConfig(ids: $ids) {
			status
			eventType
			cells
		}
	}
`;

export const EDIT_CUSTOMER_FACING_NAME = gql`
	mutation EditCustomerFacingName($update: DeviceCustomerNameInputType!) {
		editCustomerFacingName(update: $update) {
			id
			customerDeviceName
		}
	}
`;
