import { ColorType } from "../../constants/Colors";
import React from "react";
import { PeopleCategory } from "./PeopleTableRow";
import { Tag } from "../../components/Tags";

export type BatchPerson = {
  name: string;
  category: string;
  color: ColorType;
  customer?: string;
  alert: boolean;
  venues: string[];
  email?: string;
  phone?: string;
  about?: string;
  imageLink: string;
};

interface BatchPreviewRowProps {
  person: BatchPerson;
}

export const BatchPreviewRow: React.FC<BatchPreviewRowProps> = ({ person }) => {
  return (
    <tr>
      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-gray-200 sm:pl-6">
        {person.name}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap dark:text-gray-300">
        <PeopleCategory color={person.color} label={person.category} />
      </td>
      <td className="px-3 py-4 overflow-auto text-sm text-gray-500 rounded-sm max-w-40 gap-x-2 whitespace-nowrap dark:text-gray-300">
        <div className="flex gap-x-2">
          {person.venues.map((venue, i) => {
            return (
              <Tag
                color={"gray"}
                tag={venue}
                key={`venue-${person.name}-${person.category}-${i}`}
              />
            );
          })}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap dark:text-gray-300">
        {person.alert ? "Yes" : "No"}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap dark:text-gray-300">
        {person.email}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap dark:text-gray-300">
        {person.phone}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        <div className="overflow-y-auto whitespace-pre-wrap max-h-20">
          {person.about}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 whitespace-pre-wrap dark:text-gray-300">
        <img src={person.imageLink} alt={person.name} />
      </td>
    </tr>
  );
};

interface BatchPreviewProps {
  batch: BatchPerson[];
}

export const BatchPreview: React.FC<BatchPreviewProps> = ({ batch }) => {
  return (
    <div className="max-h-[75vh] overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-300/20">
        <thead className="bg-gray-50 dark:bg-gray-900">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 sm:pl-6 sm:rounded-lg"
            >
              Full Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Venues
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Alert
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Phone
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              About
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Image
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-200/20 dark:bg-gray-900/80">
          {batch.map((person) => {
            return (
              <BatchPreviewRow
                person={person}
                key={`${person.name}-${person.category}-row`}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BatchPreview;
