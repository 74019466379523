import { gql } from "@apollo/client";

export const ADD_PERSON = gql`
	mutation AddPerson($person: AddPersonInputType!) @isUpload {
		addPerson(person: $person) {
			id
			name
		}
	}
`;

// not used
export const ADD_PEOPLE = gql`
	mutation AddPeople($people: AddPeopleInputType!) @isUpload {
		addPeople(people: $people) {
			id
			name
		}
	}
`;

export const ADD_FACES = gql`
	mutation AddFaces($id: String!, $faces: AddFacesInputType!) @isUpload {
		addFaces(id: $id, faces: $faces) {
			id
			name
			faces
		}
	}
`;

export const UPDATE_PERSON = gql`
	mutation UpdatePerson($id: String!, $person: UpdatePersonInputType!) {
		updatePerson(id: $id, person: $person) {
			id
			name
		}
	}
`;

export const UPDATE_CATEGORY = gql`
	mutation UpdateCategory($id: String!, $category: UpdateCategoryInputType!) {
		updateCategory(id: $id, category: $category) {
			id
			name
		}
	}
`;

export const UPDATE_MAIN_FACE = gql`
	mutation UpdateMainFace($id: String!, $face: String!) {
		updateMainFace(id: $id, face: $face) {
			id
			name
			mainFace
		}
	}
`;

export const DELETE_PERSON = gql`
	mutation DeletePerson($id: String!) {
		deletePerson(id: $id) {
			id
			name
		}
	}
`;

export const DELETE_FACES = gql`
	mutation DeleteFaces($id: String!, $faces: DeleteFacesInputType!) {
		deleteFaces(id: $id, faces: $faces) {
			id
		}
	}
`;

export const UPLOAD_FACES = gql`
	mutation UploadFaces($faces: [Upload!]!, $name: String!) @isUpload {
		uploadFaces(faces: $faces, name: $name)
	}
`;

export const ADD_PERSON_IN_BACKGROUND = gql`
	mutation AddPersonInBackground(
		$person: AddPersonInputType!
		$uploadId: String!
	) {
		addPersonInBackground(person: $person, uploadId: $uploadId) {
			message
			name
		}
	}
`;
