import { TAG_COLORS } from "../../constants/Colors";
import { classNames } from "../../utils/ux";

const DetailsOverviewTermTag = ({ color, value, className = "" }) => {
	return (
		<div
			className={classNames(
				"px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset",
				TAG_COLORS[color],
				className
			)}
		>
			{value}
		</div>
	);
};

export const DetailsOverviewTermTagged = ({ term, description, tags }) => {
	return (
		<div className="flex justify-between py-3 gap-x-4">
			<dt className="text-gray-500 dark:text-gray-200">{term}</dt>
			<dd className="flex items-start gap-x-2">
				<div className="font-medium text-gray-900 dark:text-gray-300">
					{description}
				</div>
				{tags.map(({ color, value, className }, i) => {
					return (
						<DetailsOverviewTermTag
							key={`${term}-tag-${i}`}
							color={color}
							value={value}
							className={className}
						/>
					);
				})}
			</dd>
		</div>
	);
};

export const DetailsOverviewTerm = ({ term, description }) => {
	return (
		<div className="flex justify-between py-3 gap-x-4">
			<dt className="text-gray-500 dark:text-gray-200">{term}</dt>
			<dd className="font-medium text-gray-900 dark:text-gray-300">
				{description}
			</dd>
		</div>
	);
};

export const DetailsOverview = ({ children, className = "" }) => {
	return (
		<div
			className={classNames(
				"overflow-hidden border border-gray-200 rounded-xl dark:border-gray-200/20",
				className
			)}
		>
			<dl className="px-6 py-4 -my-3 text-sm leading-6 divide-y divide-gray-100 dark:divide-gray-200/20">
				{children}
			</dl>
		</div>
	);
};

export default DetailsOverview;
