import { create } from "zustand";
import { Report } from "./typing";
import axios from "axios";
import { GRAPHQL_HOST, DEV_ENV } from "../../constants";

type FetchReportParams = {
	id: string;
	portalUser?: string;
	passcode?: string;
};

interface ReportState {
	report?: Report;
	getReport: (params: FetchReportParams) => void;
	authorized: boolean;
	firstLoad: boolean;
	viewReport: boolean;
	validPasscode: boolean;
	reportId: string;
	userOnly: boolean;
	setUserOnly: (userOnly: boolean) => void;
	venues: any[];
	setVenues: (venues: any[]) => void;
}

export const useReportStore = create<ReportState>()((set, get) => ({
	firstLoad: true,
	authorized: false,
	report: undefined,
	viewReport: false,
	validPasscode: true,
	reportId: "",
	userOnly: false,
	venues: [],
	setUserOnly: (userOnly: boolean) => {
		set({ userOnly });
	},
	setVenues: (venues: any[]) => {
		set({ venues });
	},
	getReport: async (params: FetchReportParams) => {
		const body = {
			portalUser: params.portalUser,
			passcode: params.passcode,
		};
		if (params.id !== get().reportId) {
			set({
				firstLoad: true,
				authorized: false,
				report: undefined,
				viewReport: false,
				validPasscode: true,
				reportId: params.id,
			});
		}
		try {
			const urlPrefix = DEV_ENV ? "http" : "https";
			const { data, status } = await axios.post(
				`${urlPrefix}://${GRAPHQL_HOST}/reports/${params.id}`,
				body
			);
			set({
				report: data,
				authorized: status === 200,
				firstLoad: false,
				viewReport: true,
				validPasscode: true,
				reportId: params.id,
			});
		} catch (error) {
			set({
				authorized: false,
				firstLoad: false,
				validPasscode: false,
				reportId: params.id,
			});
		}
	},
}));
