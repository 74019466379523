import { createJSONStorage, persist } from "zustand/middleware";

import { GET_CUSTOMERS } from "../schemas/queries/Customers";
import { create } from "zustand";
import { useApolloClient } from "@apollo/client";

export interface Customer {
  id?: string;
  customerName?: string;
  shortCode?: string;
  name?: string;
}

interface CustomerState {
  customer: Customer;
  customerFilter: (string | undefined)[];
  setCustomer: (customer: Customer) => void;
  clearCustomer: () => void;
  customers: Customer[];
  customersFetched: boolean;
  fetchCustomers: () => void;
}

export const useCustomerStore = create<CustomerState>()(
  persist(
    (set, get) => ({
      customer: {},
      customerFilter: [],
      setCustomer: (customer: Customer) => {
        if (!customer.id) {
          set((state) => ({ customer: {}, customerFilter: [] }));
        } else {
          set((state) => ({
            customer: customer,
            customerFilter: [customer?.id],
          }));
        }
      },
      clearCustomer: () => {
        set((state) => ({ customer: {}, customerFilter: [] }));
      },
      customers: [],
      customersFetched: false,
      fetchCustomers: async () => {
        const client = useApolloClient();
        try {
          const { data, error } = await client.query({
            query: GET_CUSTOMERS,
          });
          if (error) {
            console.error(error);
          }
          set((state) => ({
            customers: data.customers.map((customer) => ({
              ...customer,
              name: customer.customerName,
            })),
            customersFetched: true,
          }));
        } catch (error) {
          console.log(
            `${error.message} ... Fetching auth token from browser...`
          );
        }
      },
    }),
    {
      name: "customerStore",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
