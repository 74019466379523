import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { Button } from "../../components/Buttons";
import { DELETE_PERSON } from "../../schemas/mutators/People";
import { Modal } from "../../components/Modals";
import { POLICIES } from "../../constants/Policies";
import { useMutation } from "@apollo/client";

interface Props {
  personId: string;
  name: string;
  className?: string;
}

export const DeletePerson: React.FC<Props> = ({
  personId,
  name,
  className = "",
}) => {
  const [title, setTitle] = useState(
    `Are you sure you want to delete ${name}?`
  );
  const [deleted, setDeleted] = useState(false);
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deletePerson, { loading, error }] = useMutation(DELETE_PERSON, {
    variables: {
      id: personId,
    },
  });
  const openModal = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleDelete = async (e: any) => {
    e.preventDefault();
    const deletedPerson = await deletePerson();
    setTitle(`Deleted ${deletedPerson.data.deletePerson.name}`);
    setDeleted(true);
    setTimeout(() => {
      navigate("/people");
    }, 1500);
    // if (path.includes("/edit")) {
    //   navigate("/people");
    // } else {
    //   setOpen(false);
    // }
  };

  return (
    <>
      <AccessControlledComponent policies={[POLICIES.DELETE_PEOPLE]}>
        <a
          href=""
          className={
            className
              ? className
              : "block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-500"
          }
          onClick={(e) => openModal(e)}
          role={`Delete ${name}`}
        >
          Delete<span className="sr-only"> {name}</span>
        </a>
        <Modal open={open} setOpen={setOpen} title={title}>
          <div className="flex flex-col my-4 space-y-4">
            {deleted ? (
              <p className="text-gray-800 dark:text-gray-400">
                Returning to people table...
              </p>
            ) : (
              <div className="flex justify-between">
                <Button
                  type="button"
                  className="flex flex-row gap-x-2"
                  onClick={(e) => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="flex flex-row gap-x-2"
                  loading={loading}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </AccessControlledComponent>
    </>
  );
};

export default DeletePerson;
