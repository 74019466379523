import React, { useRef, useState } from "react";

import { classNames } from "../../utils/ux";

interface UploadImagesProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  accept?: string;
  fileType?: string;
  multiple?: boolean;
}

export const FileUpload: React.FC<UploadImagesProps> = ({
  files,
  setFiles,
  accept,
  fileType = "File",
  multiple = false,
}) => {
  const inputRef = useRef<any>(null);
  const [dragActive, setDragActive] = useState(false);

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFiles([...files, ...e.target.files]);
    }
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFiles([...files, ...e.dataTransfer.files]);
    }
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const openFileExplorer = () => {
    inputRef.current.value = "";
    inputRef.current.click();
  };
  return (
    <div
      className={classNames(
        dragActive
          ? "bg-gray-100 dark:bg-blue-950/30"
          : "bg-gray-50/50 dark:bg-blue-950/15",
        "p-4 rounded-2xl min-h-[10rem] text-center flex flex-col items-center justify-center cursor-pointer"
      )}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onClick={openFileExplorer}
    >
      <input
        placeholder="File Upload Input"
        className="hidden"
        data-testid="fileUploadInput"
        ref={inputRef}
        type="file"
        onChange={handleChange}
        accept={accept}
        multiple={multiple ? multiple : false}
      />
      <div className="flex flex-col text-gray-500 dark:text-gray-300/60">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-16 h-16 mx-auto"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
          />
        </svg>

        <span>Select {fileType}</span>
        <span>or</span>
        <span>Drag and Drop</span>
      </div>
    </div>
  );
};

export default FileUpload;
