import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import { useEffect } from "react";

export const EventArrivalsChart = ({ options, series, setUri }) => {
  useEffect(() => {
    const getDataUri = async () => {
      return await ApexCharts.exec(options.chart.id, "dataURI").then(
        ({ imgURI }) => {
          setUri(imgURI);
        }
      );
    };
    getDataUri();
  }, [options.chart.id, setUri]);

  return (
    <Chart
      className="w-full h-full"
      options={options}
      series={series}
      type="bar"
      height={"460px"}
    />
  );
};

export default EventArrivalsChart;
