import { Fragment, useEffect, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames, getSeriesColor } from "../../utils/ux";

import EventArrivalsChart from "./EventArrivalsChart";
import { GET_WALK_INTERVALS } from "../../schemas/queries/Walks";
import LoadingSpinner from "../../components/LoadingSpinner";
import { WALK_INTERVALS } from "../../constants/Walks";
import { useDarkMode } from "../../hooks/darkMode";
import { useQuery } from "@apollo/client";

export const EventArrivals = ({
  eventId,
  defaultInterval = undefined,
  handleURI = undefined,
}) => {
  const { enabled } = useDarkMode();
  const intervals = useMemo(() => {
    return WALK_INTERVALS;
  }, []);
  const [interval, setInterval] = useState(
    defaultInterval ? defaultInterval : intervals[0]
  );
  const [series, setSeries] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [uri, setUri] = useState("");
  const [chartId] = useState("arrivals-chart");

  const buildSeries = (eventWalkIntervals, darkMode = false) => {
    const { yAxis } = eventWalkIntervals;
    const walkSeries = yAxis.map((series, i) => {
      return {
        name: series.name,
        data: series.data,
        color: getSeriesColor(i, darkMode),
      };
    });
    return walkSeries;
  };

  const { refetch } = useQuery(GET_WALK_INTERVALS, {
    variables: {
      eventId,
      interval: interval.value,
    },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      // DEV ONLY CONSOLE ERROR
      console.error(error.message);
    },
    onCompleted: (data) => {
      const { eventWalkIntervals } = data;
      const walkSeries = buildSeries(eventWalkIntervals, enabled);
      setSeries(walkSeries);
      setInitialLoading(false);
    },
  });

  useEffect(() => {
    const refresh = async () => {
      const { data } = await refetch();
      const { eventWalkIntervals } = data;
      const walkSeries = buildSeries(eventWalkIntervals, enabled);
      setSeries(walkSeries);
    };
    refresh();
  }, [enabled, refetch]);

  return (
    <div className="relative p-8 pt-6 border border-gray-200 rounded-xl dark:border-gray-200/20">
      <div className="flex flex-row justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
          Arrivals
        </h2>
        <div>
          <Listbox
            className="relative w-36"
            value={interval}
            onChange={setInterval}
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  type="button"
                  className="relative cursor-default w-full min-w-[128px] rounded-md bg-white dark:bg-blue-xonar-secondary py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/20 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                >
                  <span className="sr-only">Select Intervals</span>
                  <span className="block truncate">{interval.name}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute z-10 py-1 mt-1 mr-8 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-blue-xonar-secondary max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    tabIndex="-1"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-option-3"
                  >
                    {intervals.map((timeInterval) => (
                      <Listbox.Option
                        key={timeInterval.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900 dark:text-gray-200",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={timeInterval}
                      >
                        {({ active }) => {
                          return (
                            <>
                              <span
                                className={classNames(
                                  interval.id === timeInterval.id
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {timeInterval.name}
                              </span>
                              {interval.id === timeInterval.id ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Listbox.Option>
                    ))}
                    {/* })} */}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="flex mx-auto my-auto mt-1">
        {initialLoading ? (
          <div className="flex mx-auto my-auto">
            <LoadingSpinner />
          </div>
        ) : (
          <EventArrivalsChart
            options={{
              chart: {
                id: chartId,
                stacked: true,
                height: "100%",
                toolbar: {
                  offsetY: -35,
                  offsetX: -130,
                  show: false,
                },
                foreColor: enabled ? "#e5e7eb" : "#373d3f",
                animations: {
                  enabled: true,
                  easing: "easeinout",
                  speed: 1000,
                  animateGradually: {
                    enabled: true,
                    delay: 50,
                  },
                  dynamicAnimation: {
                    enabled: true,
                    speed: 450,
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: { color: enabled ? "#e5e7eb" : "#373d3f" },
                      hideOverflowingLabels: true,
                    },
                  },
                },
              },
              dataLabels: {
                enabled: false,
                textAnchor: "middle",
                // distributed: true,
                style: {
                  fontSize: "12px",
                  // fontFamily: "Helvetica, Arial, sans-serif",
                  // fontWeight: "bold",
                  colors: [enabled ? "#6b7280" : "#9ca3af"],
                },
                dropShadow: {
                  enabled: true,
                  blur: 1,
                  color: "#f8fafc",
                  opacity: 0.45,
                },
              },
            }}
            series={series}
            setUri={handleURI ? handleURI : setUri}
          />
        )}
      </div>
    </div>
  );
};

export default EventArrivals;
