import React, { useState } from "react";
import {
  ResponseModal,
  ResponseProps,
} from "../../../../components/Modals/ResponseModal";
import { SubmitHandler, useForm } from "react-hook-form";

import { AccessControlledComponent } from "../../../../components/AccessControlledComponent";
import { EDIT_PASSCODE } from "../../../../schemas/mutators/Devices";
import { EditDevicesHeader } from "../EditDevicesHeader";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { POLICIES } from "../../../../constants/Policies";
import { Select } from "../../../../components/Menus/Select";
import { Tag } from "../../../../components/Tags";
import { hasWhiteSpace } from "../../../../utils/ux";
import isInt from "validator/es/lib/isInt";
import { toCapitalized } from "../../../../utils/ux";
import { useDeviceConfigurationStore } from "../../../../store/DeviceConfiguration";
import { useMutation } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";

type EditPasscodeForm = {
  passcode?: string;
};

interface EditPasscodeResponseProps {
  success: boolean;
}

type PasscodeInput = {
  code: number;
};

const EditPasscodeResponse: React.FC<EditPasscodeResponseProps> = ({
  success,
}) => {
  return (
    <div className="flex flex-col gap-y-6">
      {/* {inferenceLevel ? (
        <div className="flex flex-row gap-x-2">
          <p className="text-gray-800 dark:text-gray-300">Inference Level</p>
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            <Tag color="gray" tag={inferenceLevel.name} />
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};

interface PasscodePanelProps {}

export const PasscodePanel: React.FC<PasscodePanelProps> = () => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    watch,
    setFocus,
  } = useForm<EditPasscodeForm>();

  const { devices } = useDeviceConfigurationStore(
    useShallow((state) => ({
      devices: state.devices,
    }))
  );
  const [visible, setVisible] = useState(false);

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });
  const [editPasscodeResponse, setEditPasscodeResponse] =
    useState<EditPasscodeResponseProps>({ success: false });

  const [editPasscode, { loading }] = useMutation(EDIT_PASSCODE);

  const onSubmit: SubmitHandler<EditPasscodeForm> = async (data) => {
    if (devices.length < 1) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select one Device to update.",
      });
      setEditPasscodeResponse({ success: false });
      setOpen(true);
      return;
    }

    if (data.passcode === "" || hasWhiteSpace(data.passcode)) {
      setError("passcode", {
        type: "validate",
        message:
          "Please enter a valid passcode! It cannot have any whitespace.",
      });
      setFocus("passcode");
      return;
    }

    data.passcode = data.passcode?.trim();

    if (data.passcode && (data.passcode.length < 4 || !isInt(data.passcode))) {
      setError("passcode", {
        type: "validate",
        message: "Passcode must be a number at least 4 digits long!",
      });
      setFocus("passcode");
      return;
    }

    const pass = Number(data.passcode);

    if (isNaN(pass)) {
      setError("passcode", {
        type: "validate",
        message: "Passcode must be a number at least 4 digits long!",
      });
      setFocus("passcode");
      return;
    }

    const cellIds = devices.map(({ id }) => id);
    // use mutation to send message with this passcode to these cells
    const passcodeInput: PasscodeInput = {
      code: pass,
    };
    const updatedPasscode = await editPasscode({
      variables: {
        ids: cellIds,
        update: passcodeInput,
      },
    });
    if (updatedPasscode.errors && updatedPasscode.errors.length > 0) {
      for (const error of updatedPasscode.errors) {
        console.error(error);
      }
      setResponse({
        ...response,
        success: false,
        errorMsg: `Failed to update passcode. Please contact support or try again.`,
      });
      setOpen(true);
      return;
    }
    setResponse({
      ...response,
      success: true,
      successMsg: `Updated passcode for selected devices!`,
    });
    setOpen(true);
  };

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <EditDevicesHeader />
      <div className="px-12 mt-6 mb-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col [&>*]:py-3"
        >
          <AccessControlledComponent policies={[POLICIES.EDIT_CELL]}>
            <div className="flex flex-col md:flex-row md:justify-between gap-x-4 lg:gap-x-0">
              <label
                className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
                htmlFor="passcode"
              >
                Passcode
              </label>
              <div className="w-full pl-4">
                <div className="flex flex-row items-center w-full">
                  <input
                    id="passcode"
                    aria-describedby={"Passcode for Selected Cells"}
                    className="block w-[90%] rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
                    placeholder={"Enter Passcode"}
                    required={true}
                    type={visible ? "text" : "password"}
                    {...register("passcode")}
                  />
                  <span className="mx-auto text-gray-900 dark:text-gray-200">
                    {!visible ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="cursor-pointer size-6"
                        onClick={() => setVisible(!visible)}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="cursor-pointer size-6"
                        onClick={() => setVisible(!visible)}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                {errors.passcode?.message && (
                  <span role="alert" className="text-red-300 dark:text-red-500">
                    {errors.passcode?.message}
                  </span>
                )}
              </div>
            </div>
          </AccessControlledComponent>
          <div className="flex w-full mt-2">
            <button
              type="submit"
              className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
              onSubmit={(e) => handleSubmit(onSubmit)}
              onClick={(e) => handleSubmit(onSubmit)}
            >
              {loading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={response.success ? "Requested to Update Passcode" : "Error"}
      >
        <EditPasscodeResponse {...editPasscodeResponse} />
      </ResponseModal>
    </div>
  );
};

export default PasscodePanel;
