import DeviceCard from "./DeviceCard";
import { GET_DEVICES } from "../../schemas/queries/Devices";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import { POLICIES } from "../../constants/Policies";
import { usePolicies } from "../../hooks/auth";
import { useCustomerStore } from "../../store/Customer";
import { useNavigate, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";
import { Button } from "../../components/Buttons";
import AccessControlledComponent from "../../components/AccessControlledComponent";

export const Devices = () => {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  //   TODO: implement below filters
  const [statusFilter, setStatusFilter] = useState([]);
  const { customerFilter } = useCustomerStore(
    useShallow((state) => ({
      customerFilter: state.customerFilter,
    }))
  );

  const [venueFilter, setVenueFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [includeDisassembled, setIncludeDisassembled] = useState(false);

  const { loading } = useQuery(GET_DEVICES, {
    variables: {
      statuses: statusFilter,
      customers: customerFilter,
      venues: venueFilter,
      locations: locationFilter,
      includeDisassembled,
    },
    // TODO: pagination
    onCompleted: (data) => {
      setDevices(data.devices.items);
    },
    onError: (error) => {
      console.error(error.message);
      navigate("/error", {
        state: {
          status: 404,
          error: "Something went wrong querying for Devices.",
        },
      });
    },
  });

  if (loading) return <ScreenLoader />;

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <div className="flex mt-4 mb-2 max-w">
        {/* TODO: device filters */}
        {/* TODO: mobile filter and button menu */}
        {/* Temporary permission blocker with isAdmin */}
        <AccessControlledComponent policies={[POLICIES.EDIT_CELL]}>
          <div className="last:ml-auto">
            <Link to="/devices/edit">
              <Button
                type="button"
                className="block px-1 text-sm font-semibold text-center"
              >
                Edit Devices
              </Button>
            </Link>
          </div>
        </AccessControlledComponent>
      </div>
      <ul
        role="list"
        className="divide-y divide-gray-100 dark:divide-gray-200/20"
      >
        {devices.map((device) => {
          return <DeviceCard key={device.id} deviceData={device} />;
        })}
      </ul>
    </div>
  );
};
