import { Category, CategoryPreview } from "../AddCategory";
import { Color } from "../../../constants/Colors";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toCapitalized } from "../../../utils/ux";
import {
  ResponseModal,
  ResponseProps,
} from "../../../components/Modals/ResponseModal";
import { useMutation } from "@apollo/client";
import { UPDATE_CATEGORY } from "../../../schemas/mutators/People";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import isAlpha from "validator/es/lib/isAlpha";

interface EditCategoryProps {
  category: Category;
  refetch: any;
}

type EditCategoryForm = {
  label: string;
  color: string;
};

export const EditCategory: React.FC<EditCategoryProps> = ({
  category,
  refetch,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<EditCategoryForm>();
  const categoryColors = [
    { id: "green", color: toCapitalized(Color.GREEN.toLowerCase()) },
    { id: "red", color: toCapitalized(Color.RED.toLowerCase()) },
  ];
  const [color, setColor] = useState<Color | string>(category.color);
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });
  const { personId } = useParams();

  const [EditCategory, { loading, error }] = useMutation(UPDATE_CATEGORY);

  const onSubmit: SubmitHandler<EditCategoryForm> = async (data) => {
    data.label = data.label?.trim();
    const words = data.label?.split(/\s+/).filter((word) => !isAlpha(word));
    if (data.label && words && words.length > 0) {
      setError("label", {
        type: "validate",
        message: "Please enter a valid alphabetical category!",
      });
      setFocus("label");
      return;
    }
    data.color = color.toUpperCase();
    const updatedPerson = await EditCategory({
      variables: {
        id: personId,
        category: data,
      },
    });
    if (updatedPerson.errors && updatedPerson.errors.length > 0) {
      for (const error of updatedPerson.errors) {
        console.error(error);
      }
      setResponse({
        ...response,
        success: false,
        errorMsg: `Failed to update category. Please contact support or try again.`,
      });
      setOpen(true);
      return;
    }
    setResponse({
      ...response,
      success: true,
      successMsg: `Updated category for ${updatedPerson.data.updateCategory.name}`,
    });
    setOpen(true);
    await refetch();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col py-8 gap-y-4">
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="categoryLabel"
          >
            Category
          </label>
          <div className="w-full pl-4">
            <input
              type="text"
              id="categoryLabel"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/30 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6"
              aria-describedby={"Person's Category Type"}
              placeholder={category?.label}
              defaultValue={category?.label}
              {...register("label")}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
          <label
            className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
            htmlFor="categoryColor"
          >
            Color
          </label>
          <div className="flex flex-row w-full pl-4 gap-x-4">
            {categoryColors.map(({ id, color }) => {
              return (
                <div key={id} className="flex items-center justify-between">
                  <input
                    id={id}
                    name="notification-method"
                    type="radio"
                    defaultChecked={id === category?.color.toLowerCase()}
                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600 dark:text-blue-xonar-light dark:focus:ring-blue-xonar-light"
                    {...(register("color"),
                    { value: color, onChange: () => setColor(id) })}
                  />
                  <label
                    htmlFor={id}
                    className="block mx-2 text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                  >
                    {color}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="py-8 m-auto">
            <CategoryPreview
              color={color}
              label={!watch("label") ? category?.label : watch("label")}
            />
          </div>
        </div>
        <div className="flex w-full py-8">
          <div className="flex w-full">
            <button
              type="submit"
              className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
            >
              {loading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={response.success ? "Success!" : "Failed to Edit Category"}
      />
    </form>
  );
};

export default EditCategory;
