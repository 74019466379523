export const POLICIES = {
  // Cell Policies
  READ_CELL: "read:cell",
  EDIT_CELL: "edit:cell",
  DELETE_CELL: "delete:cell",
  EDIT_INFERENCE_LEVEL: "edit:cell_inference_level",
  EDIT_INFERENCE_MODELS: "edit:cell_models",
  EDIT_INFERENCE_THRESHOLDS: "edit:cell_thresholds",
  EDIT_CELL_MODE: "edit:cell_mode",
  EDIT_SOFTWARE_VERSION: "edit:cell_software",

  // Customer Event Policies
  READ_CUSTOMER_EVENTS: "read:customer_events",
  EDIT_CUSTOMER_EVENTS: "edit:customer_events",
  DELETE_CUSTOMER_EVENTS: "delete:customer_events",

  // People Policies
  READ_PEOPLE: "read:people",
  EDIT_PEOPLE: "edit:people",
  DELETE_PEOPLE: "delete:people",

  // Venue Policies
  READ_VENUES: "read:venues",
  EDIT_VENUES: "edit:venues",
  DELETE_VENUES: "delete:venues",
};
