import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";

import { GET_PERSON } from "../../schemas/queries/People";
import { PeopleCategory } from "./PeopleTableRow";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import { Tag } from "../../components/Tags";
import { useQuery } from "@apollo/client";

interface PeopleDetailProps {
  label: string;
  children: any;
}

const PeopleDetail: React.FC<PeopleDetailProps> = ({ label, children }) => {
  return (
    <div className="flex flex-row justify-between gap-x-4 lg:gap-x-0">
      <span className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200">
        {label}
      </span>
      <div className="w-full pl-4">{children}</div>
    </div>
  );
};

interface AlertTagProps {
  alert: boolean;
}
export const AlertTag: React.FC<AlertTagProps> = ({ alert }) => {
  const color = alert ? "green" : "gray";
  const tag = alert ? "Yes" : "No";
  return <Tag color={color} tag={tag} />;
};

export const PeopleDetails: React.FC = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();
  const { personId } = useParams();
  const { data } = useQuery(GET_PERSON, {
    variables: {
      id: personId,
    },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.error(error);
      navigate("/error", {
        state: { status: 404, error: "Person could not be found." },
      });
    },
    onCompleted: (data) => {
      setInitialLoading(false);
    },
  });
  if (initialLoading) {
    return <ScreenLoader />;
  }

  const mainFaceUrl = data.personFaces.find(
    (personFace) => personFace.id === data.person.mainFace
  ).url;

  return (
    <main>
      <div className="py-8 space-y-16 xl:space-y-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 mt-16 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
                  Person Information
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-gray-300/80">
                  Personal details, category, and images used for facial
                  recognition.
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 my-8 sm:px-6 lg:px-8">
            <div className="grid items-start max-w-2xl grid-cols-1 grid-rows-1 mx-auto gap-y-4 lg:mx-0 lg:max-w-none lg:grid-cols-3 sm:gap-x-12">
              <div className="w-full lg:col-span-1 lg:row-span-1">
                <img
                  className="object-scale-down h-96"
                  src={mainFaceUrl}
                  alt={data.person.name}
                />
              </div>
              <div className="w-full lg:col-span-2 lg:row-span-1 lg:col-start-2">
                <div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-200/20 [&>*]:py-8">
                  <PeopleDetail label="Full Name">
                    <span className="py-2 text-sm leading-8 text-gray-800 dark:text-gray-200">
                      {data.person?.name}
                    </span>
                  </PeopleDetail>
                  <PeopleDetail label="Category">
                    <div className="py-1.5">
                      <PeopleCategory
                        color={data.person?.category.color}
                        label={data.person?.category.label}
                      />
                    </div>
                  </PeopleDetail>
                  <PeopleDetail label="Venues">
                    <div className="flex flex-col gap-y-2 sm:flex-row py-1.5 gap-x-2">
                      {data.personVenues.map(({ id, name }) => (
                        <Link key={id} to={`/venues/${id}`}>
                          <Tag
                            key={id}
                            color={"gray"}
                            tag={name}
                            className="hover:bg-gray-200 dark:hover:bg-gray-500/40"
                          />
                        </Link>
                      ))}
                    </div>
                  </PeopleDetail>
                  <PeopleDetail label="Arrival Alerts">
                    <div className="py-1">
                      <AlertTag alert={data.person.alert} />
                    </div>
                  </PeopleDetail>
                  <PeopleDetail label="Email Address">
                    <span className="py-2 text-sm leading-8 text-gray-800 dark:text-gray-200">
                      {data.person?.email ? data.person?.email : "N/A"}
                    </span>
                  </PeopleDetail>
                  <PeopleDetail label="Phone Number">
                    <span className="py-2 text-sm leading-8 text-gray-800 dark:text-gray-200">
                      {data.person?.phone ? data.person?.phone : "N/A"}
                    </span>
                  </PeopleDetail>
                  <PeopleDetail label="About">
                    <span className="py-2 text-sm leading-8 text-gray-800 dark:text-gray-200">
                      {data.person?.description
                        ? data.person?.description
                        : "N/A"}
                    </span>
                  </PeopleDetail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PeopleDetails;
