import { ImageIcon, VideoIcon } from "./Icons";
import React, { useState } from "react";

import { classNames } from "../../../utils/ux";

export type SidebarViews = "images" | "video";

type SidebarItem = {
  name: string;
  icon: React.ElementType;
  view: SidebarViews;
};

const navigation: SidebarItem[] = [
  { name: "Images", icon: ImageIcon, view: "images" },
  { name: "Video", icon: VideoIcon, view: "video" },
];

type SidebarProps = {
  view: SidebarViews;
  setView: React.Dispatch<React.SetStateAction<SidebarViews>>;
};

export const Sidebar: React.FC<SidebarProps> = ({ view, setView }) => {
  return (
    <div className="flex flex-row mx-auto mb-auto rounded-lg sm:px-8 sm:flex-col gap-y-5">
      <nav className="flex flex-col flex-1 py-4">
        <ul role="list" className="flex flex-col flex-1 gap-y-7">
          <li>
            <ul
              role="list"
              className="flex flex-row -ml-4 -mr-8 sm:space-y-2 sm:flex-col"
            >
              {navigation.map((item) => (
                <li
                  key={item.name}
                  className={classNames(
                    view === item.view
                      ? "bg-gray-200 text-gray-800 dark:bg-blue-500/50 dark:text-gray-200"
                      : "text-gray-800 hover:bg-gray-100 dark:bg-inherit dark:text-gray-400 dark:hover:bg-blue-500/30 dark:hover:text-gray-300",
                    "group flex gap-x-2 rounded-md py-2 leading-6 font-medium flex-grow px-4"
                  )}
                  onClick={() => setView(item.view)}
                >
                  <item.icon />
                  {item.name}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default Sidebar;
