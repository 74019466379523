import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

import { GET_ENTITY_PHOTOS } from "../../schemas/queries/EntityPhotos";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { timestampToRecentString } from "../../utils/timestamps";
import { useQuery } from "@apollo/client";

export default function VenueCard({ venueData }) {
  const [thumbnail, setThumbnail] = useState("");

  const { loading, data } = useQuery(GET_ENTITY_PHOTOS, {
    variables: {
      entityId: venueData.id,
      includeThumbnail: true,
    },
    onCompleted: (data) => {
      setThumbnail(data.entityPhotos[0].thumbnail);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const [recentEventDate, daysSince] = timestampToRecentString(
    venueData.mostRecentEventTime
  );

  return (
    <li className="flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        {loading ? (
          <LoadingSpinner h={12} w={12} />
        ) : (
          <img
            src={thumbnail}
            alt="Venue"
            className="h-12 w-12 flex-none rounded-full bg-gray-50 dark:text-gray-300"
          />
        )}
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
            <Link to={`/venues/${venueData.id}`} className="hover:underline">
              {venueData.name}
            </Link>
          </p>
          <p className="mt-1 flex text-xs leading-5 text-gray-500 dark:text-gray-300/80 truncate hover:underline">
            {venueData.address}
          </p>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-6">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <p className="text-xs leading-5 text-gray-500 dark:text-gray-300/80">
            Last event <time dateTime={recentEventDate}>{daysSince} ago</time>
          </p>
        </div>
        <Menu as="div" className="relative flex-none">
          {({ open }) => (
            <>
              <Menu.Button
                type="button"
                className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900 dark:hover:text-gray-400"
                id="options-menu-0-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span className="sr-only">Open options</span>
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                </svg>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={open}
              >
                <Menu.Items
                  className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white dark:bg-blue-xonar py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu-0-button"
                  tabIndex="-1"
                >
                  <Link
                    to={`/venues/${venueData.id}`}
                    className="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-300"
                    role="menuitem"
                    tabIndex="-1"
                    id="options-menu-0-item-0"
                  >
                    View<span className="sr-only"> Venue</span>
                  </Link>
                  <Link
                    to="#"
                    className="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-300"
                    role="menuitem"
                    tabIndex="-1"
                    id="options-menu-0-item-1"
                  >
                    Edit<span className="sr-only"> Venue</span>
                  </Link>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </li>
  );
}
