import { gql } from "@apollo/client";

export const GET_EVENT_REPORT = gql`
	query GetEventReport($report: String!) {
		getReport(id: $report) {
			id
			shortCode
			createdBy
			metadata {
				eventId
				eventName
				customerName
				venueName
				timeZoneId
				eventTime
				entities {
					id
					entityId
				}
			}
			overview {
				totalWalkCount
				pendingEventCount
				suspectEventCount
				verifiedEventCount
				registeredThreats
				alerts
				recognized
				recognizedGreen
				recognizedRed
				ticketsSold
				maxHourlyArrivals
				maxHourlyArrivalsStartTime
				maxHourlyArrivalsFinishTime
				excludedWalks
				includedWalks
			}
			locations {
				devices {
					location
					cell
					cellName
					arrivals
					alerts
					threats
					recognized
					customerDeviceName
				}
			}
			chartData {
				everyHour {
					xAxis
					yAxis {
						name
						data {
							x
							y
						}
					}
				}
				everyHalfHour {
					xAxis
					yAxis {
						name
						data {
							x
							y
						}
					}
				}
				everyFifteenMinutes {
					xAxis
					yAxis {
						name
						data {
							x
							y
						}
					}
				}
				everyTenMinutes {
					xAxis
					yAxis {
						name
						data {
							x
							y
						}
					}
				}
			}
			recognizedPeople {
				walks {
					id
					entryTime
					exitTime
					venue
					customer
					cell
					cellName
					validationStatus
					categoryName
					categoryColor
					userId
					personOfInterest
					personName
					entryImage
					exitImage
					walkVideo
					customerDeviceName
				}
				numWalks
			}
			registeredThreats {
				walks {
					id
					entryTime
					exitTime
					venue
					customer
					cell
					cellName
					customerDeviceName
					entryImage
					exitImage
					walkVideo
					validationStatus
					objectOfInterestPresent
					annotatedSubjectAreaContents {
						subjectArea
						object {
							id
							name
							category
						}
					}
				}
				numWalks
			}
		}
	}
`;

export const AUTHENTICATE_EVENT_REPORT = gql`
	query AuthenticateEventReport($report: String!, $passcode: String) {
		authenticateReport(id: $report, passcode: $passcode)
	}
`;

export const GET_REPORT_INFO = gql`
	query GetReportInfo($report: String!) {
		getReport(id: $report) {
			id
			createdBy
		}
	}
`;

export const GET_REPORTS = gql`
	query GetReports(
		$skip: Int = 0
		$limit: Int = 10
		$userOnly: Boolean = true
		$customers: [String!] = []
		$venues: [String!] = []
	) {
		getReports(
			skip: $skip
			limit: $limit
			userOnly: $userOnly
			customers: $customers
			venues: $venues
		) {
			items {
				id
				shortCode
				createdBy
				metadata {
					eventName
					eventTime
					customerName
					venueName
					timeZoneId
					entitiesInfo {
						url
						thumbnail
					}
				}
				customer
				venue
				passcode
				createdTime
			}
			count
		}
	}
`;
