import React from "react";
import { classNames } from "../../utils/ux";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
  children?: any;
}

export const Button: React.FC<ButtonProps> = ({
  loading = false,
  ...props
}: ButtonProps) => {
  const buttonProps = { ...props };
  buttonProps.className = undefined;
  return (
    <button
      {...buttonProps}
      className={classNames(
        "rounded-md bg-blue-xonar-light text-sm font-semibold text-white dark:text-gray-300 dark:hover:text-gray-200 shadow-sm hover:bg-sky-xonar dark:bg-blue-xonar-light/50 dark:hover:bg-blue-xonar-light/40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-xonar-secondary",
        props.className
      )}
    >
      <div className="inset-y-0 mt-[1.5px] px-2.5 py-1.5">
        {loading ? (
          <div
            className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-gray-200 dark:text-gray-200/50 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          props.children
        )}
      </div>
    </button>
  );
};

export default Button;
