import { useEffect, useState } from "react";

import { GET_EVENTS } from "../../schemas/queries/Events";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { getDayStartDate } from "../../utils/timestamps";
import { useCustomerStore } from "../../store/Customer";
import { useQuery } from "@apollo/client";
import { useShallow } from "zustand/react/shallow";

const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const EVENTS_DATE_TIME_LOCAL_STORAGE_KEY = "eventsDateTime";

export const Events = () => {
  const { customerFilter } = useCustomerStore(
    useShallow((state) => ({
      customerFilter: state.customerFilter,
    }))
  );

  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setDate(
        localStorage.getItem(EVENTS_DATE_TIME_LOCAL_STORAGE_KEY)
          ? new Date(
              Number(localStorage.getItem(EVENTS_DATE_TIME_LOCAL_STORAGE_KEY))
            )
          : new Date()
      );
    }

    return () => {
      mounted = false;
    };
  }, []);

  const now = getDayStartDate(new Date());
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  const days = [];
  for (
    let calendarDate = new Date(firstDay);
    calendarDate <= lastDay;
    calendarDate.setDate(calendarDate.getDate() + 1)
  ) {
    days.push(getDayStartDate(calendarDate));
  }

  var used = firstDay.getDay() + lastDay.getDate();
  const weeks = Math.ceil(used / DAYS_OF_WEEK.length);

  var calendarFirstWeekDay = firstDay.getDay();
  const calendarDays = [];

  for (let d = 0; d < weeks * DAYS_OF_WEEK.length; d++) {
    const date = days[d - calendarFirstWeekDay];
    calendarDays.push({
      date: date,
      events: date
        ? events.filter(
            (e) => getDayStartDate(e.eventTime).getTime() == date.getTime()
          )
        : [],
    });
  }

  const { loading } = useQuery(GET_EVENTS, {
    variables: {
      skip: 0,
      limit: 1000,
      timeStart: firstDay.getTime(),
      timeEnd: lastDay.getTime(),
      customers: customerFilter,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setEvents(data.events.items);
    },
    onError: (error) => {
      console.error(error.message);
      // Todo, show error popup
    },
  });

  const handleMoveMonth = (monthChange) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + monthChange);
    newDate.setDate(1);
    setDate(newDate);
    storeEventsDateTimeLocally(newDate);
  };

  const handleMoveToCurrentMonth = () => {
    const newDate = new Date();
    newDate.setDate(1);
    setDate(newDate);
    storeEventsDateTimeLocally(newDate);
  };

  const storeEventsDateTimeLocally = (eventsDateTime) => {
    localStorage.setItem(
      EVENTS_DATE_TIME_LOCAL_STORAGE_KEY,
      eventsDateTime.getTime().toString()
    );
  };

  const EventName = ({ event }) => {
    /* If event name is the default date then use venue name */
    const eventName =
      !event.name || !isNaN(Date.parse(event.name))
        ? event.venueName
        : event.name;
    return <>{eventName}</>;
  };

  return (
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="pt-16 lg:flex lg:h-full lg:flex-col">
        <div className="mt-8 border border-gray-200 rounded-xl dark:border-gray-200/20">
          <header className="flex items-center justify-between px-6 py-4 border-b border-gray-200 lg:flex-none dark:border-gray-200/60">
            <div className="flex items-center">
              <div className="relative flex items-stretch bg-white rounded-md shadow-sm dark:bg-blue-xonar-secondary">
                <button
                  onClick={() => handleMoveMonth(-1)}
                  type="button"
                  className="flex items-center justify-center w-12 pr-0 pr-1 text-gray-400 border-l border-gray-300 h-9 rounded-l-md border-y hover:text-gray-500 focus:relative w-9 hover:bg-gray-50 dark:hover:bg-blue-xonar dark:border-gray-500"
                >
                  <span className="sr-only">Previous month</span>
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 dark:text-gray-200 focus:relative min-w-28 dark:border-gray-500"
                >
                  <time dateTime="2022-01">
                    {date.toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "short",
                    })}
                  </time>
                </button>
                <span className="relative hidden w-px h-5 -mx-px bg-gray-300"></span>
                <button
                  type="button"
                  onClick={() => handleMoveMonth(1)}
                  className="flex items-center justify-center w-12 pl-0 pl-1 text-gray-400 border-r border-gray-300 h-9 rounded-r-md border-y hover:text-gray-500 focus:relative w-9 hover:bg-gray-50 dark:hover:bg-blue-xonar dark:border-gray-500"
                >
                  <span className="sr-only">Next month</span>
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  onClick={() => handleMoveToCurrentMonth()}
                  className="ml-4 rounded-md border border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:text-gray-500 hover:bg-gray-50 dark:text-gray-200 focus:relative dark:border-gray-500 dark:hover:bg-blue-xonar dark:hover:text-gray-200/70"
                >
                  Today
                </button>
              </div>
            </div>
            {loading && <LoadingSpinner />}
          </header>
          <div className="hidden shadow lg:block ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
            <div className="grid grid-cols-7 gap-px text-xs font-semibold leading-6 text-center text-gray-700 bg-gray-200 border-b border-gray-300 dark:bg-gray-200/20 dark:border-gray-200/20 lg:flex-none dark:text-gray-200">
              {DAYS_OF_WEEK.map((day) => (
                <div
                  key={day}
                  className="flex justify-center py-2 bg-white dark:bg-blue-xonar"
                >
                  <span>{day.substring(0, 1)}</span>
                  <span className="sr-only sm:not-sr-only">
                    {day.substring(1, day.length)}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex text-xs leading-6 text-gray-700 bg-gray-200 lg:flex-auto dark:bg-gray-200/20">
              <div
                className={`hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-${weeks} lg:gap-px`}
              >
                {calendarDays.map((day, i) => (
                  <div
                    key={i}
                    className={`relative ${
                      day.date
                        ? "bg-white dark:bg-blue-xonar-secondary"
                        : "bg-gray-50 dark:bg-blue-xonar"
                    } px-3 py-2 text-gray-500 dark:text-gray-400 min-h-28`}
                  >
                    {day.date && (
                      <>
                        <time
                          dateTime="2021-12-28"
                          className={
                            now.getTime() == day.date?.getTime()
                              ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                              : ""
                          }
                        >
                          {day.date?.getDate()}
                        </time>
                        <ol className="mt-2">
                          {day.events.map((event) => (
                            <li key={event.id}>
                              <Link
                                to={`/events/${event.id}`}
                                className="flex group"
                              >
                                <p className="flex-auto font-medium text-gray-900 truncate group-hover:text-indigo-600 dark:text-gray-200">
                                  <EventName event={event} />
                                </p>
                                <p className="flex-none hidden ml-3 text-gray-500 dark:text-gray-400 group-hover:text-indigo-600 xl:block">
                                  {event.includedWalks?.toLocaleString()}
                                </p>
                              </Link>
                            </li>
                          ))}
                        </ol>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="px-4 py-10 sm:px-6 lg:hidden">
            {calendarDays.filter((d) => d.events?.length > 0).length === 0 ? (
              <p className="text-sm text-gray-900 dark:text-gray-200/70">
                There are currently no events for this month
              </p>
            ) : (
              <ol className="overflow-hidden text-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:divide-gray-200/40 ring-1 ring-black ring-opacity-5 dark:ring-gray-200/20">
                {calendarDays
                  .filter((d) => d.events?.length > 0)
                  .map((day, i) => (
                    <li
                      key={i}
                      className="flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50 dark:bg-blue-xonar-secondary"
                    >
                      <div className="flex-auto">
                        <time
                          dateTime="2022-01-15T09:00"
                          className="flex items-center mt-2 text-gray-700 dark:text-gray-400"
                        >
                          {day.date.toLocaleDateString()}
                        </time>
                        <ol className="mt-2">
                          {day.events.map((event) => (
                            <li key={event.id}>
                              <Link
                                to={`/events/${event.id}`}
                                className="flex group"
                              >
                                <p className="flex-auto font-medium text-gray-900 truncate group-hover:text-indigo-600 dark:text-gray-200">
                                  <EventName event={event} />
                                </p>
                                <p className="font-medium text-gray-900 truncate flex-right group-hover:text-indigo-600 dark:text-gray-400">
                                  {event.totalWalkCount?.toLocaleString()}
                                </p>
                              </Link>
                            </li>
                          ))}
                        </ol>
                      </div>
                    </li>
                  ))}
              </ol>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
