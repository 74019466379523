import {
	ChevronDownIcon,
	ChevronUpIcon,
	PaperClipIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";

interface TicketCardProps {
	subject: string;
	content: string;
	cellName: string;
	createdTime: number;
	updatedTime: number;
	stage: string;
	attachments: string[];
	// Add other properties from HubspotTicket as needed
}

const statusColors = {
	New: "bg-indigo-100 text-indigo-800 dark:bg-indigo-800 dark:text-indigo-100",
	"In Progress":
		"bg-pink-100 text-pink-800 dark:bg-pink-800 dark:text-pink-100",
	Resolved:
		"bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
};

const statusLabels = {
	New: "New",
	"In Progress": "In Progress",
	Resolved: "Resolved",
};

export default function TicketCard({
	subject,
	content,
	cellName,
	createdTime,
	updatedTime,
	stage,
	attachments,
}: TicketCardProps) {
	const status = statusLabels[stage] || "Open";
	const statusColor = statusColors[stage] || statusColors.New;

	const [attachmentsOpen, setAttachmentsOpen] = useState(false);
	const [attachmentHeight, setAttachmentHeight] = useState("0px");
	const attachmentRef = useRef<HTMLDivElement>(null);

	const [isOverflowing, setIsOverflowing] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const subjectRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const [translateX, setTranslateX] = useState(0);
	const [maxTranslateX, setMaxTranslateX] = useState(0);

	useEffect(() => {
		if (subjectRef.current && containerRef.current) {
			const isOverflow =
				subjectRef.current.scrollWidth >
				containerRef.current.clientWidth;
			setIsOverflowing(isOverflow);
			if (isOverflow) {
				const overflow =
					subjectRef.current.scrollWidth -
					containerRef.current.clientWidth;
				const maxTranslate =
					(overflow / containerRef.current.clientWidth) * 100;
				setMaxTranslateX(maxTranslate);
			}
		}
	}, [subject]);

	useEffect(() => {
		let animationFrame: number;
		let startTime: number | null = null;

		const animate = (timestamp: number) => {
			if (!startTime) startTime = timestamp;
			const animationTime = 2 * 1000; // 2 seconds
			const elapsedTime = timestamp - startTime;
			const progress = Math.min(elapsedTime / animationTime, 1);

			if (isHovering && isOverflowing) {
				const newTranslateX = -maxTranslateX * progress;
				setTranslateX(newTranslateX);
			} else {
				setTranslateX(0);
			}

			if (progress < 1 || !isHovering) {
				animationFrame = requestAnimationFrame(animate);
			}
		};

		animationFrame = requestAnimationFrame(animate);

		return () => {
			if (animationFrame) {
				cancelAnimationFrame(animationFrame);
			}
		};
	}, [isHovering, isOverflowing, maxTranslateX]);

	const toggleAttachments = () => {
		setAttachmentsOpen(!attachmentsOpen);
	};

	useEffect(() => {
		if (attachmentRef.current) {
			setAttachmentHeight(
				attachmentsOpen
					? `${attachmentRef.current.scrollHeight}px`
					: "0px"
			);
		}
	}, [attachmentsOpen]);

	return (
		<div className="overflow-hidden rounded-lg bg-gradient-to-br from-white to-slate-100 dark:from-slate-800 dark:to-slate-900 shadow-lg w-300 hover:shadow-xl transition-all duration-300 ease-in-out hover:-translate-y-1 hover:scale-[1.02] relative flex flex-col">
			<div className="h-1 bg-blue-xonar-true-blue"></div>
			<div className="px-5 py-4 flex-grow">
				<div className="flex items-center justify-between mb-2">
					<div
						ref={containerRef}
						className="relative flex-grow mr-2 overflow-hidden"
						onMouseEnter={() => setIsHovering(true)}
						onMouseLeave={() => setIsHovering(false)}
					>
						<div
							ref={subjectRef}
							className="text-lg font-semibold transition-transform duration-300 ease-linear text-slate-800 dark:text-slate-100 whitespace-nowrap"
							style={{ transform: `translateX(${translateX}%)` }}
						>
							{subject}
						</div>
					</div>
					<span
						className={`px-2 py-1 text-xs font-medium rounded-full ${statusColor} flex-shrink-0`}
					>
						{status}
					</span>
				</div>
				<div className="overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 dark:scrollbar-thumb-slate-600 scrollbar-track-transparent">
					<div className="flex justify-between space-x-3">
						<p className="mb-2 text-xs text-slate-500 dark:text-slate-400">
							{cellName}
						</p>
						<p className="mb-2 text-xs text-slate-500 dark:text-slate-400">
							{stage === "New" || updatedTime === createdTime
								? `${new Date(createdTime).toLocaleString()}`
								: `${new Date(updatedTime).toLocaleString()}`}
						</p>
					</div>
					<p className="mb-2 text-sm text-slate-600 dark:text-slate-300 custom-scrollbar">
						{content}
					</p>
				</div>
			</div>
			{attachments && attachments.length > 0 && (
				<div className="mt-auto">
					<div
						className="flex items-center justify-between px-5 py-3 cursor-pointer bg-slate-200/50 dark:bg-slate-700/50"
						onClick={toggleAttachments}
					>
						<span className="text-xs font-medium align-middle transition-colors duration-200 ease-in-out select-none text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-white">
							<PaperClipIcon className="inline w-4 h-4 mr-1" />
							View Attachments
						</span>
						{attachmentsOpen ? (
							<ChevronUpIcon className="w-4 h-4 transition-transform duration-300" />
						) : (
							<ChevronDownIcon className="w-4 h-4 transition-transform duration-300" />
						)}
					</div>
					<div
						ref={attachmentRef}
						style={{ maxHeight: attachmentHeight }}
						className="overflow-hidden transition-all duration-300 ease-in-out"
					>
						<div className="px-5 py-3 border-t bg-slate-100 dark:bg-slate-800 border-slate-200 dark:border-slate-700">
							<ul className="space-y-2">
								{attachments.map((attachment, index) => (
									<li
										key={index}
										className="text-sm text-slate-600 dark:text-slate-300"
									>
										<a
											href={attachment}
											target="_blank"
											rel="noopener noreferrer"
											className="hover:underline"
										>
											Attachment {index + 1}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
