import React, { useState } from "react";
import { GENERATE_REPORT_FROM_EVENT } from "../../schemas/mutators/Reports";
import { useMutation } from "@apollo/client";
import { Button } from "../../components/Buttons";
import { Modal } from "../../components/Modals";
import { DynamicLoadingSpinner } from "../../components/LoadingSpinner";
import { ClipboardIcon } from "@heroicons/react/20/solid";

type GenerateReportProps = {
	event: string;
};

export const GenerateReport: React.FC<GenerateReportProps> = ({ event }) => {
	const [open, setOpen] = useState(false);
	const [reportUrl, setReportUrl] = useState("");

	const [generateReport, { loading: reportLoading, data: reportData }] =
		useMutation(GENERATE_REPORT_FROM_EVENT);

	const handleOnClick = async (e) => {
		e.preventDefault();
		const generatedReport = await generateReport({ variables: { event } });
		const { shortCode } = generatedReport.data.generateReport;
		const newReportUrl = `https://u.xonar.com/${shortCode}`;
		setReportUrl(newReportUrl);
		setOpen(true);
	};

	return (
		<>
			{reportLoading ? (
				<>
					<p className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer dark:text-gray-200">
						Generating
					</p>
					<DynamicLoadingSpinner />
				</>
			) : (
				<a
					href=""
					className="block text-sm font-semibold leading-6 text-gray-900 cursor-pointer dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-500"
					onClick={handleOnClick}
				>
					Share Report
				</a>
			)}
			<Modal open={open} setOpen={setOpen} title="Share Report">
				<div className="flex flex-col w-full my-6 gap-y-2">
					<div className="text-sm text-gray-900 dark:text-gray-200">
						Click the link below to view your report or share it
						with others.
					</div>
					<div className="flex flex-row justify-between py-2 text-gray-900 bg-gray-100 rounded-lg dark:bg-blue-xonar-navy dark:text-gray-200">
						<a
							className="px-1.5 text-base font-semibold leading-6"
							href={reportUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							{reportUrl}
						</a>
						<ClipboardIcon
							height={20}
							width={20}
							className="leading-6 mx-1.5 cursor-pointer hover:text-indigo-500"
							onClick={async () =>
								await navigator.clipboard.writeText(reportUrl)
							}
							title="Copy Link"
						/>
					</div>
					<div className="text-sm text-gray-900 dark:text-gray-200">
						Other viewers must use the passcode below.
					</div>
					<div className="flex flex-row justify-between py-2 text-gray-900 bg-gray-100 rounded-lg dark:bg-blue-xonar-navy dark:text-gray-200">
						<p className="w-full font-bold text-center">
							{reportData?.generateReport.passcode}
						</p>
						<ClipboardIcon
							height={20}
							width={20}
							className="leading-6 mx-1.5 cursor-pointer hover:text-indigo-500"
							onClick={async () =>
								await navigator.clipboard.writeText(
									reportData?.generateReport.passcode
								)
							}
							title="Copy Passcode"
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default GenerateReport;
