import React from "react";
import { DeviceControlPanel } from "./DeviceControlPanel";
import { EditDevicesHeader } from "./EditDevicesHeader";

interface EditDevicesProps {}

export const EditDevices: React.FC<EditDevicesProps> = () => {
  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <EditDevicesHeader />
      <div className="px-12 mt-6 mb-4">
        <DeviceControlPanel />
      </div>
    </div>
  );
};

export default EditDevices;
