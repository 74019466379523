export const WALK_INTERVALS = [
	{
		id: `interval_1`,
		name: "Hourly",
		value: 60,
		key: "everyHour",
	},
	{
		id: `interval_2`,
		name: "30 Minutes",
		value: 30,
		key: "everyHalfHour",
	},
	{
		id: `interval_3`,
		name: "15 Minutes",
		value: 15,
		key: "everyFifteenMinutes",
	},
	{
		id: `interval_4`,
		name: "10 Minutes",
		value: 10,
		key: "everyTenMinutes",
	},
];
