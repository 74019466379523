import { IconProps } from "../Icons";
import React from "react";
import { FadeIn } from "../Animations/FadeIn";
import { classNames } from "../../utils/ux";

export interface Stage {
  icon: React.FC<IconProps>;
  state: string;
  show: boolean;
  color: string; // tailwind string for styling color
  message?: string;
}

interface FeedProps {
  stages: Stage[];
}

export const Feed: React.FC<FeedProps> = ({ stages }) => {
  return (
    <div className="flow-root">
      <ul role="list" className="">
        {stages.map((stage, i) => {
          return (
            <FadeIn delay="delay-150" show={stage.show}>
              <li key={`${stage.state}-${i}`}>
                <div className="relative pb-6">
                  {i !== 0 ? (
                    <span
                      className="absolute left-6 bottom-[22.5px] -ml-px h-full w-0.5 bg-gray-400 dark:bg-gray-700"
                      aria-hidden="true"
                    />
                  ) : (
                    <></>
                  )}
                  <div className="relative flex space-x-3">
                    <div
                      className={classNames(
                        "w-12 h-12 rounded-full items-center justify-center ring-2",
                        stage.color
                      )}
                    >
                      <stage.icon className="w-8 h-8 mx-auto my-2" />
                    </div>
                    <div className="flex my-auto">
                      <p className="text-gray-500 text-md dark:text-gray-300">
                        {stage.message ? stage.message : stage.state}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </FadeIn>
          );
        })}
      </ul>
    </div>
  );
};

export default Feed;
