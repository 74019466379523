import React, { useState } from "react";

type InputWithError = [
	string,
	React.Dispatch<React.SetStateAction<string>>,
	boolean,
	React.Dispatch<React.SetStateAction<boolean>>
];

export const useInputWithError = (defaultInput = "") => {
	const [input, setInput] = useState(defaultInput);
	const [error, setError] = useState(false);

	const value: InputWithError = [input, setInput, error, setError];

	return value;
};
