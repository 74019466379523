import { NotificationSettings } from "./NotificationSettings";
import { ProfileDetails } from "./ProfileDetails";
import React from "react";

type Props = {};

export const Profile = (props: Props) => {
	return (
		<main className="py-8 space-y-16 xl:space-y-20">
			<section className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="px-4 mt-16 sm:px-6 lg:px-8">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
								Profile Information
							</h1>
						</div>
					</div>
					<ProfileDetails />
					<NotificationSettings />
				</div>
			</section>
		</main>
	);
};

export default Profile;
