import { gql } from "@apollo/client";

export const GET_USER_ALERTS = gql`
  query GetUserAlerts {
    notifications {
      id
      method
      notifier
      message
      viewed
      viewedTime
      cleared
      clearedTime
    }
  }
`;
