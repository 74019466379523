import { gql } from "@apollo/client";

export const GET_INFERENCE_LEVELS = gql`
  query GetInferenceLevels {
    inferenceLevels {
      low
      standard
      high
    }
  }
`;

export const GET_INFERENCE_LEVELS_AND_MODEL_CONFIGS = gql`
  query GetInferenceLevelsAndModelConfigs {
    inferenceLevels {
      low
      standard
      high
    }
    modelConfigurations {
      id
      name
      status
    }
  }
`;
