import { gql } from "@apollo/client";

export const GET_TICKETS = gql`
	query GetHubspotTickets(
		$skip: Int = 0
		$limit: Int = 6
		$stages: [String!] = []
		$cells: [String!] = []
		$sortBy: Int = -1
		$subject: String = ""
		$cellName: String = ""
	) {
		hubspotTickets(
			skip: $skip
			limit: $limit
			stages: $stages
			cells: $cells
			sortBy: $sortBy
			subject: $subject
			cellName: $cellName
		) {
			count
			items {
				id
				sourceSystem
				sourceSystemId
				sourceSystemDetailsUrl
				subject
				content
				stage
				closed
				cellName
				cellIdentifier
				cell
				createdTime
				updatedTime
				archived
				reconciled
				portalUser
				attachments
				hasAttachments
			}
		}
	}
`;
