import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { usePageWindow } from "./PaginationHooks";

interface PageButtonProps {
	children: React.ReactNode;
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	isActive: boolean;
	disabled: boolean;
}

const PageButton: React.FC<PageButtonProps> = ({
	children,
	onClick,
	isActive,
	disabled,
}) => (
	<motion.button
		whileHover={{ scale: 1.1 }}
		whileTap={{ scale: 0.9 }}
		onClick={onClick}
		disabled={disabled}
		className={`
      w-8 h-8 flex items-center justify-center rounded-md
      text-sm font-medium transition-all duration-200
      ${
			isActive
				? "bg-blue-xonar-true-blue text-white shadow-md"
				: "bg-white text-slate-600 dark:bg-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-600"
		}
      ${disabled ? "opacity-30 cursor-not-allowed" : "cursor-pointer"}
    `}
	>
		{children}
	</motion.button>
);

interface PaginationProps {
	skip: number;
	onSkip: (page: number) => void;
	count: number;
	limit: number;
	currentAmount: number;
	itemType?: string;
	className?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
	skip,
	onSkip,
	count,
	limit,
	currentAmount,
	itemType = "results",
	className = "",
}) => {
	const { pages, handlePageClick, firstWindow, lastWindow } = usePageWindow({
		onSkip,
		count,
		limit,
	});

	const currentPage = skip + 1;
	const totalPages = Math.ceil(count / limit);

	const [direction, setDirection] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);

	const wrappedHandlePageClick = (
		e: React.MouseEvent<HTMLButtonElement>,
		page: number
	) => {
		if (isAnimating) return;
		setIsAnimating(true);
		setDirection(page > currentPage ? -1 : 1);
		handlePageClick(e, page);
		setTimeout(() => setIsAnimating(false), 300);
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
			className={`${className} relative overflow-hidden bg-gradient-to-br from-white to-slate-100 dark:from-slate-800 dark:to-slate-900 shadow-lg rounded-lg p-4 border border-slate-200 dark:border-slate-700`}
		>
			<AnimatePresence>
				{isAnimating && (
					<motion.div
						key="flash"
						initial={{
							x: direction > 0 ? "-100%" : "100%",
							opacity: 0,
						}}
						animate={{ x: 0, opacity: 1 }}
						exit={{
							x: direction > 0 ? "100%" : "-100%",
							opacity: 0,
						}}
						transition={{
							duration: 0.3,
							ease: "easeInOut",
						}}
						className="absolute inset-0 bg-gradient-to-r from-transparent via-black/[0.02] to-transparent dark:via-white/[0.03] pointer-events-none"
					/>
				)}
			</AnimatePresence>
			<div className="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-y-0">
				<p className="text-sm text-slate-600 dark:text-slate-300">
					Showing{" "}
					<span className="font-semibold text-slate-800 dark:text-slate-100">
						{count === 0 ? 0 : limit * skip + 1}
					</span>{" "}
					to{" "}
					<span className="font-semibold text-slate-800 dark:text-slate-100">
						{limit * skip + currentAmount}
					</span>{" "}
					of{" "}
					<span className="font-semibold text-slate-800 dark:text-slate-100">
						{count}
					</span>{" "}
					{itemType}
				</p>

				<div className="flex items-center space-x-2">
					<PageButton
						onClick={(e) =>
							wrappedHandlePageClick(
								e,
								Math.max(1, currentPage - 1)
							)
						}
						disabled={currentPage === 1}
						isActive={false}
					>
						<ChevronLeftIcon className="w-4 h-4" />
					</PageButton>
					<div className="flex space-x-2">
						{pages.length <= 5 ? (
							pages.map((page) => (
								<PageButton
									key={page}
									onClick={(e) =>
										wrappedHandlePageClick(e, page)
									}
									isActive={currentPage === page}
									disabled={false}
								>
									{page}
								</PageButton>
							))
						) : (
							<>
								{firstWindow.map((page) => (
									<PageButton
										key={page}
										onClick={(e) =>
											wrappedHandlePageClick(e, page)
										}
										isActive={currentPage === page}
										disabled={false}
									>
										{page}
									</PageButton>
								))}
								{currentPage > 3 &&
									currentPage < totalPages - 2 && (
										<span className="px-2 py-1 text-slate-400 dark:text-slate-500">
											...
										</span>
									)}
								{lastWindow.map((page) => (
									<PageButton
										key={page}
										onClick={(e) =>
											wrappedHandlePageClick(e, page)
										}
										isActive={currentPage === page}
										disabled={false}
									>
										{page}
									</PageButton>
								))}
							</>
						)}
					</div>

					<PageButton
						onClick={(e) =>
							wrappedHandlePageClick(
								e,
								Math.min(totalPages, currentPage + 1)
							)
						}
						disabled={currentPage === totalPages || count === 0}
						isActive={false}
					>
						<ChevronRightIcon className="w-4 h-4" />
					</PageButton>
				</div>
			</div>
		</motion.div>
	);
};

export default Pagination;
