import { gql } from "@apollo/client";

export const ADD_PEOPLE_BATCH_SUBSCRIPTION = gql`
  subscription AddPeopleBatchSubscription($batch: [AddPersonBatchInputType!]) {
    uploadBatch(batch: $batch) {
      state
      imagesRemaining
      imageTarget
      usersRemaining
      userTarget
      linksRemaining
      linkTarget
      errorMsg
    }
  }
`;

export const ADD_PERSON_SUBSCRIPTION = gql`
  subscription AddPeopleBatchSubscription(
    $person: AddPersonInputType
    $uploadId: String
  ) {
    addPerson(person: $person, uploadId: $uploadId) {
      state
      imagesRemaining
      imageTarget
      usersRemaining
      userTarget
      linksRemaining
      linkTarget
      errorMsg
    }
  }
`;
