import { gql } from "@apollo/client";

export const GET_EVENT = gql`
	query GetEvent($id: String!) {
		event(id: $id) {
			id
			name
			customer
			cells
			venue
			venueName
			eventTime
			timeZoneId
			totalWalkCount
			registeredThreats
			alerts
			maxHourlyArrivals
			maxHourlyArrivalsStartTime
			maxHourlyArrivalsFinishTime
			ticketsSold
			excludedWalks
			includedWalks
			recognized
			recognizedGreen
			recognizedRed
		}
	}
`;

export const GET_EVENT_WITH_VENUE_PHOTO = gql`
	query GetEventWithVenuePhoto(
		$id: String!
		$venueId: String!
		$hasVenueId: Boolean!
	) {
		event(id: $id) {
			id
			name
			customer
			cells
			venue
			venueName
			eventTime
			timeZoneId
			totalWalkCount
			registeredThreats
			alerts
			maxHourlyArrivals
			maxHourlyArrivalsStartTime
			maxHourlyArrivalsFinishTime
			ticketsSold
			excludedWalks
			includedWalks
			recognized
			recognizedGreen
			recognizedRed
		}
		entityPhotos(entityId: $venueId) @include(if: $hasVenueId) {
			url
			deviceLocationPlan
		}
	}
`;

export const GET_EVENT_REPORT = gql`
	query GET_EVENT_REPORT(
		$id: String!
		$venueId: String!
		$hasVenueId: Boolean!
	) {
		event(id: $id) {
			id
			name
			customer
			cells
			venue
			venueName
			eventTime
			timeZoneId
			totalWalkCount
			registeredThreats
			alerts
			maxHourlyArrivals
			maxHourlyArrivalsStartTime
			maxHourlyArrivalsFinishTime
			ticketsSold
			excludedWalks
			includedWalks
			recognized
			recognizedGreen
			recognizedRed
		}
		entityPhotos(entityId: $venueId) @include(if: $hasVenueId) {
			url
		}
		eventMetrics(eventId: $id) {
			cell
			cellName
			location
			customerEvent
			arrivals
			alerts
			threats
			recognized
		}
	}
`;

export const GET_EVENTS = gql`
	query GetEvents(
		$skip: Int = 0
		$limit: Int = 10
		$timeStart: Int
		$timeEnd: Int
		$customers: [String!] = []
	) {
		events(
			skip: $skip
			limit: $limit
			timeStart: $timeStart
			timeEnd: $timeEnd
			customers: $customers
		) {
			count
			items {
				id
				name
				venue
				venueName
				eventTime
				timeZoneId
				totalWalkCount
				registeredThreats
				alerts
				maxHourlyArrivals
				maxHourlyArrivalsStartTime
				maxHourlyArrivalsFinishTime
				ticketsSold
				excludedWalks
				includedWalks
				recognized
				recognizedGreen
				recognizedRed
			}
		}
	}
`;

export const GET_EVENT_METRICS = gql`
	query GetEventMetrics($id: String!) {
		eventMetrics(eventId: $id) {
			cell
			cellName
			location
			customerEvent
			arrivals
			alerts
			threats
			recognized
			customerDeviceName
		}
	}
`;

// TODO get metrics for people recognized
export const GET_EVENTS_METRICS = gql`
	query GetEventsMetrics(
		$present: TimeFrameInput!
		$previous: TimeFrameInput!
		$customers: [String!]
	) {
		metrics(present: $present, previous: $previous, customers: $customers) {
			arrivals {
				value
				previous
				difference
			}
			maxHourlyArrivals {
				value
				previous
				difference
			}
			threatsDetected {
				value
				previous
				difference
			}
			peopleRecognized {
				value
				previous
				difference
			}
			present {
				start
				end
			}
			previous {
				start
				end
			}
		}
	}
`;
