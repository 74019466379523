import { useContext } from "react";
import { ThemeContext } from "../providers/ThemeProvider";

export const useDarkMode = () => {
	const { enabled, update } = useContext(ThemeContext);

	const useSystemPreferences = () => {
		localStorage.removeItem("theme");
		update(window.matchMedia("(prefers-color-scheme: dark)").matches);
	};

	const toggleDarkMode = () => {
		update(!enabled);
	};
	return { enabled, toggleDarkMode, useSystemPreferences };
};
