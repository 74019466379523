import React, { useEffect } from "react";
import PageLayout from "../layouts/PageLayout";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Landing = () => {
	const navigate = useNavigate();
	const { isAuthenticated, loginWithRedirect } = useAuth0();

	const handleLogin = async () => {
		return await loginWithRedirect({
			appState: {
				returnTo: "/home",
			},
		});
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate("/home");
		} else {
			handleLogin();
		}
	}, [isAuthenticated]);

	return (
		<PageLayout>
			<></>
		</PageLayout>
	);
};

export default Landing;
