import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query GET_CUSTOMER($id: String!) {
    customer(id: $id) {
      id
      customerName
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GET_CUSTOMERS {
    customers {
      id
      customerName
      shortCode
    }
  }
`;
