import React, { useState } from "react";
import {
  ResponseModal,
  ResponseProps,
} from "../../../../components/Modals/ResponseModal";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { AccessControlledComponent } from "../../../../components/AccessControlledComponent";
import { EDIT_INFERENCE } from "../../../../schemas/mutators/Devices";
import { EditDevicesHeader } from "../EditDevicesHeader";
import { GET_INFERENCE_LEVELS } from "../../../../schemas/queries/Inference";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { POLICIES } from "../../../../constants/Policies";
import { Select } from "../../../../components/Menus/Select";
import { Tag } from "../../../../components/Tags";
import { toCapitalized } from "../../../../utils/ux";
import { useDeviceConfigurationStore } from "../../../../store/DeviceConfiguration";
import { useShallow } from "zustand/react/shallow";

type EditInferenceForm = {
  inferenceLevel?: number;
};

type InferenceLevel = {
  name: string;
  value: number;
};

interface InferenceResponseProps {
  inferenceLevel?: InferenceLevel;
}

const InferenceResponse: React.FC<InferenceResponseProps> = ({
  inferenceLevel = null,
}) => {
  return (
    <div className="flex flex-col gap-y-6">
      {inferenceLevel ? (
        <div className="flex flex-row gap-x-2">
          <p className="text-gray-800 dark:text-gray-300">Inference Mode</p>
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            <Tag color="gray" tag={inferenceLevel.name} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

interface InferencePanelProps {}

export const InferencePanel: React.FC<InferencePanelProps> = () => {
  const { handleSubmit } = useForm<EditInferenceForm>();
  const [inferenceLevels, setInferenceLevels] = useState<InferenceLevel[]>([]);
  const [selectedInferenceLevel, setSelectedInferenceLevel] =
    useState<InferenceLevel>({ name: "none", value: -1 });

  const clearSelectedInferenceLevel = () => {
    setSelectedInferenceLevel({ name: "none", value: -1 });
  };

  const handleReset = () => {
    clearSelectedInferenceLevel();
  };

  const { devices } = useDeviceConfigurationStore(
    useShallow((state) => ({
      devices: state.devices,
    }))
  );

  const inferenceQuery = useQuery(GET_INFERENCE_LEVELS, {
    onCompleted: (data) => {
      const levels = Object.entries(data.inferenceLevels)
        .filter(([key, value]) => typeof value === "number" && !isNaN(value))
        .map(([key, value]) => ({ name: toCapitalized(key), value }));
      // @ts-ignore
      setInferenceLevels(levels);
    },
  });

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState<ResponseProps>({
    success: false,
    successMsg: "",
    errorMsg: "",
  });
  const [inferenceResponse, setInferenceResponse] =
    useState<InferenceResponseProps>({});

  const [editInference, { loading, error }] = useMutation(EDIT_INFERENCE, {
    onError: (error) => {
      console.error(error);
    },
  });

  const onSubmit: SubmitHandler<EditInferenceForm> = async (data) => {
    if (devices.length < 1) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must select one Device to update.",
      });
      setInferenceResponse({});
      setOpen(true);
      return;
    }

    const cellIds = devices.map(({ id }) => id);

    let inferenceLevel;
    if (selectedInferenceLevel.name !== "none") {
      inferenceLevel = selectedInferenceLevel.value;
    }

    const inferenceInput = {
      inferenceLevel: !isNaN(inferenceLevel) ? inferenceLevel : undefined,
    };
    if (inferenceInput.inferenceLevel === undefined) {
      setResponse({
        ...response,
        success: false,
        errorMsg: "Must modify at least one setting.",
      });
      setInferenceResponse({});
      setOpen(true);
      return;
    }
    const mqttMessage = await editInference({
      variables: {
        ids: cellIds,
        update: inferenceInput,
      },
    });
    setResponse({
      ...response,
      success: true,
      successMsg: "Changing devices to have these settings...",
    });
    setInferenceResponse({
      inferenceLevel:
        selectedInferenceLevel.name !== "none"
          ? selectedInferenceLevel
          : undefined,
    });
    setOpen(true);
  };

  return (
    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <EditDevicesHeader />
      <div className="px-12 mt-6 mb-4">
        {inferenceQuery.loading ? (
          <LoadingSpinner h={12} w={12} className="mx-auto my-auto" />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col [&>*]:py-3"
          >
            <div className="flex w-full">
              <button
                type="button"
                className="px-2 py-1 -mt-3.5 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
                onClick={handleReset}
              >
                Clear
              </button>
            </div>
            <AccessControlledComponent
              policies={[POLICIES.EDIT_INFERENCE_LEVEL]}
            >
              <div className="flex flex-col md:flex-row md:justify-between gap-x-4 lg:gap-x-0">
                <label
                  className="block w-32 text-sm font-medium leading-8 text-gray-900 dark:text-gray-200"
                  htmlFor="inferenceLevel"
                >
                  Inference Mode
                </label>
                <div className="w-full">
                  {!inferenceQuery.loading ? (
                    <>
                      <Select
                        value={
                          selectedInferenceLevel.name === "none"
                            ? []
                            : selectedInferenceLevel
                        }
                        onChange={setSelectedInferenceLevel}
                        itemType="Inference Mode"
                        items={inferenceLevels}
                        menuHeight={"max-h-48"}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </AccessControlledComponent>
            <div className="flex w-full mt-2">
              <button
                type="submit"
                className="w-full py-2 font-medium text-white bg-indigo-600 border border-indigo-400 rounded-md shadow-sm dark:text-gray-200 drop-shadow-sm dark:bg-blue-xonar-light/40 hover:bg-indigo-500 dark:hover:bg-blue-xonar-light/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:border-blue-xonar-light/20"
                onSubmit={(e) => handleSubmit(onSubmit)}
                onClick={(e) => handleSubmit(onSubmit)}
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        )}
      </div>
      <ResponseModal
        open={open}
        setOpen={setOpen}
        {...response}
        title={
          response.success ? "Requested to Change Inference Settings" : "Error"
        }
      >
        <InferenceResponse {...inferenceResponse} />
      </ResponseModal>
    </div>
  );
};

export default InferencePanel;
