import { useReportStore } from "../../../store/Report";
import { ReportDeviceLocation } from "../../../store/Report/typing";
import { useShallow } from "zustand/react/shallow";
import React from "react";

type ReportLocationProps = {
	cell: ReportDeviceLocation;
};

const ReportLocationRow: React.FC<ReportLocationProps> = ({ cell }) => {
	return (
		<tr className="border-b border-gray-100 dark:border-gray-100/20">
			<td className="py-2 pl-6 pr-2 align-top">
				<div className="max-w-[7rem] font-medium text-gray-900 dark:text-gray-200 hover:underline">
					{cell?.customerDeviceName
						? cell.customerDeviceName
						: cell.cellName}
				</div>
			</td>
			<td className="hidden py-2 pl-2 pr-1 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cell?.arrivals?.toLocaleString()}
			</td>
			<td className="hidden py-2 pl-2 pr-0 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cell?.alerts?.toLocaleString()}
			</td>
			<td className="hidden py-2 pl-2 pr-0 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cell?.threats?.toLocaleString()}
			</td>
			<td className="hidden py-2 pl-2 pr-2 text-left text-gray-700 align-top tabular-nums dark:text-gray-200/75 sm:table-cell">
				{cell?.recognized?.toLocaleString()}
			</td>
		</tr>
	);
};

export const ReportLocation: React.FC = () => {
	const { report, authorized, viewReport } = useReportStore(
		useShallow((state) => state)
	);

	if (!viewReport || !authorized) {
		return <></>;
	}
	return (
		<div className="mt-4 overflow-hidden border border-gray-200 rounded-xl dark:border-gray-200/20">
			<table className="w-full mt-8 text-xs leading-6 text-left whitespace-nowrap">
				<thead className="text-gray-900 border-b border-gray-200 dark:border-gray-200/20 dark:text-gray-200">
					<tr>
						<th scope="col" className="px-6 py-0 font-semibold">
							Location
						</th>
						<th
							scope="col"
							className="hidden py-2 pl-2 pr-1 font-semibold text-left sm:table-cell"
						>
							Arrivals
						</th>
						<th
							scope="col"
							className="hidden py-2 pl-1 pr-0 font-semibold text-left sm:table-cell"
						>
							Alerts
						</th>
						<th
							scope="col"
							className="hidden py-2 pl-1 pr-0 font-semibold text-left sm:table-cell"
						>
							Threats
						</th>

						<th
							scope="col"
							className="hidden py-2 pl-2 pr-1.5 font-semibold text-left sm:table-cell"
						>
							Recognized
						</th>
					</tr>
				</thead>
				<tbody>
					{report?.locations?.devices &&
					report?.locations?.devices?.length > 0 ? (
						report?.locations?.devices.map((device) => {
							return (
								<ReportLocationRow
									key={`${device.cell}-metrics`}
									cell={device}
								/>
							);
						})
					) : (
						<tr className="border-b border-gray-100 dark:border-gray-100/20">
							<td className="px-6 py-2 align-top max-w-0">
								<div className="font-medium text-gray-900 dark:text-gray-200">
									No Devices Found.
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ReportLocation;
