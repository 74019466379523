import { useEffect, useState } from "react";

import { Button } from "../../components/Buttons";
import { Modal } from "../../components/Modals/";
import { TextInput } from "../../components/Inputs/TextInput";
import { useInputWithError } from "../../hooks/inputWithError";
import { useMutation } from "@apollo/client";
import { EDIT_CUSTOMER_FACING_NAME } from "../../schemas/mutators/Devices";
import React from "react";

type DeviceEditModalProps = {
	deviceId: string;
	deviceName: string;
	customerFacingName: string | null;
	refetch: () => any;
};

type DeviceCustomerNameInput = {
	id: string;
	customerDeviceName: string;
};

export const DeviceEditModal: React.FC<DeviceEditModalProps> = ({
	deviceId,
	deviceName,
	customerFacingName,
	refetch,
}) => {
	const [open, setOpen] = useState(false);
	const [deviceCustomerFacingName, setDeviceCustomerFacingName] =
		useInputWithError("");

	const [editError, setEditError] = useState("");

	useEffect(() => {
		// clear modal error after 3 seconds
		setTimeout(() => {
			setEditError("");
		}, 3000);
	}, [editError]);

	const [editCustomerFacingName, { loading, error }] = useMutation(
		EDIT_CUSTOMER_FACING_NAME
	);

	const clearInputs = () => {
		setDeviceCustomerFacingName("");
	};

	const openModal = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	const handleName = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setDeviceCustomerFacingName(value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (deviceCustomerFacingName === "") return;

		if (
			deviceCustomerFacingName &&
			deviceCustomerFacingName.trim().length === 0
		) {
			setEditError("Device Name cannot be empty.");
			return;
		}

		// construct DeviceCustomerNameInput
		const update: DeviceCustomerNameInput = {
			id: deviceId,
			customerDeviceName: deviceCustomerFacingName.trim(),
		};

		await editCustomerFacingName({ variables: { update } });
		// clear state
		clearInputs();
		refetch();
	};

	if (error) {
		console.error(error);
		setEditError(error.message);
	}

	if (!deviceName) {
		return <></>;
	}

	return (
		<>
			<a
				href=""
				className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-500 pt-[0.05rem]"
				onClick={(e) => openModal(e)}
			>
				Edit
			</a>
			<Modal open={open} setOpen={setOpen} title="Edit Device">
				<div className="flex flex-col my-6 space-y-4">
					{editError && (
						<div className="mt-2 text-sm text-center text-red-600">
							<p>{editError}</p>
						</div>
					)}
					<form
						className="flex flex-col space-y-4"
						onSubmit={handleSubmit}
					>
						<TextInput
							inputType={"text"}
							inputName={"deviceCustomerFacingName"}
							inputId={"deviceCustomerFacingName"}
							placeholder={
								customerFacingName
									? customerFacingName
									: deviceName
							}
							label={"Name"}
							value={deviceCustomerFacingName}
							onChange={handleName}
						/>
						<div className="flex">
							<Button
								type="submit"
								className="flex flex-row gap-x-2 last:ml-auto"
								onClick={handleSubmit}
								onSubmit={handleSubmit}
								loading={loading}
							>
								Submit
							</Button>
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

export default DeviceEditModal;
