import { Disclosure } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import LogoHeader from "../../assets/images/logo_header.png";
import { classNames } from "../../utils/ux";
import { NotificationBell } from "../Notifications/NotificationBell";
import { ProfileMenu } from "./ProfileMenu";

export const Logo = () => (
	<Link to="/home" className="mx-auto md:mx-0">
		<img className="w-auto h-8" src={LogoHeader} alt="Xonar" />
	</Link>
);

export const MobileMenuButton = ({ open }) => (
	<Disclosure.Button className="relative inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:text-gray-400">
		<span className="absolute -inset-0.5" />
		<span className="sr-only">Open Main Menu</span>
		{open ? (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="1.5"
				stroke="currentColor"
				className="w-6 h-6"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M6 18 18 6M6 6l12 12"
				/>
			</svg>
		) : (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="1.5"
				stroke="currentColor"
				className="w-6 h-6"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
				/>
			</svg>
		)}
	</Disclosure.Button>
);

export const NavLinks = ({ filteredNavigation, isActive }) => (
	<div className="flex lg:gap-x-8 md:gap-x-6">
		{filteredNavigation.map((route, i) => {
			if (route.children) {
				return (
					<div
						className="relative group"
						key={`${route.name}-menu-${i}`}
					>
						<button className="inline-flex items-center dark:text-gray-200 dark:hover:text-indigo-300 focus:outline-none">
							{route.name}
							<svg
								className="w-4 h-4 ml-1 transition-transform duration-200 group-hover:transform group-hover:rotate-180"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M19 9l-7 7-7-7"
								/>
							</svg>
						</button>
						<div className="absolute left-0 z-10 w-48 py-1 mt-2 origin-top-left bg-white rounded-md shadow-lg dark:bg-blue-xonar ring-1 ring-black ring-opacity-5 dark:ring-white/10 focus:outline-none invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300">
							{route.children.map((child, j) => (
								<Link
									key={`${child.name}-submenu-${j}`}
									to={child.to}
									className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-indigo-vite dark:hover:text-gray-300"
								>
									{child.name}
								</Link>
							))}
						</div>
					</div>
				);
			}

			const active = isActive(route);
			const linkClass = active
				? "text-indigo-600 dark:text-indigo-400"
				: "dark:text-gray-200";
			return (
				<Link
					key={`${route.name}-route-${i}`}
					target={route.target}
					to={route.to}
					className={classNames(
						linkClass,
						"dark:hover:text-indigo-300"
					)}
				>
					{route.name}
				</Link>
			);
		})}
	</div>
);

export const MobileMenu = ({ filteredNavigation, isActive, onClose }) => (
	<Disclosure.Panel className="md:hidden">
		<div className="fixed inset-0 z-50 backdrop-blur-lg bg-white/60 dark:bg-blue-xonar/45">
			{/* Top menu bar */}
			<div className="fixed top-0 left-0 right-0 h-16 px-4 flex items-center justify-between bg-white/50 dark:bg-blue-xonar/50 backdrop-blur-sm border-b border-gray-200 dark:border-gray-700">
				{/* Close button */}
				<button
					onClick={onClose}
					className="p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
				>
					<span className="sr-only">Close menu</span>
					<svg
						className="h-6 w-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>

				{/* Centered logo */}
				<div className="absolute left-1/2 transform -translate-x-1/2">
					<Logo />
				</div>

				{/* Right side icons */}
				<div className="flex items-center">
					<NotificationBell />
					<ProfileMenu />
				</div>
			</div>

			{/* Scrollable menu items */}
			<div className="mt-16 h-[calc(100vh-4rem)] overflow-y-auto">
				<div className="px-4 py-6 space-y-4">
					{filteredNavigation.map((route, i) => {
						if (route.children) {
							return (
								<Disclosure
									key={`${route.name}-mobile-submenu-${i}`}
									as="div"
									className="space-y-2"
								>
									{({ open }) => (
										<>
											<Disclosure.Button className="flex justify-between w-full py-2 text-left text-lg font-medium text-gray-800 dark:text-gray-200 px-4 rounded-xl hover:bg-black/10 dark:hover:bg-white/10">
												<span>{route.name}</span>
												<svg
													className={`${
														open
															? "transform rotate-180"
															: ""
													} w-5 h-5 text-gray-500`}
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 20 20"
													fill="currentColor"
													aria-hidden="true"
												>
													<path
														fillRule="evenodd"
														d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
														clipRule="evenodd"
													/>
												</svg>
											</Disclosure.Button>
											<Disclosure.Panel className="pl-4 space-y-2">
												{route.children.map(
													(child, j) => (
														<Link
															key={`${child.name}-mobile-subitem-${j}`}
															to={child.to}
															className="block py-2 text-base text-gray-700 dark:text-gray-300 pl-4 rounded-xl hover:bg-black/10 dark:hover:bg-white/10"
															onClick={onClose}
														>
															{child.name}
														</Link>
													)
												)}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							);
						}

						const active = isActive(route);
						const linkClass = classNames(
							active
								? "text-indigo-600 dark:text-indigo-400 pl-4 rounded-xl hover:bg-black/10 dark:hover:bg-white/10"
								: "text-gray-800 dark:text-gray-200 pl-4 rounded-xl hover:bg-black/10 dark:hover:bg-white/10",
							"block py-2 text-lg font-medium"
						);
						return (
							<Link
								key={`${route.name}-route-${i}-mobile-btn`}
								to={route.to}
								className={linkClass}
								aria-current={active ? "page" : undefined}
								onClick={onClose}
							>
								{route.name}
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	</Disclosure.Panel>
);
