import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Buttons";

interface PageLayoutProps {
	title?: string;
	description?: string;
	buttonText?: string;
	buttonLink?: string;
	children: React.ReactNode;
}

export default function PageLayout({
	title,
	description,
	buttonText,
	buttonLink,
	children,
}: PageLayoutProps) {
	return (
		<main>
			<div className="py-8 space-y-16 xl:space-y-20">
				<div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
					<div className="px-4 mt-16 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
									{title}
								</h1>
								<p className="mt-2 text-sm text-gray-700 dark:text-gray-300/80">
									{description}
								</p>
							</div>
							{buttonText && buttonLink && (
								<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
									<Link to={buttonLink}>
										<Button
											type="button"
											className="block px-1 text-sm font-semibold text-center"
										>
											{buttonText}
										</Button>
									</Link>
								</div>
							)}
						</div>
					</div>
					<div className="px-4 mx-auto my-8 text-gray-900 sm:px-6 lg:px-8 dark:text-gray-200">
						{children}
					</div>
				</div>
			</div>
		</main>
	);
}
