import { useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import {
	CLEAR_USER_ALERT,
	READ_USER_ALERT,
} from "../../schemas/mutators/Notifications";

import { classNames } from "../../utils/ux";

export interface NotificationType {
	id: string;
	method: string;
	notifier: string;
	message: string;
	viewed: boolean;
	viewedTime: number;
	cleared: boolean;
	clearedTime: number;
}

export const NotificationItem = ({
	id,
	method,
	notifier,
	message,
	viewed,
	viewedTime,
	cleared,
	clearedTime,
	isPopoverOpen,
	refetch,
}: NotificationType & {
	isPopoverOpen: boolean;
	refetch: () => void;
}) => {
	const itemRef = useRef<HTMLDivElement>(null);
	const [isReading, setIsReading] = useState(false);
	const timerRef = useRef<any | null>(null);
	const [readNotification] = useMutation(READ_USER_ALERT);
	const [clearNotification] = useMutation(CLEAR_USER_ALERT);

	useEffect(() => {
		if (viewed || !id || !isPopoverOpen) return;

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setIsReading(true);
						timerRef.current = setTimeout(async () => {
							await readNotification({
								variables: { notification: id },
							});
							refetch();
						}, 5000);
					} else {
						setIsReading(false);
						if (timerRef.current) {
							clearTimeout(timerRef.current);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (itemRef.current) {
			observer.observe(itemRef.current);
		}

		return () => {
			if (itemRef.current) {
				observer.unobserve(itemRef.current);
			}
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [id, viewed, isPopoverOpen]);

	useEffect(() => {
		if (!isPopoverOpen) {
			setIsReading(false);
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		}
	}, [isPopoverOpen]);

	return (
		<div
			ref={itemRef}
			className={classNames(
				"flex items-start border-b border-gray-200 p-4 transition-all duration-500 ease-in-out",
				viewed
					? "bg-gray-200" // Viewed
					: isReading
					? "bg-blue-100 dark:bg-blue-200 font-bold" // Reading
					: "bg-white font-bold" // Unread
			)}
		>
			<div className="flex-grow pr-4">
				<p
					className={
						viewed && !isReading
							? "text-sm text-gray-700"
							: "text-sm text-gray-800"
					}
				>
					{message}
				</p>
			</div>
			<div className="flex flex-col justify-between flex-grow h-full">
				<button
					className="text-gray-400 hover:text-gray-600 ml-auto"
					onClick={async (e) => {
						e.stopPropagation();
						if (id !== undefined) {
							await clearNotification({
								variables: { notification: id },
							});
							refetch();
						}
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="w-5 h-5"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};
