import LoadingSpinner from "../../components/LoadingSpinner";

export const ScreenLoader = () => {
	return (
		<div className="flex justify-center h-[70vh] px-4 mx-auto my-auto max-w-7xl sm:px-6 lg:px-8">
			<LoadingSpinner
				className="w-24 h-24 mx-auto my-auto"
				w={24}
				h={24}
			/>
		</div>
	);
};

export default ScreenLoader;
