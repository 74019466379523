export const TextInput = ({
  inputType,
  inputName,
  inputId,
  value,
  onChange,
  placeholder,
  label,
  inputClassName = "block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-200/50 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-blue-xonar-light sm:text-sm sm:leading-6",
  errorClassName = "block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 dark:text-red-200 dark:bg-blue-xonar/50 shadow-sm ring-1 ring-inset ring-red-300 dark:ring-red-200/50 placeholder:text-red-400 dark:placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6",
  labelClassName = "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200",
  errorMessage = "Error on input!",
  error = false,
}) => {
  return (
    <div>
      <label htmlFor={inputId} className={labelClassName}>
        {label}
      </label>
      <div className="flex mt-2">
        <input
          type={inputType}
          name={inputName}
          id={inputId}
          className={error ? errorClassName : inputClassName}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          aria-invalid={error}
          aria-describedby={`${inputName}-error`}
        />
        {error && (
          <div className="absolute flex items-center pr-3 my-2 text-red-500 pointer-events-none right-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
              />
            </svg>
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${inputName}-error`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default TextInput;
