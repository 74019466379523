import { Link, useNavigate, useParams } from "react-router-dom";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";

import { Button } from "../../../components/Buttons";
import { Category } from "../AddCategory";
import { DeletePerson } from "../DeletePerson";
import { EditCategory } from "./EditCategory";
import { EditDetails } from "./EditDetails";
import { EditImages } from "./EditImages";
import { EditPanel } from "./EditPanel";
import { GET_PERSON } from "../../../schemas/queries/People";
import ScreenLoader from "../../../components/Loaders/ScreenLoader";
import { Tab } from "@headlessui/react";
import { classNames } from "../../../utils/ux";

interface EditTabProps {
  children: any;
}
const EditTab: React.FC<EditTabProps> = ({ children }) => {
  return (
    <Tab
      className={({ selected }) =>
        classNames(
          selected
            ? "text-gray-600 dark:text-gray-300"
            : "text-gray-400 dark:text-gray-600 hover:text-gray-500 dark:hover:text-gray-400",
          "w-full py-2.5 text-lg font-medium leading-5 rounded-md",
          "ring-white/60 ring-offset-2 ring-offset-blue-xonar-light focus:outline-none focus:ring-2"
        )
      }
    >
      {children}
    </Tab>
  );
};

interface Props {}

export interface VenueType {
  id: string;
  name: string;
}

interface PersonType {
  alert?: boolean;
  category?: Category;
  customer?: string;
  description?: string;
  email?: string;
  id?: string;
  name?: string;
  phone?: string;
  venue?: VenueType[];
  mainFace?: string;
}

export interface PersonFaceImages {
  id: string;
  url: string;
}

export const EditPerson: React.FC<Props> = (props: Props) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [person, setPerson] = useState<PersonType>({});
  const [personFaceImages, setPersonFaceImages] = useState<PersonFaceImages[]>(
    []
  );
  const navigate = useNavigate();
  const { personId } = useParams();
  const personQuery = useQuery(GET_PERSON, {
    variables: {
      id: personId,
    },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.error(error);
      navigate("/error", {
        state: { status: 404, error: "Person could not be found." },
      });
    },
    onCompleted: (data) => {
      setPerson({
        ...data.person,
        venue: data.personVenues,
      });
      const { personFaces, person } = data;
      const mainFaceIndex = personFaces.findIndex(
        (face) => face.id === person.mainFace
      );
      const displayImages = [...personFaces];
      displayImages.unshift(displayImages.splice(mainFaceIndex, 1)[0]);
      setPersonFaceImages(displayImages);

      setInitialLoading(false);
    },
    fetchPolicy: "network-only",
  });

  if (initialLoading) {
    return <ScreenLoader />;
  }

  return (
    <main>
      <div className="py-8 space-y-16 xl:space-y-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 mt-16 sm:px-6 lg:px-8">
            <div className="md:flex md:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
                  Edit Person
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-gray-300/80">
                  Modify personal details, category, and images used for facial
                  recognition.
                </p>
              </div>
              <div className="flex flex-row mt-8 gap-x-8 md:ml-16 md:mt-0 md:gap-x-6">
                <Link to={`/people/${personId}`}>
                  <Button
                    type="button"
                    className="block px-1 text-sm font-semibold text-center"
                  >
                    View Person
                  </Button>
                </Link>
                <DeletePerson
                  personId={personId ? personId : ""}
                  name={personQuery?.data?.person.name}
                  className="block px-4 py-[7px] my-auto text-sm font-semibold text-center text-white rounded-md shadow-sm bg-blue-xonar-light hover:text-white dark:text-gray-300 dark:hover:text-gray-200 hover:bg-sky-xonar dark:bg-blue-xonar-light/50 dark:hover:bg-blue-xonar-light/40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-xonar-secondary"
                />
              </div>
            </div>
            <div className="w-full px-2 py-6 sm:px-0">
              <Tab.Group>
                <Tab.List className="flex p-2 space-x-2 rounded-t-xl bg-gray-50/50 dark:bg-blue-xonar-secondary/80">
                  <EditTab>Details</EditTab>
                  <EditTab>Category</EditTab>
                  <EditTab>Images</EditTab>
                </Tab.List>
                <Tab.Panels>
                  <EditPanel>
                    <EditDetails
                      name={person.name}
                      email={person.email}
                      phone={person.phone}
                      venue={person.venue}
                      alert={person.alert}
                      description={person.description}
                      customer={person.customer}
                      refetch={personQuery.refetch}
                    />
                  </EditPanel>
                  <EditPanel>
                    <EditCategory
                      // @ts-ignore
                      category={person.category}
                      refetch={personQuery.refetch}
                    />
                  </EditPanel>
                  <EditPanel>
                    <EditImages
                      images={personFaceImages}
                      // @ts-ignore
                      mainFace={person.mainFace}
                      refetch={personQuery.refetch}
                      loadingExistingFaces={
                        personQuery.networkStatus === NetworkStatus.refetch
                      }
                    />
                  </EditPanel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditPerson;
